import SortIcon from "components/commonComponent/sortIcon"
import { HiOutlineChevronUp } from 'react-icons/hi';

const Sort = (props: any) => {
    const { check, sortByType } = props
    return (
        check ? <SortIcon sortByType={sortByType} /> :
            <div className='icon-sort'>
                <HiOutlineChevronUp className='pl-1 opacity-40' size={22} />
            </div>
    )
}

export default Sort