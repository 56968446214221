import userSlice from './slice/userSlice';
import roleSlice from './slice/roleSlice';
import orgSlice from './slice/orgSlice';
import locationSlice from './slice/locationSlice';

const userAction = userSlice.actions;
const roleAction = roleSlice.actions;
const orgAction = orgSlice.actions;
const locationAction = locationSlice.actions;

export { userAction, roleAction, orgAction, locationAction };
