import PaginateTable from 'components/table/paginate';
import { exportFileName, defaultpageCount, routePaths, sortByTypeType } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { initUser } from 'utils/initData';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import { isEqual } from 'lodash';
import 'styles/styles.scss';
import { useNavigate } from 'react-router-dom';
import HeaderTable from 'components/table/headerTable';
import DataTable from 'components/commonComponent/datatable/dataTable';
import { getSubscriptionsByUserId } from 'api/userApi';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import moment from 'moment';
import { exportToFile } from 'utils/utils';

const MySubscription = () => {
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [sortBy, setSortBy] = useState('created');
  const [sortByType, setSortByType] = useState(sortByTypeType.DESC);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(defaultpageCount);

  const { isLoading, data, error } = useQuery(
    ['getSubscriptionByUserId', currentPage, searchValue, sortBy, sortByType, pageCount],
    () => getSubscriptionsByUserId(userInfo.id, { page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    {},
  );

  useEffect(() => {
    if (data) {
      setSubscriptions(data.data.entities);
      setTotalEntities(data.data.totalEntities);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setSubscriptions([]);
    }
  }, [error]);


  useEffect(() => {
    const susbscriotionTemp = data
      ? data?.data?.entities?.map((item: any) => {
          return {
            planName: <p className="font-medium text-gray-800">{item?.plan?.name}</p>,
            billing: <p className="font-medium text-gray-800">{item?.plan?.interval}</p>,
            currency: <p className="font-medium text-gray-800">{'USD'}</p>,
            price: <p className="font-medium text-gray-800">${item?.plan?.priceUSD / 100}</p>,
            startDate: <p className="font-medium text-gray-800">{moment(item?.created).format('L')}</p>,
            endDate: (
              <p className={`font-medium ${moment(item?.expiredAt) > moment(new Date()) ? 'text-gray-800' : 'text-red-500'}`}>
                {moment(item?.expiredAt).format('L')}
              </p>
            ),
          };
        })
      : [];
    setSubscriptionData(susbscriotionTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setCurrentPage(0);
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const columns = [
    {
      name: t('mySubscriptions.planName'),
      selector: 'planName',
    },
    {
      name: t('mySubscriptions.billing'),
      selector: 'billing',
    },
    {
      name: t('mySubscriptions.currency'),
      selector: 'currency',
    },
    {
      name: t('mySubscriptions.price'),
      selector: 'price',
    },
    {
      name: t('mySubscriptions.startDate'),
      selector: 'startDate',
      sortField: 'created',
    },
    {
      name: t('mySubscriptions.endDate'),
      selector: 'endDate',
      sortField: 'expiredAt',
    },
  ];

  const handleOnClickExport = () => {
    const exportedData = subscriptions?.map((item: any) => ({
      'Plan Name': item.plan.name,
      Billing: item.plan.type,
      Currency: 'USD',
      Price: `$${item.plan.priceUSD}`,
      'Start Date': item.created,
      'End Date': item.expiredAt,
    }));
    exportToFile(exportedData, exportFileName.MY_SUPSCRIPTIONS);
  };

  const handleHeaderSort = (field: string) => {
    if (!field) {
      return false;
    }
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC;
    } else {
      type = sortByType === sortByTypeType.ASC ? sortByTypeType.DESC : sortByTypeType.ASC;
    }
    setSortByType(type);
  };

  return (
    <div className="w-full">
      <HeaderTable
        buttonName="Add Subscription"
        handleAddClick={() => {
          navigate(routePaths.SUBSCRIPTION);
        }}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {!isEqual(subscriptions[0], initUser) && totalEntities === 0 && !isLoading ? (
        <NoResult />
      ) : (
        <>
          {isLoading ? (
            <SpinnerComponent />
          ) : (
            <DataTable columns={columns} data={subscriptionData} sortBy={sortBy} sortByType={sortByType} handleHeaderSort={handleHeaderSort} />
          )}
          <PaginateTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isLoadingTable={isLoading}
            exportHandler={handleOnClickExport}
            pageCount={pageCount}
          />
        </>
      )}
    </div>
  );
};
export default MySubscription;
