/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import useUser from 'hooks/useUser';
import {Modal} from 'flowbite-react';
import {useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './purchaseOrderModal.scss';
import {locationSelector, orgSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {HiOutlineExclamation, HiOutlineRefresh, HiOutlineSparkles} from "react-icons/hi";
import ButtonNewPurchaseOrder from "../../commonComponent/buttonNewPurchaseOrder";

type createPurchaseOrderModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    isEdit: boolean,
    isBlank: boolean,
    setIsBlank: Function,
    isLastOrder: boolean,
    setIsLastOrder: Function,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const CreatePurchaseOrderModal = (props: createPurchaseOrderModalProps) => {
    const {
        openModal,
        isEdit,
        isBlank,
        setIsBlank,
        isLastOrder,
        setIsLastOrder,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);
    const [openReplenishModal, setOpenReplenishModal] = useState(false);
    const [replenishNumber, setReplenishNumber] = useState(2);

    const toggleHandler = () => {
        setOpenModal(!openModal);
    };

    const toggleBlank = () => {
        setIsBlank(!isBlank);
        toggleHandler();
    };

    const toggleReplenish = () => {

    };

    const toggleRepeat = () => {
        setIsLastOrder(!isLastOrder);
        toggleHandler();
    };

    return (
        <div ref={rootRef} className="order-modal-container">
            <Modal
                show={openModal}
                size="2xl"
                className="order-modal"
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={false}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex items-center justify-center p-[50px] gap-7'}}>
                    <ButtonNewPurchaseOrder text={t('orderPage.newBlankOrder')} Icon={HiOutlineSparkles}
                                            onClick={() => toggleBlank()}/>
                    <ButtonNewPurchaseOrder text={t('orderPage.replenishLowStock')} Icon={HiOutlineExclamation}
                                            onClick={() => toggleReplenish()}/>
                    <ButtonNewPurchaseOrder text={t('orderPage.repeatLastOrder')} Icon={HiOutlineRefresh}
                                            onClick={() => toggleRepeat()}/>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default CreatePurchaseOrderModal;
