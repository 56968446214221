/* eslint-disable jsx-a11y/label-has-associated-control */

import { useTranslation } from 'react-i18next';
import { HiOutlineUpload, HiX } from 'react-icons/hi';
import { acceptImageTypeFile } from 'utils/constants';
import { uploadImageTypes } from 'utils/proptypes';

const FileUpload = (props: uploadImageTypes) => {
  const { handleUploadImage, imgUrl, handleRemoveImage } = props;
  const [t] = useTranslation();

  return (
    <div className="flex justify-center items-center w-full">
      {imgUrl !== '' && imgUrl !== null ? (
        <div className="rounded-lg border border-gray-300 relative w-24 h-24">
          <span
            onClick={() => handleRemoveImage()}
            className="absolute rounded-sm top-0 right-0 bg-white/50 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
          >
            <HiX />
          </span>
          <img className="preview w-full h-24 object-cover rounded-lg" src={imgUrl} alt="avatar" />
        </div>
      ) : (
        <label
          htmlFor="dropzone-file"
          className="flex flex-col justify-center items-center w-24 h-24 text-gray-600 bg-gray-300 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-200 font-normal rounded-lg text-xs dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
          <div className="flex flex-col justify-center items-center cursor-pointer">
            <HiOutlineUpload className="h-6 w-6" />
            <p>{t('uploadPicture')}</p>
          </div>
          <input id="dropzone-file" type="file" accept={acceptImageTypeFile} onChange={e => handleUploadImage(e)} className="hidden" />
        </label>
      )}
    </div>
  );
};
export default FileUpload;
