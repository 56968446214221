import {HiOutlineDuplicate, HiOutlineUserAdd} from 'react-icons/hi';
import PaginateTable from 'components/table/paginate';
import {
    apiStatus,
    defaultpageCount,
    exportFileName,
    pageType,
    permissionKeys,
    routePaths,
    sortByTypeType,
    status
} from 'utils/constants';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {getOrganizationUsers, getSiteUsers, removeUser, updateActiveUserById} from 'api/userApi';
import {initUser} from 'utils/initData';
import {useTranslation} from 'react-i18next';
import {roleType, userType} from 'utils/proptypes';
import UserModal from 'components/modal/userModal';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import SpinnerComponent from 'components/spinner';
import {find, flattenDeep, isEmpty, isEqual, map, uniq} from 'lodash';
import '../../styles/styles.scss';
import {getRoles} from 'api/rolesApi';
import {checkPermission, copyToClipboard, exportToFile, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {orgSelector, userSelector} from 'redux/selectors';
import './userManagement.scss';
import Actions from '../../components/actions';
import {useNavigate} from 'react-router-dom';
import Avatar from 'components/GroupAvatar/Avatar';
import Sort from 'components/table/sort';
import useUser from 'hooks/useUser';
import EmptyState from "../../components/commonComponent/emptyState";

const emptysUser = require('../../assets/image/svg/Emptys_User.svg').default || '';

const UserManagementPage = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_USER, PLATFORM_ADMIN} = permissionKeys;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const {organizationId} = useSelector(orgSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [users, setUsers] = useState([initUser]);
    const [allUsers, setAllUsers] = useState([]);
    const {userInfo} = useSelector(userSelector);
    const [targetUser, setTargetUser] = useState(initUser);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const defaultRoles: [roleType] = [{displayName: '', id: '', type: '', description: ''}];
    const [roleOptions, setRoleOptions] = useState(defaultRoles);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_USER], organizationId);
    const [pageCount, setPageCount] = useState(defaultpageCount);

    const handleClickAddUser = () => {
        setTargetUser(initUser);
        setOpenModal(true);
        setIsEdit(false);
    };

    const getUserByRole = (currentPage: number, pageCount: number, searchValue: string) => {
        return (props.type === pageType.SITE ? getSiteUsers : getOrganizationUsers)({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType,
        });
    };

    const getUsers = useQuery(
        ['getUsers', currentPage, searchValue, props.type, sortBy, sortByType, pageCount],
        () => getUserByRole(currentPage, pageCount, searchValue),
        {
            onSuccess: ({data}) => {
                setUsers(data.entities);
                setTotalEntities(data.totalEntities);
            },
            staleTime: Infinity,
        },
    );

    const getAllUsers = useMutation(['getAllUsersBySite'], {
        mutationFn: props.type === pageType.SITE ? getSiteUsers : getOrganizationUsers,
        onSuccess: ({data}) => setAllUsers(data.entities),
        onError: error => {
            setAllUsers([]);
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const mutGetRoles = useMutation(['getRoles'], {
        mutationFn: getRoles,
        onSuccess: ({data}) => setRoleOptions(data?.entities),
        onError: () => setRoleOptions(defaultRoles),
    });

    const mutRemoveUser = useMutation(['removeUser'], {
        mutationFn: removeUser,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleRemove = ({id}: any) => {
        mutRemoveUser.mutate(id, {
            onSuccess: async () => {
                const message: string = t('userManagementPage.removeSuccessMessage');
                toast.success(message);
                await fetchCurrentUser();
                const permissionsSite = uniq(flattenDeep(map(userInfo?.roles, (r: any) => r?.permissions)));
                if (id === userInfo.id && !permissionsSite.includes(permissionKeys.PLATFORM_ADMIN)) navigate(routePaths.DASHBOARD_PAGE);
                queryClient.invalidateQueries(['getOrganizationDetail']);
                queryClient.invalidateQueries(['getOrganizations']);
                queryClient.invalidateQueries(['getUsers']);
            },
        });
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    useEffect(() => {
        setTotalEntities(getUsers.data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        queryClient.invalidateQueries(['getUsers']);
        // eslint-disable-next-line
    }, [organizationId, userInfo]);

    useEffect(() => {
        if (getUsers.data !== undefined) {
            setUsers(getUsers.data?.data?.entities);
            setTotalEntities(getUsers.data?.data?.totalEntities);
        }
    }, [getUsers.data]);

    const handleActiveUser = async (data: userType) => {
        const response = await updateActiveUserById(data.id, {isActive: !data.isActive});
        if (response.status === apiStatus.NO_CONTENT) {
            queryClient.invalidateQueries(['getOrganizationDetail']);
            queryClient.invalidateQueries(['getOrganizations']);
            queryClient.invalidateQueries(['getUsers']);
        }
    };

    const handleViewDetail = (userId: string, tab?: string) => {
        if (props.type === pageType.SITE) {
            navigate(`${routePaths.ADMIN_USERS_PAGE}/${userId}`, {state: {tab}});
        } else {
            navigate(`${routePaths.ORG_USERS_PAGE}/${userId}`, {state: {tab}});
        }
    };

    const handleCopyToClipboard = (e: any, item: any, notification: any) => {
        copyToClipboard(item, notification);
        e.stopPropagation();
    };
    const exportUserHandler = () => {
        if (users[0] !== initUser && users?.length > 0 && !getAllUsers.isLoading) {
            getAllUsers.mutate({page: 0, limit: totalEntities, searchQuery: searchValue, sortBy, sortByType});
        }
    };

    useEffect(() => {
        if (!isEmpty(allUsers)) {
            const exportedData = allUsers.map((user: userType) => {
                return {
                    'First name': user.firstName,
                    'Last name': user.lastName,
                    Email: user.emailAddress,
                    Status: user.isActive,
                };
            });
            exportToFile(exportedData, exportFileName.USER);
        }
    }, [allUsers]);

    useEffect(() => {
        setCurrentPage(0);
        queryClient.invalidateQueries(['getUsers'], {refetchType: 'active'}, {cancelRefetch: true});
        mutGetRoles.mutate({type: props.type, limit: 0});
        setSearchValue('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    useEffect(() => {
        if (getUsers.error) {
            setUsers([initUser]);
        }
    }, [getUsers.error]);

    const renderHeader = () => {
        if (props.type === pageType.SITE) {
            return isEdit ? t('userManagementPage.editUser') : t('userManagementPage.addUser');
        } else return isEdit ? t('userManagementPage.editUserInOrg') : t('userManagementPage.addUserToOrg');
    };

    return (
        <>
            {totalEntities === 0 && !searchValue && props.type === pageType.ORGANIZATION ? (
                <EmptyState buttonName={props.type === pageType.SITE ? t('userManagementPage.addUser') : t('userManagementPage.addUserToOrg')}
                            handleAddClick={isWriteOrg && handleClickAddUser}
                            title={t('organizationPage.notification')}
                            subtitle={t('organizationPage.note')}
                            icon={emptysUser}
                />

            ) : (
                <div className="main-sub">
                    {!isEqual(users[0], initUser) && totalEntities === 0 && !getAllUsers.isLoading && 
                    ( 
                        <>
                        <ActionTable
                        placeholderSearch="Search"
                        buttonName={props.type === pageType.SITE ? t('userManagementPage.addUser') : t('userManagementPage.addUserToOrg')}
                        handleAddClick={isWriteOrg && handleClickAddUser}
                        handleSearch={handleSearch}
                        searchValue={searchValue}
                        />
                        <NoResult/>
                        </>)
                    }
                    {!isEqual(users[0], initUser) && users?.length > 0 && (
                        <>
                            <ActionTable
                                placeholderSearch="Search"
                                buttonName={props.type === pageType.SITE ? t('userManagementPage.addUser') : t('userManagementPage.addUserToOrg')}
                                handleAddClick={isWriteOrg && handleClickAddUser}
                                handleSearch={handleSearch}
                            />
                            <table className="w-full text-sm text-left dark:text-gray-400">
                                <thead
                                    className="text-xs font-medium text-gray-900 border-b-2 border-gray-100 uppercase">
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3 px-3 cursor-pointer"
                                        onClick={() => {
                                            handleHeaderSort('firstName');
                                        }}
                                    >
                                        <div className="flex items-center font-medium">
                                            {t('userManagementPage.fullName')}
                                            <Sort check={sortBy === 'firstName'} sortByType={sortByType}/>
                                        </div>
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-3 px-4 hidden-mobile cursor-pointer"
                                        onClick={() => {
                                            handleHeaderSort('isActive');
                                        }}
                                    >
                                        <div className="flex items-center font-medium">
                                            {t('userManagementPage.status')}
                                            <Sort check={sortBy === 'isActive'} sortByType={sortByType}/>
                                        </div>
                                    </th>
                                    {isWriteOrg && <th scope="col" className="py-3 w-6"/>}
                                </tr>
                                </thead>
                                <tbody>
                                {users?.map((item: userType, index) => {
                                    const platformAdmin = find(uniq(flattenDeep(map(item?.roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);
                                    const isWriteOrgWithUser = checkPermission(userInfo, props.type, [WRITE_USER], organizationId, platformAdmin);
                                    return (
                                        <tr
                                            onClick={() => isWriteOrgWithUser && handleViewDetail(item.id)}
                                            key={`${index + 1}-list-user`}
                                            className="cursor-pointer font-medium text-gray-900 bg-white border-b border-gray-100 hover:bg-gray-50"
                                        >
                                            <td className="py-[8px] px-3 flex flex-row items-center">
                                                <Avatar item={item}/>
                                                <div className="flex flex-col ml-3">
                                                    <div className="flex flex-row">
                                                        <div
                                                            className="text-sm font-semibold text-black">{`${item.firstName || ''} ${item.lastName || ''}`}</div>
                                                        <div className="copy-icon pl-3 cursor-pointer">
                                                            <HiOutlineDuplicate
                                                                size={25}
                                                                onClick={(e: any) =>
                                                                    handleCopyToClipboard(e, `${item.firstName || ''} ${item.lastName || ''}`, t('userManagementPage.copiedName'))
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="text-gray-500 text-xs font-normal">{`${item.emailAddress}`}</div>
                                                </div>
                                            </td>
                                            <td className="hidden-mobile py-[8px] px-4">
                                                <div className="flex flex-row items-center">
                                                    {item.isActive ? (
                                                        <span className="w-3 h-3 bg-green-400 rounded-lg mr-2 "/>
                                                    ) : (
                                                        <span className="w-3 h-3 bg-red-500 rounded-lg mr-2 "/>
                                                    )}
                                                    {item.isActive ? status.ACTIVE : status.INACTIVE}
                                                </div>
                                            </td>
                                            <td className="py-[8px]">
                                                {isWriteOrgWithUser && props.type === pageType.ORGANIZATION && (
                                                    <div onClick={e => e.stopPropagation()}>
                                                        <Actions>
                                                            <Actions.Item action={() => handleViewDetail(item.id)}
                                                                          label={t('action.viewDetails')}/>
                                                            {/*<Actions.Item*/}
                                                            {/*    action={() => handleViewDetail(item.id, 'assignRole')}*/}
                                                            {/*    label={t(props.type === pageType.SITE ? 'assignRoles' : 'assignRole')}*/}
                                                            {/*/>*/}
                                                            {/*<Actions.Item*/}
                                                            {/*    action={() => handleViewDetail(item.id, 'organizations')}*/}
                                                            {/*    label={t('action.organizations')}/>*/}
                                                            {props.type === pageType.SITE && (
                                                                <Actions.Item action={() => handleActiveUser(item)}
                                                                              label={item.isActive ? t('deactivate') : t('activate')}/>
                                                            )}
                                                            {props.type === pageType.ORGANIZATION &&
                                                                <Actions.Item action={() => handleRemove(item)}
                                                                              label={t('removeMember')}/>}
                                                        </Actions>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            {getUsers.isLoading && <SpinnerComponent/>}
                            <PaginateTable
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                totalEntities={totalEntities}
                                isLoadingTable={getUsers.isLoading}
                                exportHandler={exportUserHandler}
                                pageCount={pageCount}
                                isShowDownload={false}
                            />
                        </>
                    )}
                </div>
            )}

            {openModal && (
                <UserModal
                    headerTitle={renderHeader}
                    isEdit={isEdit}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    targetData={targetUser}
                    successFunc={getUsers.refetch}
                    roleOptions={roleOptions}
                    type={props.type}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setIsEdit={setIsEdit}
                />
            )}
        </>
    );
};
export default UserManagementPage;
