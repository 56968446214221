// eslint-disable-next-line
import {propsBasicLayoutType} from 'utils/proptypes';

const BasicLayout = (props: propsBasicLayoutType) => {
    const {children} = props;
    return (
        <>{children}</>
    );
};
export default BasicLayout;
