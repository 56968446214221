import { TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import './style.scss';
import { omit } from 'lodash';
import { themInput } from 'utils/theme';
import {HiOutlineDuplicate, HiOutlineMail} from "react-icons/hi";

const InputText = (props: any) => {
  const { name, placeholder, hookForm, isHolderLabel, className, disabled, handleKeyPress, icon, type = "text", step = "0" } = props;
  const {
    formState: { errors },
    register,
    getValues,
  } = hookForm;

  const [focus, setFocus] = useState(!!getValues(name));
  useEffect(() => {
    setFocus(!!getValues(name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(name)]);

  useEffect(() => {
    if (getValues(name) !== '') setFocus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(name)]);

  const handleOnKeyPress = (e: any) => {
    setFocus(true)
    if (handleKeyPress) {
      handleKeyPress(e)
    }
  }

  return (
    <div className={`flex-1 input-text relative ${className || ''}`}>
      {isHolderLabel && <span className={`holder ${focus ? 'label' : ''}`}>{placeholder}</span>}
      <TextInput
        theme={themInput}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setFocus(!!getValues(name));
          return register(name).onBlur;
        }}
        onClick={() => setFocus(true)}
        className="flex-1 items-center"
        color={errors[name] ? 'failure' : 'gray'}
        id={name}
        type={type}
        step={step}
        placeholder={!isHolderLabel ? placeholder : ''}
        onKeyPress={e => handleOnKeyPress(e)}
        /* eslint-disable react/jsx-props-no-spreading */
        {...(register ? omit(register(name), ['onBlur']) : {})}
        disabled={disabled}
        helperText={<span className="text-xs font-normal">{errors?.[name]?.message}</span>}
      />
      <div className="absolute right-3 top-[14px] text-gray-500">{icon}</div>
    </div>
  );
};

export default InputText;
