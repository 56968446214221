import { Table } from 'flowbite-react';
import './dataTable.scss';
import SortIcon from '../sortIcon';
import classnames from 'classnames';

const DataTable = (props: any) => {
  const { data, columns, onRowClicked , sortBy, sortByType, handleHeaderSort} = props;
  const handleOnRowClicked = (item: any) => {
    if (onRowClicked) onRowClicked(item);
  };
  return (
    <div className="data-table">
      <Table striped={true}>
        <Table.Head className="text-sm text-gray-600 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          {columns.map((item: any) => (
            <Table.HeadCell key={item.id} className={classnames({'cursor-pointer':item?.sortField && sortBy === item?.sortField})} onClick={()=>handleHeaderSort(item?.sortField)}>
              <div className="flex">
              {item.name}
              {item?.sortField && sortBy === item?.sortField && (<SortIcon sortByType={sortByType} />)}
              </div>
            </Table.HeadCell>
          ))}
        </Table.Head>
        <Table.Body className="divide-y">
          {data?.map((item: any) => {
            return (
              <Table.Row
                key={item.id}
                className={`bg-white dark:border-gray-700 dark:bg-gray-900 ${!item.isEmpty ? 'cursor-pointer' : ''}`}
                onClick={() => handleOnRowClicked(item)}
              >
                {columns.map((col: any) => {
                  return <Table.Cell key={item.name}>{item[col.selector]}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default DataTable;
