import SidebarComponent from 'components/layouts/sidebar';
import {useSelector} from 'react-redux';
import {userSelector} from '../redux/selectors';
import {Spinner} from 'flowbite-react';
import {useTranslation} from 'react-i18next';
import Header from 'components/header';

const DashboardPage = () => {
    const [t] = useTranslation();
    const {userInfo} = useSelector(userSelector);
    const building = require('../../src/assets/image/svg/building.svg').default;

    if (!userInfo?.id) {
        return (
            <Spinner/>
        );
    }
    return (
        <>
            <Header/>
            <div className="w-full overflow-hidden flex bg-white h-page relative">
                <SidebarComponent isDashboard={true}/>
                <div className="relative w-full flex flex-col main-content">
                    {userInfo?.organizations.length === 0 && (
                        <div className="h-full pt-16">
                            <div className="mb-4 flex justify-center">
                                <img alt="MileMarker logo" src={building}/>
                            </div>
                            <p className="text-2xl font-semibold text-center mb-4">{t('dashboard.notification')}</p>
                            <p className="text-sm font-normal text-center	">{t('dashboard.note')}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default DashboardPage;
