import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';
const { urls, methods, execute } = base();
const getMyTokens = (queryFilter: queryFilter) => {
  const method = methods.GET;
   const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);
  let url = `${urls.myTokens.myTokens}?${params}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createToken = () => {
  const method = methods.POST;

  const url = `${urls.myTokens.myTokens}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

const deleteToken = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.myTokens.myTokens}/${id}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

export { getMyTokens, createToken, deleteToken };
