/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import CustomModalHeader from './customModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from 'redux/actions';
import { useTranslation } from 'react-i18next';
import ChangePasswordModal from 'components/modal/ChangePasswordModal';
import 'styles/styles.scss';
import FormUpdateProfile from './formUpdateProfile';
import { userSelector } from 'redux/selectors';

const UpdateProfileModal = () => {
    const rootRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [t] = useTranslation()
    const { isOpenProfileModal } = useSelector(userSelector);
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
        const isOpenModal: any = false
        dispatch(userAction.setOpenProfileModal(isOpenModal));
    };

    return (
        <div ref={rootRef}>
            <Modal
                show={isOpenProfileModal}
                size="md"
                root={rootRef.current ?? undefined}
                onClose={closeModal}
                dismissible={true}
                className="modal-profile"
            >
                <CustomModalHeader title={t('sidebar.profile')}
                    toggle={closeModal}
                />
                <Modal.Body>
                    <div className="w-full ">
                        <FormUpdateProfile isModal isOpenProfileModal={isOpenProfileModal}/>
                        <ChangePasswordModal openModal={openModal} setOpenModal={setOpenModal} closeModal={closeModal} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>)
}
export default UpdateProfileModal