import cn from 'classnames';

const GroupButton = (props: any) => {
  const { className, buttons } = props;
  return (
    <div className={cn('flex mt-2', className)}>
      {buttons?.map((button: any, index: number) => {
        let classType = '';
        if (button?.classType === 'white') {
          classType = 'text-blue-600 hover:bg-blue-100 hover:text-white';
        } else if (button?.classType === 'blue') {
          classType = 'text-white bg-secondary ';
        }
        return (
          <div className={`relative ${index !== 0 ? 'ml-4' : ''}`} key={`key_${index}`}>
            <button
              type={button?.type}
              onClick={button?.action || (() => {})}
              disabled={button?.disabled}
              className={`button ${ button?.isLoading ? 'is-loading' : ''} ${classType} hover:drop-shadow-[0_4px_4px_rgba(255,230,177,1)] border border-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[2px] text-sm px-5 h-[34px] text-center`}
            >
              {button?.text}
            </button>
          </div>
        );
      })}
    </div>
  );
};
export default GroupButton;
