import {searchMaxLength, sortByTypeType} from 'utils/constants';
import {HiOutlineSearch, HiCalendar, HiOutlineX, HiOutlineCalendar} from 'react-icons/hi';
import {useTranslation} from 'react-i18next';
import {isEmpty} from 'lodash';
import MultiSelect from 'components/select/index';
import {useEffect, useRef, useState} from 'react';
import {getRegionByFilter} from 'api/regionApi';
import {useSelector} from 'react-redux';
import {orgSelector} from 'redux/selectors';
import {getLocationByFilter} from 'api/locationApi';
import Buttons from "../button/Buttons";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Import a Flatpickr theme
import 'rsuite/dist/rsuite.min.css';
import './actionTable.scss';

const ActionTableWithFilter = (props: any) => {
    const {
        placeholderSearch,
        buttonName,
        handleAddClick,
        exportHandler,
        handleSearch,
        hiddenSearch = false,
        location,
        setLocation,
        region,
        setRegion,
        fDate,
        setFDate,
        tDate,
        setTDate
    } = props;
    const [t] = useTranslation();
    const [locationTypeOptions, setLocationTypeOptions] = useState([{label: '...', value: ''}]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [regionTypeOptions, setRegionTypeOptions] = useState([{label: '...', value: ''}]);
    const [dateFilter, setDateFilter] = useState<any>([]);
    const {organizationId} = useSelector(orgSelector);
    const flatpickrRef = useRef<Flatpickr | null>(null);

    useEffect(() => {
        getRegionByFilter({page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId})?.then(res => {
            const newOption = res?.data?.entities?.map((i: any) => ({label: i.name, value: i.id}));
            setRegionTypeOptions(newOption);
        });

        getLocationByFilter({page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId})?.then(res => {
            const newOption = res?.data?.entities?.map((i: any) => ({label: i.name, value: i.id}));
            setLocationTypeOptions(newOption);
        });

        if (flatpickrRef.current) {
            const flatpickr = flatpickrRef.current;
            if (flatpickr) {
              flatpickr.flatpickr.set('mode', 'range');
            }
        }

        // eslint-disable-next-line
    }, []);

    const handleoOnKeyUp = (e: any) => {
        const {key, target} = e;
        if (key === 'Enter') handleSearch(target.value);
    };

    const handleoOnChange = (e: any) => {
        if (isEmpty(e.target.value)) handleSearch(e.target.value);
    };

    const handleChangeLocation = (a: any) => {
        if (a) {
            setSelectedLocation(a);
            setLocation(a.value);
        } else {
            setSelectedLocation(null);
            setLocation('');
        }
    }

    const handleChangeRegion = (option: any) => {
        if (option) {
            setSelectedRegion(option);
            setRegion(option.value);
        } else {
            setSelectedRegion(null);
            setRegion('');
        }
    };

    const handleChangeDate = (option: any) => {
        if (option) {
            if (option[0]) {
                setFDate((option[0] as Date).toLocaleDateString());
            }

            if (option[1]) {
                setTDate((option[1] as Date).toLocaleDateString());
            }
            setDateFilter(option);
        } else {
            setFDate('');
            setTDate('');
            setDateFilter([]);
        }
    };

    const handleClearSelection = () => {
        if (flatpickrRef.current) {
          flatpickrRef.current.flatpickr.clear();
          setFDate('');
          setTDate('');
        }
      };

    return (
        <div className="flex pb-3 justify-between at-requestedPOs">
            <div className="desktop">
                <div className="flex responsive">
                    <div
                        className="flex items-center text-search border border-gray-200 mr-[15px] action-search rounded-sm pl-2 h-[36px]">
                        {!hiddenSearch && (
                            <span className="pl-1"><HiOutlineSearch strokeWidth={3} className="w-4 h-4"/></span>
                        )}
                        <input
                            className="px-2 text-sm border-0 focus:outline-none focus:drop-shadow-none w-full bg-transparent"
                            type="search"
                            name="search"
                            hidden={hiddenSearch}
                            maxLength={searchMaxLength}
                            placeholder={placeholderSearch}
                            onChange={e => handleoOnChange(e)}
                            onKeyUp={e => handleoOnKeyUp(e)}
                        />
                    </div>
                    <MultiSelect
                        onChange={(e: any) => {
                            handleChangeRegion(e);
                        }}
                        options={regionTypeOptions}
                        value={selectedRegion}
                        id={`region-filter`}
                        name={`region-filter`}
                        noOptionsMessage={() => t('No options')}
                        placeholder={t('orderPage.placeholderRegion')}
                        closeMenuOnSelect
                        isHolderLabel={false}
                    />
                </div>
                <div className="flex responsive mt15">
                    <MultiSelect
                        onChange={(e: any) => {
                            handleChangeLocation(e);
                        }}
                        options={locationTypeOptions}
                        value={selectedLocation}
                        id={`location-filter`}
                        name={`location-filter`}
                        noOptionsMessage={() => t('No options')}
                        placeholder={t('orderPage.placeholderLocation')}
                        closeMenuOnSelect
                        isHolderLabel={false}
                    />
                    {/*<div className="datepicker">*/}
                    {/*     <input type='date' id='date-filter' name='date-filter' value={dateFilter} placeholder="Date"*/}
                    {/*           onChange={(e: any) => {*/}
                    {/*               handleChangeDate(e);*/}
                    {/*           }}/> */}
                    {/*</div>*/}
                </div>
                <div className="custom-date-range">
                    <Flatpickr
                        ref={flatpickrRef}
                        value={dateFilter}
                        onChange={(e: any) => handleChangeDate(e)}
                        options={
                            {
                                mode: 'range',
                                dateFormat: 'M-d-Y'
                            }
                        }
                        placeholder="Select Date"
                    />
                    {fDate !== '' && tDate !== '' ? <button onClick={handleClearSelection}><HiOutlineX /></button> : <HiOutlineCalendar />}
                </div>
            </div>
            <div className="flex justify-end action-button">
                {handleAddClick && (
                    // <div className="w-full h-full w-auto flex-row gap-2 action-add">
                    //     <button
                    //         onClick={() => handleAddClick()}
                    //         className="w-auto bg-primary text-white text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-sm"
                    //     >
                    //         {buttonName}
                    //     </button>
                    // </div>
                    <Buttons
                        className="text-white bg-secondary"
                        onClick={() => handleAddClick()}
                        text={buttonName}
                    />
                )}
                {exportHandler && (
                    // <div
                    //     className="w-auto flex-row gap-2 ml-3 bg-gray-400 flex items-center action-export rounded-sm">
                    //     <button
                    //         onClick={() => exportHandler()}
                    //         className="w-auto px-4 h-34 text-white text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10"
                    //     >
                    //         {t('export')}
                    //     </button>
                    // </div>
                    <Buttons
                        className="text-secondary border border-secondary"
                        onClick={() => exportHandler()}
                        text={t('export')}
                    />
                )}
            </div>
        </div>
    );
};
export default ActionTableWithFilter;
