// eslint-disable-next-line
import { queryFilterByOrg } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getRegionByFilter = (queryFilter: queryFilterByOrg) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.region.region}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getRegions = () => {
  const method = methods.GET;
  const url = `${urls.region.region}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getRegionById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.region.region}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getNumberRegionById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.region.region}/number/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getChildrenRegionByParent = (id: string) => {
  const method = methods.GET;
  const url = `${urls.region.region}/children/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createRegion = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.region.region}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateRegionById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.region.region}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateRegionsByIds = (ids: any,orgUserId: any) => {
  const method = methods.PUT;
  const url = `${urls.region.region}/update-user/${orgUserId}`;
  const response = execute(method, url, { authorization: true }, ids);
  return response;
};

const removeUserRegionById = (id: any, userOrgId: string) => {
  const method = methods.PUT;
  const url = `${urls.region.region}/remove-user/${id}/${userOrgId}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

const deleteRegionById = (id: string) => {
    const method = methods.DELETE;
    const url = `${urls.region.region}/${id}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

export {
  getRegionByFilter,
  getRegionById,
  getNumberRegionById,
  getChildrenRegionByParent,
  updateRegionById,
  updateRegionsByIds,
  getRegions,
  createRegion,
  removeUserRegionById,
  deleteRegionById
};
