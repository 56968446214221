/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import  { useEffect, useRef, useState } from 'react';
import { themModal } from 'utils/theme';
import '../../form/styles.scss';
import GroupButton from 'components/button/groupButton';
import { exportStockFormOrg } from 'api/stockApi';
import { useSelector } from 'react-redux';
import { orgSelector } from 'redux/selectors';
import MultiSelect from 'components/select/index';
import { getCategoriesByFilter } from 'api/categoryApi';
import { sortByTypeType } from 'utils/constants';
import { toast } from 'react-toastify';

type exportStockModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string
};
const ExportStockModal = (props: exportStockModalProps) => {
  const { openModal, setOpenModal, headerTitle } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);
  const { organizationId } = useSelector(orgSelector);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([{ label: '...', value: '' }]);
  const [selectedItemCount, setSelectedItemCount] = useState([]);
  const [categoryIds, setCategoryIds] = useState('');
  const [itemCountOptions, setItemCountOptions] = useState([{ label: 'All', value: 'all' }, { label: 'Negative', value: 'negative' }, { label: 'Positive', value: 'positive' }]);
  const [itemCount, setItemCount] = useState('all');

  useEffect(() => {
    getCategoriesByFilter({ page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId })?.then(res => {
      const newOption = res?.data?.entities?.map((i: any) => ({ label: i.name, value: i.id }));
      setCategoryTypeOptions(newOption);
    });
      // eslint-disable-next-line
  }, []);

  const toggleHandler = () => {
    setOpenModal(!openModal);
  };

  const handleExportItem = async () => {
    const res = await exportStockFormOrg(organizationId, {categoryIds, itemCount});
    if (res.data) {
        if (res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `stock-export.xlsx`);
            document.body.appendChild(link)
            link.click();

            // Remove dom after finish
            document.body.removeChild(link);
        } else {
            if (res.status === 204) {
                toast.error(`${t('uploadFile.noItemExport')}`);
            } else {
                toast.error(`${t('uploadFile.exportError')}`);
            }
        }
      }
  };

  const handleChangeCategory = (option: any) => {
    setSelectedCategory(option);

    let cateIds = '';
    for (let index = 0; index < option.length; index++) {
        const element = option[index];
        if (cateIds === '') {
            cateIds = element.value;
        } else {
            cateIds = `${cateIds},${element.value}`;
        }
    }
    setCategoryIds(cateIds);
  };

  const handleChangeItemCount = (option: any) => {
    setSelectedItemCount(option);
    setItemCount(option.value);
  };

  return (
    <div ref={rootRef} className="group-modal-container">
      <Modal
        show={openModal}
        size="lg"
        className="group-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        //dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
            <div className="mt-2 relative flex md:flex-row flex-col">
                <MultiSelect
                    onChange={handleChangeCategory}
                    options={categoryTypeOptions}
                    value={selectedCategory}
                    name="categoryId"
                    noOptionsMessage={() => t('No options')}
                    closeMenuOnSelect
                    isHolderLabel={true}
                    placeholder={`${t('sidebar.category')}`}
                    isMulti={true}
                />
            </div>
            <div className="mt-6 relative flex md:flex-row flex-col">
                <MultiSelect
                        onChange={handleChangeItemCount}
                        options={itemCountOptions}
                        value={selectedItemCount}
                        name="itemCount"
                        noOptionsMessage={() => t('No options')}
                        closeMenuOnSelect
                        isHolderLabel={true}
                        placeholder={`${t('stockPage.itemCount')}`}
                    />
            </div>
            <GroupButton
              className="items-center justify-center pt-5 mb-4"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'button',
                  text: t('modal.export'),
                  classType: 'blue',
                  action: () => handleExportItem(),
                },
              ]}
            />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ExportStockModal;
