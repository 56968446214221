type ModalHeaderTypes = {
  title?: string,
  toggle: Function,
};
const CustomModalHeader = (props: ModalHeaderTypes) => {
  const { title, toggle } = props;
  return (
    <div className="flex justify-center rounded-t pt-8">
      <h3 className="text-lg font-medium text-on-primary-container dark:text-white px-[30px] text-center">{title}</h3>
      <button
        type="button"
        className="absolute top-3 right-3 text-search bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
        data-modal-toggle="authentication-modal"
        onClick={() => toggle()}
      >
        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};
export default CustomModalHeader;
