/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import CustomModalHeader from './customModalHeader';
import { filter, isEmpty, map } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { Label, Modal } from 'flowbite-react';
import Select from 'react-select';
import { pageType } from 'utils/constants';
import './userModal.scss';
import { getRoles } from 'api/rolesApi';
import makeAnimated from 'react-select/animated';
import classNames from 'classnames';
import { themModal } from 'utils/theme';
const animatedComponents = makeAnimated();

const AssignRolesModal = (props: any) => {
  const { openModal, setOpenModal, headerTitle, type, roles, assignRoles } = props;
  const [t] = useTranslation();
  const [roleSelects, setRoleSelects] = useState([]);
  const [error, setError] = useState(false);

  const rootRef = useRef(null);
  const roleIds = map(roles, 'id');
  const { data: roleOptions }: any = useQuery(['getRoles', type], () => getRoles({ type, limit: 0 }), { staleTime: Infinity });
  const roleSelectOptions: any = filter(roleOptions?.data?.entities, (r: any) => !roleIds.includes(r?.id)).map((r: any) => ({
    value: r?.id,
    label: r?.displayName,
  }));

  const handleChange = (options: any) => {
    setRoleSelects(options);
    setError(isEmpty(options));
  };

  const handleClose = () => {
    setOpenModal(false);
    setRoleSelects([]);
    setError(false);
  };
  const handleSave = () => {
    setError(isEmpty(roleSelects));
    if (!isEmpty(roleSelects)) {
      assignRoles(roleSelects);
      handleClose();
    }
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-assign-role"
        show={openModal}
        size="xl"
        root={rootRef.current ?? undefined}
        onClose={() => handleClose()}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={() => handleClose()} />
        <Modal.Body theme={{ base: 'flex-1 pb-4 pt-3 px-12' }}>
          <div className="flex flex-col gap-4">
            <div>
              <div className="mb-2 block">
                <Label value={t('roleManagementPage.RoleModal.Role')} />
              </div>
              <Select
                isMulti={type === pageType.SITE || type === pageType.ORGANIZATION}
                closeMenuOnSelect={type !== pageType.SITE || type !== pageType.ORGANIZATION}
                options={roleSelectOptions}
                components={animatedComponents}
                onChange={option => handleChange(option)}
                className={classNames('react-select', { error })}
                classNamePrefix="react-select"
                noOptionsMessage={() => t('No options')}
                placeholder={type === pageType.SITE ? 'Multi Select' : 'Multi Select'}
                value={roleSelects}
              />
              {error && <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>
                {t('validations.required')}</div>}
            </div>
            <GroupButton
              className="items-center justify-center pt-1 pb-2"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => handleClose(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: false,
                  action: () => handleSave(),
                },
              ]}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AssignRolesModal;
