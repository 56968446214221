import { Button, Sidebar } from 'flowbite-react';
import {
  HiOutlineCog,
  HiOutlineLogout,
  HiMenuAlt2,
  HiX,
  HiUsers,
  HiOutlineOfficeBuilding,
  HiOutlineLocationMarker,
  HiHashtag,
  HiBadgeCheck,
  HiOutlineBadgeCheck,
  HiOutlineUsers,
  HiOfficeBuilding,
  HiLocationMarker,
  HiOutlineHashtag,
  HiUser,
  HiOutlineUser,
  HiCash,
  HiOutlineCash,
  HiChevronRight,
  HiCollection,
  HiOutlineCollection,
  HiDocumentText,
  HiOutlineDocumentText,
  HiShoppingBag,
  HiOutlineShoppingBag,
  HiArchive,
  HiOutlineArchive,
  HiShoppingCart,
  HiOutlineShoppingCart,
  HiStop,
  HiOutlineStop
} from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths, permissionKeys, pageType, permissionsOrg, permissionsSite } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { orgSelector, userSelector } from '../../redux/selectors';
import useUser from 'hooks/useUser';
import './styles.scss';
import { useState } from 'react';
import cn from 'classnames';
import { checkPermission, getNavbarUrl } from 'utils/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { userAction } from 'redux/actions';
import { find, isString, size } from 'lodash';
import Avatar from 'components/GroupAvatar/Avatar';
import SelectLocation from 'components/selectLocation';

const logo = require('../../assets/image/svg/logo.svg').default;

const SidebarComponent = (props?: any) => {
  const {
    READ_USER, WRITE_USER, READ_ORG, WRITE_ORG,
    READ_ROLE, WRITE_ROLE, READ_AUTHEN, WRITE_AUTHEN,
    READ_LOCATION, WRITE_LOCATION, READ_REGION, WRITE_REGION,
    READ_ITEM, WRITE_ITEM, READ_CATEGORY, WRITE_CATEGORY,
    READ_STOCK, WRITE_STOCK, READ_PURCHASE_ORDER, WRITE_PURCHASE_ORDER, REVIEW_PURCHASE_ORDER
  } = permissionKeys;
  const { ADMIN_USERS_PAGE, ROLES_PAGE, ORGANIZATION_PAGE, MY_TOKENS } = routePaths;
  const { isDashboard, type } = props || {};
  const { user } = useAuth0();
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { organizationId } = useSelector(orgSelector);
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);
  const { firstName, lastName } = userInfo;
  const { handleLogout } = useUser();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const url = location.pathname;

  const checkActivePage = (page: string | string[]): any => {
    if (isString(page)) {
      return url.indexOf(page) === 0;
    } else {
      return !!find(page, (p: any) => url.indexOf(p) === 0);
    }
  };

  const toggleMenu = () => {
    setIsShowMenu(isShowMenu => !isShowMenu);
  };

  const handleOpenModalProfile = () => {
    const isOpenModal: any = true;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };

  const renderSidebarItem = (isSubSidebar: any, route: string, IconActive: any, IconInactive: any, label: any) => {
    return (
      <li className={` ${isSubSidebar ? '!m-0' : '!mb-1'} cursor-pointer hover:bg-lightSecondary ${!isSubSidebar && checkActivePage(route) && 'bg-lightSecondary'}`}>
        <button className="flex items-center py-2 rounded-sm w-full" onClick={() => (isSubSidebar ? nav(route) : navigate(route))}>
          {!isSubSidebar && <div className={`h-5 w-1 rounded-r-sm ${checkActivePage(route) && 'bg-darkSecondary'}`} />}
          <div className="ml-2.5 mr-3">
            {isSubSidebar && <HiChevronRight className={`w-6 h-6 ${checkActivePage(route)}`} />}
            {!isSubSidebar && checkActivePage(route) && <IconActive className="w-6 h-6 text-darkSecondary" />}
            {!isSubSidebar && !checkActivePage(route) && <IconInactive className="w-6 h-6 text-darkPrimary" />}
          </div>
          <p className={`text-sm block  ${checkActivePage(route) ? 'text-darkSecondary' : 'text-darkPrimary'}`}>{t(label)}</p>
        </button>
      </li>
    );
  };

  const renderSidebarItemGroup = (route: any, IconActive: any, IconInactive: any, label: any, action?: any, sub?: boolean) => {
    const routes = [ADMIN_USERS_PAGE, ROLES_PAGE, ORGANIZATION_PAGE, MY_TOKENS];
    const newRoute = routes.includes(route) ? routes : route;
    return (
      <Sidebar.ItemGroup className="m-0 p-0 !border-t mt-0 pt-2 border-0">
        <Sidebar.Item
          theme={{
            base: 'flex px-1 py-4 hover:bg-lightSecondary text-darkSecondary sm:border-l-3 border-white cursor-pointer text-gray-900 font-medium',
            active: 'bg-lightSecondary border-gray-900 font-semibold',
          }}
          icon={checkActivePage(newRoute) ? IconActive : IconInactive}
          active={checkActivePage(newRoute)}
        >
          <p
            onClick={() => {
              toggleMenu();
              action ? action() : navigate(route);
            }}
          >
            {label}
          </p>
        </Sidebar.Item>
        {sub}
      </Sidebar.ItemGroup>
    );
  };

  const getMenuSite = (isSubSidebar = false): any => {
    return (
      <div className={`${isSubSidebar && 'pl-8'}`}>
        <Sidebar.ItemGroup className="mt-0 pt-2 border-0">
          {checkPermission(userInfo, pageType.SITE, [READ_ORG, WRITE_ORG], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORGANIZATION_PAGE, HiOfficeBuilding, HiOutlineOfficeBuilding, 'sidebar.organizations')}
          {checkPermission(userInfo, pageType.SITE, [READ_USER, WRITE_USER], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ADMIN_USERS_PAGE, HiUsers, HiOutlineUsers, 'sidebar.userManagement')}
          {checkPermission(userInfo, pageType.SITE, [READ_ROLE, WRITE_ROLE], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ROLES_PAGE, HiBadgeCheck, HiOutlineBadgeCheck, 'sidebar.roles')}
          {checkPermission(userInfo, pageType.SITE, [READ_AUTHEN, WRITE_AUTHEN], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.MY_TOKENS, HiHashtag, HiOutlineHashtag, 'sidebar.myTokens')}
        </Sidebar.ItemGroup>
      </div>
    );
  };

  const getMenuOrg = (isSubSidebar = false): any => {
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <Sidebar.ItemGroup className="mt-0 pt-2 border-0">
          {checkPermission(userInfo, pageType.ORGANIZATION, [READ_USER, WRITE_USER], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORG_USERS_PAGE, HiUsers, HiOutlineUsers, 'sidebar.userManagement')}
          {checkPermission(userInfo, pageType.ORGANIZATION, [READ_LOCATION, WRITE_LOCATION], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORG_LOCATION_PAGE, HiLocationMarker, HiOutlineLocationMarker, 'sidebar.location')}
          {checkPermission(userInfo, pageType.ORGANIZATION, [READ_REGION, WRITE_REGION], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORG_REGION_PAGE, HiCollection, HiOutlineCollection, 'sidebar.region')}
          {checkPermission(userInfo, pageType.ORGANIZATION, [READ_ITEM, WRITE_ITEM], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORG_ITEM_PAGE, HiShoppingBag, HiOutlineShoppingBag, 'sidebar.items')}
          {checkPermission(userInfo, pageType.ORGANIZATION, [READ_CATEGORY, WRITE_CATEGORY], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORG_CATEGORY_PAGE, HiDocumentText, HiOutlineDocumentText, 'sidebar.category')}
        </Sidebar.ItemGroup>
      </div>
    );
  };

  const getMenuLocationManager = (isSubSidebar = false): any => {
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <Sidebar.ItemGroup className="mt-0 pt-2 border-0 requestedPOs">
          {checkPermission(userInfo, pageType.LOCATION, [REVIEW_PURCHASE_ORDER], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.PURCHASE_ORDER_REQUESTED_PAGE, HiStop, HiOutlineStop, 'sidebar.requestedBos')}
        </Sidebar.ItemGroup>
        <SelectLocation />
        <Sidebar.ItemGroup className="mt-0 pt-2 border-0">
          {checkPermission(userInfo, pageType.LOCATION, [READ_STOCK, WRITE_STOCK], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.STOCK_PAGE, HiArchive, HiOutlineArchive, 'sidebar.stock')}
          {checkPermission(userInfo, pageType.LOCATION, [READ_PURCHASE_ORDER, WRITE_PURCHASE_ORDER], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.PURCHASE_ORDER_PAGE, HiShoppingCart, HiOutlineShoppingCart, 'sidebar.purchaseOrder')}
        </Sidebar.ItemGroup>
      </div>
    );
  };

  const nav = (path: string) => {
    toggleMenu();
    navigate(path);
  };

  return (
    <>
      {/* for desktop */}
      <div className="z-[2] flex flex-col bg-gray-50 border border-gray-200 shadow-[0_4px_6px_-1px_rgba(0,0,0,0.15)] text-left hidden-mobile">
        <Sidebar aria-label="Sidebar with logo branding" theme={{ root: { inner: '' } }} className="w-full w-64 p-2.5">
        {isDashboard && isDashboard === true ? getMenuLocationManager() : (type === pageType.SITE ? getMenuSite() : type === pageType.ORGANIZATION ? getMenuOrg() : getMenuLocationManager())}
        </Sidebar>
      </div>

      {/*  for mobile */}
      <div className="flex sm:hidden nav-menu-mobile bg-darkPrimary">
        <a aria-labelledby="flowbite-sidebar-logo-:r7:" className="flex items-center" href="/">
          <img alt="MileMarker logo" className="mr-3 h-7 sm:h-7 hover:shadow-none" src={logo} />
          {/*<span className="ml-1 text-xl font-semibold dark:text-white">{t('milemarker')}</span>*/}
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center text-sm text-gray-900 rounded-lg hover:bg-secondary p-1 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span className="sr-only">{t('openMainMenu')}</span>
          <HiMenuAlt2 className="h-7 w-7" color="white" />
        </button>
      </div>
      <div
        className={cn('', {
          'show-dropdow-menu': isShowMenu,
          hidden: !isShowMenu,
        })}
      >
        <Sidebar className="w-full lg:w-64 " theme={{ root: { inner: 'bg-white p-2' } }}>
          <div className="flex justify-end fixed right-2 top-3">
            <button
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center text-sm text-gray-900 p-2 rounded-lg hover:bg-secondary"
              aria-controls="navbar-default"
              aria-expanded="false"
              onClick={toggleMenu}
            >
              <HiX className="h-6 w-6" />
            </button>
          </div>
          <div className="flex py-2">
              <Avatar item={userInfo} />
            <div className="ml-3 flex flex-col justify-center">
              <span className="block text-sm capitalize font-medium">{`${firstName} ${lastName}`}</span>
              <span className="block truncate text-xs text-darkSecondary text-right">{user?.email}</span>
            </div>
          </div>
          <Sidebar.Items>
            {renderSidebarItemGroup(routePaths.PROFILE_PAGE, HiUser, HiOutlineUser, t('sidebar.profile'), handleOpenModalProfile)}
            {checkPermission(userInfo, pageType.ORGANIZATION, permissionsOrg, organizationId) &&
              size(userInfo.organizations) > 0 &&
              renderSidebarItemGroup(
                getNavbarUrl(userInfo, pageType.ORGANIZATION, organizationId),
                HiOfficeBuilding,
                HiOutlineOfficeBuilding,
                t('sidebar.organizationSettings'),
                null,
                type === pageType.ORGANIZATION && getMenuOrg(true),
              )}
              {checkPermission(userInfo, pageType.LOCATION, permissionsOrg, organizationId) &&
              size(userInfo.organizations) > 0 &&
              renderSidebarItemGroup(
                getNavbarUrl(userInfo, pageType.LOCATION, organizationId),
                HiLocationMarker,
                HiOutlineLocationMarker,
                t('sidebar.locationSettings'),
                null,
                type === pageType.LOCATION && getMenuLocationManager(true),
              )}
            {checkPermission(userInfo, pageType.SITE, permissionsSite, organizationId) &&
              renderSidebarItemGroup(
                getNavbarUrl(userInfo, pageType.SITE, organizationId),
                HiUsers,
                HiOutlineUsers,
                t('sidebar.siteAdmin'),
                null,
                type === pageType.SITE && getMenuSite(true),
              )}
            <span onClick={() => handleLogout()} className="flex flex-row border-t py-3.5 px-1 cursor-pointer">
              <HiOutlineLogout className="h-6 w-6 mr-3 text-red-500" />
              <span className="font-medium text-red-500">{t('logout')}</span>
            </span>
          </Sidebar.Items>
        </Sidebar>
      </div>
    </>
  );
};
export default SidebarComponent;
