import {searchMaxLength} from 'utils/constants';
import {HiOutlineSearch} from 'react-icons/hi';
import './actionTable.scss';

import {useTranslation} from 'react-i18next';
import {isEmpty} from 'lodash';
import Buttons from "../button/Buttons";

type buttonProps = {
    buttonName: string,
    buttonHidden: boolean,
    buttonAction: Function,
};
const ActionTable = (props: any) => {
    const {
        placeholderSearch,
        buttonName,
        buttons,
        handleAddClick,
        exportHandler,
        buttonExports,
        handleSearch,
        searchValue,
        hiddenSearch = false
    } = props;
    const [t] = useTranslation();

    const handleoOnKeyUp = (e: any) => {
        const {key, target} = e;
        if (key === 'Enter') handleSearch(target.value);
    };

    const handleoOnChange = (e: any) => {
        if (isEmpty(e.target.value)) handleSearch(e.target.value);
    };

    return (
        <div className="flex justify-between action-table">
            <div className="relative search">
                {!hiddenSearch && (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2 h-34">
              <HiOutlineSearch strokeWidth={3} className="w-4 h-4"/>
            </span>
                )}
                <input
                    className="w-72 h-[34px] pl-8 text-sm text-search border-1 border-gray-300 bg-white rounded-lg focus:outline-none mr-5 action-search rounded-sm"
                    type="search"
                    name="search"
                    hidden={hiddenSearch}
                    maxLength={searchMaxLength}
                    placeholder={placeholderSearch}
                    onChange={e => handleoOnChange(e)}
                    onKeyUp={e => handleoOnKeyUp(e)}
                    value={searchValue}
                />
            </div>
            <div className="flex justify-end gap-3 action-button">
                {buttons
                    ? buttons.map((button: buttonProps) => {
                        return (
                            <>
                                {button.buttonHidden && (

                                    <Buttons className="text-white bg-secondary" text={button.buttonName}
                                             onClick={() => button.buttonAction()}/>
                                )}
                            </>
                        );
                    })
                    : handleAddClick && (
                    <Buttons className="text-white bg-secondary" text={buttonName}
                             onClick={() => handleAddClick()}/>
                )}
                {buttonExports && (
                    buttonExports.map((buttonExport: buttonProps) => {
                        return (

                            <Buttons className="text-secondary border border-secondary"
                                     text={buttonExport.buttonName} onClick={() => buttonExport.buttonAction()}/>
                        )
                    })
                )}
            </div>
        </div>
    );
};
export default ActionTable;
