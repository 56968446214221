import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiDocumentDownload, HiDotsVertical, HiOutlineCog, HiPlus } from 'react-icons/hi';
import { searchMaxLength } from 'utils/constants';
// eslint-disable-next-line
type headerTableType = {
  placeholderSearch?: string,
  buttonName: string,
  handleAddClick: Function,
  searchValue: string,
  setSearchValue: Function,
  fetchData?: Function,
};
const HeaderTable = (props: headerTableType) => {
  const [t] = useTranslation();
  const { placeholderSearch, buttonName, handleAddClick, setSearchValue, searchValue, fetchData } = props;
  const [value, setValue] = useState(searchValue)
  const handleSearch = (e: any) => {
    const { key } = e;
    if (key === 'Enter') {
      setSearchValue(value)
      fetchData && fetchData();
    }
  };

  return (
    <div className="flex flex-col sm:flex-row bg-white items-center justify-between p-4 flex-wrap shadow-md">
      <div className="w-full mb-4 sm:mb-0 text-gray-600 flex flex-1 flex-row items-center">
        <input
          className="w-1/2 border-1 border-gray-300 bg-white h-9 rounded-lg text-sm focus:outline-none"
          type="search"
          name="search"
          maxLength={searchMaxLength}
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={placeholderSearch}
          onKeyDown={e => handleSearch(e)}
        />
      </div>
      {handleAddClick && (
        <div className="flex w-full sm:w-auto justify-start sm:justify-end flex-row gap-2">
          <button
            onClick={() => handleAddClick()}
            className="w-60 sm:w-auto bg-blue-600 hover:bg-blue-700 px-2.5 h-9 text-white text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-lg"
          >
            <HiPlus className="mr-1 h-4 w-4" />
            {buttonName}
          </button>
        </div>
      )}
    </div>
  );
};
export default HeaderTable;
