import { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
type chartProps = {
    data?: any,
    showHeaderChart?: boolean
};
const BarChart = (props: chartProps) => {
  const { data, showHeaderChart = false } = props;
  const chartRef = useRef<any>();
  const [chartInstance, setChartInstance] = useState<any>(null);
  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    // Check if a chart instance already exists and destroy it before creating a new one
    if (chartInstance) {
        chartInstance.destroy();
      }
    const newChartInstance = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
        },
        plugins: {
            legend: {
                display: showHeaderChart
            },
        },
      }
    });
    setChartInstance(newChartInstance);
    // Clean up the chart instance on component unmount
    return () => {
      newChartInstance.destroy();
    };
    // eslint-disable-next-line
  }, [data]);
  return <canvas ref={chartRef} />;
};
export default BarChart;