import {locationTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineBan, HiOutlineDuplicate, HiOutlinePencil, HiOutlineRefresh} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {deleteLocationById, getLocationByFilter, updateLocationById} from 'api/locationApi';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initLocation} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {apiStatus, defaultpageCount, permissionKeys, routePaths, sortByTypeType} from 'utils/constants';
import {isEqual} from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import {checkOrgAdmin, checkPermission, copyToClipboard, getUserOrg, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import {Table} from 'flowbite-react';
import Sort from 'components/table/sort';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import EmptyState from 'components/commonComponent/emptyState';
import LocationModal from 'components/modal/locationModal/locationModal';

const locationMarker = require('../../assets/image/svg/location-marker.svg').default || '';

const LocationList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_LOCATION, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [locations, setLocations] = useState([initLocation]);
    const [allLocations, setAllLocations] = useState([]);
    const [targetLocation, setTargetLocation] = useState(initLocation);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const {organizationId} = useSelector(orgSelector);
    const isWriteLocation = checkPermission(userInfo, props.type, [WRITE_LOCATION], organizationId);
    const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
    
    const getRegions = () => {
        const userOrg = userInfo?.organizations?.find((o: any) => o.id === organizationId);
        const orgRegions = userOrg?.regions;
        let regionIds = '';
        if (orgRegions) {
            for (let index = 0; index < orgRegions.length; index++) {
                const region = orgRegions[index];
                if (regionIds === '') {
                    regionIds = region.id;
                } else {
                    regionIds = `${regionIds},${region.id}`;
                }
            }
        }
        return regionIds;
    }

    const handleAddLocation = () => {
        setOpenModal(true);
        setIsEdit(false);
    };

    const onRowClickHandler = async (org: locationTypes, tab?: string) => {
        if (checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId)) {
            await handleSetTookie(org.id);
            navigate(routePaths.ORG_OVERVIEW_PAGE, {state: {tab}});
        }
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getLocations', currentPage, searchValue, sortBy, sortByType, pageCount],
        () =>getLocationByFilter({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType,
            organizationId,
            userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId,
            regionIds: isOrgAdmin === true ? '' : getRegions()
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setLocations([initLocation]);
        }
    }, [error]);

    const getAllLocations = useMutation(['getAllLocations'], {
        mutationFn: getLocationByFilter,
        onSuccess: ({data}) => setAllLocations(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any, type: string) => {
        if (type === 'name') {
            copyToClipboard(item.name, t('locationPage.copiedName'));
        } else {
            if (type === 'externalId') {
                copyToClipboard(item.externalId, t('locationPage.copiedExternalId'));
            } else {
                copyToClipboard(item.group.name, t('locationPage.copiedGroupName'));
            }
        }
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    const handleEdit = async (item: any) => {
        if (isWriteLocation) {
            setTargetLocation(item);
            setOpenModal(true);
            setIsEdit(true);
        }
    }

    const handleDelete = async (id: string) => {
        try {
            const response = await deleteLocationById(id);
            if (response.status === 200) {
                await queryClient.invalidateQueries(['getLocations'], {refetchType: 'active'}, {cancelRefetch: true});
                const message: string = t('locationPage.deleteSuccessfully')
                toast.success(message)
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    };

    const handleUpdateStatus = async (item: any, status: boolean) => {
        try {
            item.region = {id: item?.regionId};
            const payload: any = {
                ...item,
                isActive: status
            };
            const response = await updateLocationById(item?.id, payload);
            if (response.status === apiStatus.NO_CONTENT) {
                const message: string = t('locationPage.changeStatusSuccess')
                toast.success(message)
                refetch();
                queryClient.invalidateQueries(['getOrganizationDetail']);
                queryClient.invalidateQueries(['getUserDetail']);
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    };

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    useEffect(() => {
        if (data) {
            setLocations(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);
    if (isLoading) {
        return null;
    }

    return (
        <>
            {error ? <EmptyState buttonName={t('locationPage.addLocation')}
                                 handleAddClick={isWriteLocation && handleAddLocation}
                                 title={t('locationPage.titleNoResult')}
                                 subtitle={t('locationPage.subTitleNoResult')}
                                 icon={locationMarker}
                /> :
                <>
                    {!isEqual(locations[0], initLocation) && totalEntities === 0 && searchValue === ''
                        ?
                        <EmptyState buttonName={t('locationPage.addLocation')}
                                    handleAddClick={isWriteLocation && handleAddLocation}
                                    title={t('locationPage.titleNoResult')}
                                    subtitle={t('locationPage.subTitleNoResult')}
                                    icon={locationMarker}
                        />
                        :
                        !isEqual(locations[0], initLocation) && totalEntities === 0
                            ?
                            (
                                <div className="p-[30px]">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('locationPage.addLocation')}
                                        handleAddClick={isWriteLocation && handleAddLocation}
                                        handleSearch={handleSearch}
                                        searchValue={searchValue}
                                    />
                                    <NoResult/>
                                </div>
                            )
                            :
                            (
                                <div className="main-sub">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('locationPage.addLocation')}
                                        handleAddClick={isWriteLocation && handleAddLocation}
                                        handleSearch={handleSearch}
                                    />
                                    <Table className="border-b">
                                        <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                            <Table.HeadCell
                                                className="cursor-pointer bg-white font-medium text-xs px-3"
                                                onClick={() => handleHeaderSort('name')}>
                                                <div className="flex items-center">
                                                    {t('locationPage.location')}
                                                    <Sort check={sortBy === 'name'} sortByType={sortByType}/>
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs"
                                                            onClick={() => handleHeaderSort('clubCode')}>
                                                <div className="flex items-center">
                                                    {t('locationPage.clubCode')}
                                                    <Sort check={sortBy === 'externalId'} sortByType={sortByType}/>
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell
                                                className="hidden-mobile-tablet bg-white font-medium text-xs"
                                                onClick={() => {
                                                    handleHeaderSort('region');
                                                }}
                                            >
                                                <div className="flex items-center">
                                                    {t('locationPage.region')}
                                                    <Sort check={sortBy === 'region'} sortByType={sortByType}/>
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell
                                                className="hidden-mobile w-44 bg-white font-medium text-xs"
                                                onClick={() => {
                                                    handleHeaderSort('userOrgs');
                                                }}
                                            >
                                                <div className="flex items-center">
                                                    {t('locationPage.countOfMember')}
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell
                                                className="hidden-mobile w-44 bg-white font-medium text-xs"
                                                onClick={() => {
                                                    handleHeaderSort('isActive');
                                                }}
                                            >
                                                <div className="flex items-center">
                                                    {t('locationPage.status').toUpperCase()}
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell className="w-6 bg-white">{''}</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body>
                                            {locations.map((item: locationTypes, index) => (
                                                <Table.Row key={`row-${index + 1}`}
                                                           className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                                    <Table.Cell className="font-semibold py-[6px] px-3">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div className="text-secondary">{item?.name}</div>
                                                            <div
                                                                className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                <HiOutlineDuplicate size={25}
                                                                                    onClick={(e: any) => handleCopyToClipboard(e, item, 'name')}/>
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-[6px]">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div className="text-gray-900">{item?.clubCode}</div>
                                                            <div
                                                                className="copy-icon pl-3 cursor-pointer text-gray-900">
                                                                <HiOutlineDuplicate size={25}
                                                                                    onClick={(e: any) => handleCopyToClipboard(e, item, 'externalId')}/>
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-[6px]">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div
                                                                className="text-gray-900">{item?.region?.name}</div>
                                                            {item?.region?.name && <div
                                                                className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                <HiOutlineDuplicate size={25}
                                                                                    onClick={(e: any) => handleCopyToClipboard(e, item, 'region')}/>
                                                            </div>}
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-[6px]">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div
                                                                className="text-gray-900">{item?.userOrgs?.length}</div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="text-gray-900 hidden-mobile py-[8px]">
                                                        <aside className="flex flex-row items-center">
                                                                <span
                                                                    className={`w-3 h-3 flex ${item?.isActive ? 'bg-green-400' : 'bg-red-500'} rounded-lg mr-2`}/>
                                                            {t(item?.isActive ? 'active' : 'inactive')}
                                                        </aside>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-[6px]">
                                                        {isWriteLocation && (
                                                            <GroupButtonWithIcons
                                                                className="items-center justify-center"
                                                                buttons={[
                                                                    {
                                                                        type: 'button',
                                                                        text: '',
                                                                        classType: 'white',
                                                                        icon: <HiOutlinePencil size={"20px"}/>,
                                                                        action: () => handleEdit(item),
                                                                    },
                                                                    {
                                                                        type: 'button',
                                                                        text: '',
                                                                        classType: 'white',
                                                                        tooltip: item?.isActive ? t('deactivate') : t('activate'),
                                                                        icon: item?.isActive ?
                                                                            <HiOutlineBan size={"20px"}/> :
                                                                            <HiOutlineRefresh size={"20px"}/>,
                                                                        action: () => {
                                                                            handleUpdateStatus(item, item?.isActive ? false : true)
                                                                        },
                                                                    },
                                                                ]}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                    {isLoading && <SpinnerComponent/>}
                                    <PaginateTable
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        totalEntities={totalEntities}
                                        isLoadingTable={isLoading}
                                    />
                                </div>
                            )
                    }
                </>}
            {openModal && (
                <LocationModal
                    headerTitle={isEdit ? t('locationPage.editLocation') : t('locationPage.addLocation')}
                    openModal={openModal}
                    isEdit={isEdit}
                    targetData={targetLocation}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </>
    );
};

export default LocationList;
