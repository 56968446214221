import {orgTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineDuplicate} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {getOrganizationByFilter, updateStatusOrganizationById} from 'api/organizationApi';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initOrganization} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {apiStatus, defaultpageCount, exportFileName, permissionKeys, routePaths, sortByTypeType} from 'utils/constants';
import OrganizationModal from 'components/modal/organizationModal';
import {isEmpty, isEqual} from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import {checkPermission, copyToClipboard, exportToFile, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import Actions from '../../components/actions';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import {Table} from 'flowbite-react';
import Sort from 'components/table/sort';

const OrganizationList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_ORG, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {organizationId} = useSelector(orgSelector);
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [organizations, setOrganizations] = useState([initOrganization]);
    const [allOrgs, setAllOrgs] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ORG], organizationId);
    const [pageCount, setPageCount] = useState(defaultpageCount);

    const handleAddOrganization = () => {
        setOpenModal(true);
        setIsEdit(false);
    };

    const onRowClickHandler = async (org: orgTypes, tab?: string) => {
        if (checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId)) {
            await handleSetTookie(org.id);
            navigate(routePaths.ORG_OVERVIEW_PAGE, {state: {tab}});
        }
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getOrganizations', currentPage, searchValue, sortBy, sortByType, pageCount],
        () => getOrganizationByFilter({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setOrganizations([initOrganization]);
        }
    }, [error]);

    const getAllOrgs = useMutation(['getAllOrganizations'], {
        mutationFn: getOrganizationByFilter,
        onSuccess: ({data}) => setAllOrgs(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleOnClickExport = () => {
        if (organizations[0] !== initOrganization && organizations.length > 0) {
            getAllOrgs.mutate({page: 0, limit: totalEntities, searchQuery: searchValue, sortBy, sortByType});
        }
    };

    const handleActive = async (item: orgTypes) => {
        const {status} = await updateStatusOrganizationById({id: item.id, isActive: !item.isActive});
        if (status === apiStatus.NO_CONTENT) {
            refetch();
            queryClient.invalidateQueries(['getOrganizationDetail']);
            queryClient.invalidateQueries(['getUserDetail']);
        }
    };

    const handleCopyToClipboard = (e: any, item: any) => {
        copyToClipboard(item.name, t('organizationPage.copiedName'));
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    useEffect(() => {
        if (!isEmpty(allOrgs)) {
            const exportedData = allOrgs.map((or: any) => ({name: or.name}));
            exportToFile(exportedData, exportFileName.ORGANIZATION);
        }
        // eslint-disable-next-line
    }, [allOrgs]);

    useEffect(() => {
        if (data) {
            setOrganizations(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <>
            <div className="main-sub">
                <ActionTable
                    placeholderSearch="Search"
                    buttonName={t('organizationPage.addOrganization')}
                    handleAddClick={isWriteOrg && handleAddOrganization}
                    handleSearch={handleSearch}
                />
                {!isEqual(organizations[0], initOrganization) && totalEntities === 0 && <NoResult/>}
                {!isEqual(organizations[0], initOrganization) && organizations.length > 0 && (
                    <>
                        <Table className="border-b">
                            <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs py-[10px] px-3"
                                                onClick={() => handleHeaderSort('name')}>
                                    <div className="flex items-center">
                                        {t('organizationPage.organization')}
                                        <Sort check={sortBy === 'name'} sortByType={sortByType}/>
                                    </div>
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs py-[10px]"
                                    onClick={() => {
                                        handleHeaderSort('user');
                                    }}
                                >
                                    <div className="flex items-center">
                                        {t('organizationPage.user')}
                                        <Sort check={sortBy === 'user'} sortByType={sortByType}/>
                                    </div>
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs py-[10px]"
                                    onClick={() => {
                                        handleHeaderSort('countUser');
                                    }}
                                >
                                    <div className="flex items-center">
                                        {t('organizationPage.count')}
                                        <Sort check={sortBy === 'countUser'} sortByType={sortByType}/>
                                    </div>
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs py-[10px]"
                                    onClick={() => {
                                        handleHeaderSort('isActive');
                                    }}
                                >
                                    <div className="flex items-center">
                                        {t('organizationPage.status')}
                                        <Sort check={sortBy === 'isActive'} sortByType={sortByType}/>
                                    </div>
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white w-6 py-[6px]">{''}</Table.HeadCell>
                            </Table.Head>
                            <Table.Body>
                                {organizations.map((item: orgTypes, index) => (
                                    <Table.Row key={`row-${index + 1}`}
                                               className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-gray-100 border-b">
                                        <Table.Cell className="font-semibold py-[11px] px-3">
                                            <div className="flex flex-row items-center cursor-pointer"
                                                 onClick={() => onRowClickHandler(item)}>
                                                <div className="text-blue-600">{item.name}</div>
                                                <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                    <HiOutlineDuplicate size={25}
                                                                        onClick={(e: any) => handleCopyToClipboard(e, item)}/>
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="text-gray-900 hidden-mobile-tablet py-[11px]">
                                            <GroupAvatar items={item.userOrganizations.map((uo: any) => uo.user)}/>
                                        </Table.Cell>
                                        <Table.Cell className="text-gray-900 hidden-mobile py-[11px]">
                                            {item.userOrganizations.length} {item.userOrganizations.length < 2 ? t('member') : t('members')}
                                        </Table.Cell>
                                        <Table.Cell className="text-gray-900 hidden-mobile py-[11px]">
                                            <aside className="flex flex-row items-center">
                                                <span
                                                    className={`w-3 h-3 flex ${item.isActive ? 'bg-green-400' : 'bg-red-500'} rounded-lg mr-2`}/>
                                                {t(item.isActive ? 'active' : 'inactive')}
                                            </aside>
                                        </Table.Cell>
                                        <Table.Cell className="py-[11px]">
                                            {isWriteOrg && (
                                                <Actions>
                                                    <Actions.Item action={() => onRowClickHandler(item)}
                                                                  label={t('userManagementPage.overview')}/>
                                                    <Actions.Item action={() => onRowClickHandler(item, 'members')}
                                                                  label={t('userManagementPage.members')}/>
                                                    <Actions.Item action={() => handleActive(item)}
                                                                  label={item.isActive ? t('deactivate') : t('activate')}/>
                                                </Actions>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        {isLoading && <SpinnerComponent/>}
                        <PaginateTable
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            totalEntities={totalEntities}
                            exportHandler={handleOnClickExport}
                            pageCount={pageCount}
                        />
                    </>
                )}
            </div>
            {openModal && (
                <OrganizationModal
                    headerTitle={isEdit ? t('organizationPage.editOrganization') : t('organizationPage.addOrganization')}
                    openModal={openModal}
                    isEdit={isEdit}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </>
    );
};

export default OrganizationList;
