import PaginateTable from 'components/table/paginate';
import {HiOutlineDuplicate, HiOutlinePencil, HiOutlineTrash} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {deleteRegionById, getRegionByFilter, getRegionById} from 'api/regionApi';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initRegion} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {defaultpageCount, permissionKeys, sortByTypeType} from 'utils/constants';
import {isEqual} from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import {checkOrgAdmin, checkPermission, copyToClipboard, getUserOrg, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import './region.scss';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import Sort from 'components/table/sort';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import EmptyState from 'components/commonComponent/emptyState';
import RegionModal from 'components/modal/regionModal/regionModal';
import {TreeTable} from 'primereact/treetable';
import {Column} from 'primereact/column';
import CustomPaginatorTreeTable from 'components/table/customPaginateTreeTable';
import {PaginatorTemplate} from 'primereact/paginator';

const groupEmpty = require('../../assets/image/svg/collection.svg').default || '';

const RegionList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_REGION, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [regions, setRegions] = useState([initRegion]);
    const [allRegions, setAllRegions] = useState([]);
    const [targetRegion, setTargetRegion] = useState(initRegion);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const {organizationId} = useSelector(orgSelector);
    const isWriteRegion = checkPermission(userInfo, props.type, [WRITE_REGION], organizationId);
    const [nodes, setNodes] = useState([]);
    const [nodesItemPage, setNodesItemPage] = useState([]);
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState<any>([]);
    const [rows, setRows] = useState(pageCount);
    const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

    const handleAddRegion = () => {
        setOpenModal(true);
        setIsEdit(false);
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getRegions', currentPage, searchValue, sortBy, sortByType, 0],
        () => getRegionByFilter({
            page: currentPage,
            limit: 0,
            searchQuery: searchValue,
            sortBy,
            sortByType,
            organizationId,
            userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setRegions([initRegion]);
        }
    }, [error]);

    const getAllGroups = useMutation(['getAllRegions'], {
        mutationFn: getRegionByFilter,
        onSuccess: ({data}) => setAllRegions(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any) => {
        copyToClipboard(item.name, t('regionPage.copiedName'));
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    const handleEdit = async (item: any) => {
        const response = await getRegionById(item.id);
        setTargetRegion(response.data);
        setOpenModal(true);
        setIsEdit(true);
    }

    const handleDelete = async (key: string, id: string) => {
        try {
            const response = await deleteRegionById(id);
            if (response.status === 200) {
                await queryClient.invalidateQueries(['getRegions'], {refetchType: 'active'}, {cancelRefetch: true});
                const message: string = t('regionPage.deleteSuccessfully')
                toast.success(message);
                expandNode(key);
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    };

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    useEffect(() => {
        if (data) {
            setRegions(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
            let nodesData = generatedTreeData(data?.data?.entities || []);
            const nodesDataTemp = searchValue === '' ? nodesData.filter((node: any) => !node.data.parentRegion) : nodesData;
            setNodes(nodesDataTemp);
            if (currentPage === 0) setNodesItemPage(nodesDataTemp.slice(currentPage, rows));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (nodes) {
            const page = currentPage + 1;
            const startIndex = (page - 1) * rows;
            const endIndex = startIndex + rows;
            const nodesItemPage = nodes.slice(startIndex, endIndex);
            setNodesItemPage(nodesItemPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const generatedTreeData = (treeData: any) => {
        let treeNodes = [];
        treeNodes = treeData?.map((tree: any) => {
            return {
                key: `${tree.id}`,
                data: tree,
                children: generatedTreeData(tree.children)
            }
        });
        return treeNodes;
    }

    const actionTemplate = (key: string, data: any) => {
        if (isWriteRegion) {
            return (
                <GroupButtonWithIcons
                    className="items-center justify-end"
                    buttons={[
                        {
                            type: 'button',
                            text: '',
                            classType: 'white',
                            icon: <HiOutlinePencil size={"20px"}/>,
                            action: () => handleEdit(data),
                        },
                        {
                            type: 'submit',
                            text: '',
                            classType: 'white',
                            icon: <HiOutlineTrash size={"20px"}/>,
                            action: () => {
                                handleDelete(key, data?.id)
                            },
                        },
                    ]}
                />
            )
        }
    }

    const memberTemplate = (data: any) => {
        return (
            <GroupAvatar items={data?.userOrgs?.map((uo: any) => uo.user)}/>
        )
    }

    const groupTemplate = (data: any) => {
        return (
            <div className="flex flex-row items-center" onClick={() => handleEdit(data)}>
                <div className="text-secondary text-sm">{data?.name}</div>
                <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                    <HiOutlineDuplicate size={25} onClick={(e: any) => handleCopyToClipboard(e, data)}/>
                </div>
            </div>
        )
    }

    const groupHeaderTemplate = () => {
        return (
            <div className="flex items-center text-xs uppercase text-gray-900 font-medium cursor-pointer"
                 onClick={() => handleHeaderSort('name')}>
                {t('regionPage.region')}
                <Sort check={sortBy === 'name'} sortByType={sortByType}/>
            </div>
        )
    }

    const expandNode = (key: any) => {
        let _expandedKeys = {...expandedKeys};

        if (_expandedKeys[key]) delete _expandedKeys[key];
        else _expandedKeys[key] = true;

        setExpandedKeys(_expandedKeys);
    };

    const renderNode = (key: any, rowData: any) => {
        return (
            <div onClick={() => expandNode(key)}>
                <p className='text-secondary text-sm cursor-pointer group-name'>{rowData.name}</p>
            </div>
        );
    };

    if (isLoading) {
        return null;
    }

    return (
        <>
            {error ? <EmptyState buttonName={t('regionPage.addRegion')}
                                 handleAddClick={isWriteRegion && handleAddRegion}
                                 title={t('regionPage.titleNoResult')}
                                 subtitle={t('regionPage.subTitleNoResult')}
                                 icon={groupEmpty}
                /> :
                <>
                    {!isEqual(regions[0], initRegion) && totalEntities === 0 && searchValue === ''
                        ?
                        <EmptyState buttonName={t('regionPage.addRegion')}
                                    handleAddClick={isWriteRegion && handleAddRegion}
                                    title={t('regionPage.titleNoResult')}
                                    subtitle={t('regionPage.subTitleNoResult')}
                                    icon={groupEmpty}
                        />
                        :
                        !isEqual(regions[0], initRegion) && totalEntities === 0
                            ?
                            (
                                <div className="p-[30px]">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('regionPage.addRegion')}
                                        handleAddClick={isWriteRegion && handleAddRegion}
                                        handleSearch={handleSearch}
                                        searchValue={searchValue}
                                    />
                                    <NoResult/>
                                </div>
                            )
                            :
                            (
                                <div className="main-sub">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('regionPage.addRegion')}
                                        handleAddClick={isWriteRegion && handleAddRegion}
                                        handleSearch={handleSearch}
                                    />
                                    <TreeTable value={nodesItemPage} reorderableColumns expandedKeys={expandedKeys}
                                               onToggle={(e) => setExpandedKeys(e.value)}
                                               onRowClick={(e: any) => expandNode(e.node.key)}
                                               tableStyle={{minWidth: '100%'}}
                                               className="border-b"
                                               paginator
                                               rows={rows}
                                               paginatorTemplate={<CustomPaginatorTreeTable/> as PaginatorTemplate}
                                    >
                                        <Column field='name' header={groupHeaderTemplate}
                                                bodyClassName='text-secondary text-sm cursor-pointer hover:bg-gray-50 group-name'
                                                expander/>
                                        <Column
                                            body={(data: any, props: any) => actionTemplate(data.key, data.data)}
                                            style={{width: '200px'}}/>
                                    </TreeTable>
                                    {isLoading && <SpinnerComponent/>}
                                    <PaginateTable
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        totalEntities={nodes?.length}
                                        isLoadingTable={isLoading}
                                    />
                                </div>
                            )
                    }
                </>}
            {openModal && (
                <RegionModal
                    headerTitle={isEdit ? t('regionPage.editRegion') : t('regionPage.addRegion')}
                    openModal={openModal}
                    isEdit={isEdit}
                    targetData={targetRegion}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                />
            )}
        </>
    );
};

export default RegionList;
