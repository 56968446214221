import cn from 'classnames';
import { Tooltip } from 'flowbite-react';

const GroupButtonWithIcons = (props: any) => {
  const { className, buttons } = props;
  return (
    <div className={cn('flex', className)}>
        {buttons?.map((button: any, index: number) => {
        let classType = '';
        if (button?.classType === 'white') {
            classType = 'text-textContainer hover:bg-secondary rounded-[2px] hover:text-white';
        } else if (button?.classType === 'blue') {
            classType = 'text-white bg-blue-600 hover:shadow-[0 0 2px 2px #FFE6B1]';
        }
        return (
            <>
            {button?.tooltip 
            ? 
            (
                <>
                    {button?.visible 
                    ?  
                    ('')
                    :
                    (
                        <Tooltip content={button?.tooltip} placement='top'>
                            <div className={`relative ${index !== 0 ? '' : ''}`} key={`key_${index}`}>
                                <button
                                    data-tooltip-target={`tooltip-default-${index}`}
                                    type={button?.type}
                                    onClick={button?.action || (() => {})}
                                    disabled={button?.disabled}
                                    className={`button ${ button?.isLoading ? 'is-loading' : ''} ${classType} focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xs text-sm p-3 text-center`}
                                >
                                {button?.icon}{button.text}
                                </button>
                            </div>
                        </Tooltip>
                    )
                    }
                </>
            )
            :
            (
                <>
                {button?.visible 
                ? 
                ('') 
                : 
                (
                    <div className={`relative ${index !== 0 ? '' : ''}`} key={`key_${index}`}>
                    <button
                        data-tooltip-target={`tooltip-default-${index}`}
                        type={button?.type}
                        onClick={button?.action || (() => {})}
                        disabled={button?.disabled}
                        className={`button ${ button?.isLoading ? 'is-loading' : ''} ${classType} focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xs text-sm p-3 text-center`}
                    >
                    {button?.icon}{button.text}
                    </button>
                    </div>
                )
                }
                </>
            )
            }   
            </>
        );
        })}
    </div>
  );
};
export default GroupButtonWithIcons;
