/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import  { useRef } from 'react';
import { themModal } from 'utils/theme';
import '../form/styles.scss';
import GroupButton from 'components/button/groupButton';

type confirmModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  onDelete: Function
};
const ConfirmModal = (props: confirmModalProps) => {
  const { openModal, setOpenModal, onDelete } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);



  const toggleHandler = () => {
    setOpenModal(!openModal);
  };

  return (
    <div ref={rootRef} className="confirm-modal-container">
      <Modal
        show={openModal}
        size="lg"
        className="confirm-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        //dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={t('confirmDelete')} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex items-center flex-col' }}>
            <div className="text-black  text-base font-semibold mt-5 mb-3">{t('descriptionDelete')}</div>
            <GroupButton
              className="items-center justify-center pt-5 mb-4"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'button',
                  text: t('acceptDelete'),
                  classType: 'blue',
                  action: () => onDelete(),
                },
              ]}
            />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ConfirmModal;
