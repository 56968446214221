// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import userSlice from './slice/userSlice';
import roleSlice from './slice//roleSlice';
import orgSlice from './slice//orgSlice';
import locationSlice from './slice//locationSlice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  role: roleSlice.reducer,
  org: orgSlice.reducer,
  location: locationSlice.reducer
});

export default rootReducer;
