import { HiOutlineDuplicate } from 'react-icons/hi';
import { deleteRole, getPermissions, getRoles } from 'api/rolesApi';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { exportFileName, defaultpageCount, permissionKeys, sortByTypeType } from 'utils/constants';
import { roleType } from 'utils/proptypes';
import { checkPermission, copyToClipboard, exportToFile } from 'utils/utils';
import RoleModal from 'components/modal/roleModal/roleModal';
import PaginateTable from 'components/table/paginate';
import { Table } from 'flowbite-react';
import './roleManagement.scss';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import '../styles/styles.scss';
import { toast } from 'react-toastify';
import { size } from 'lodash';
import Actions from '../components/actions';
import Sort from 'components/table/sort';

const initRole = {
  id: '',
  displayName: '',
  type: '',
  permissions: [],
};

const RoleManagementPage = (props: any) => {
  const { WRITE_ROLE } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const { userInfo } = useSelector(userSelector);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [roles, setRoles] = useState([]);
  const [targetRole, setTargetRole] = useState(initRole);
  const [openModal, setOpenModal] = useState(false);
  const [permissions, setPermission] = useState([]);
  const queryClient = useQueryClient();

  const [sortBy, setSortBy] = useState('');
  const [sortByType, setSortByType] = useState('');
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ROLE], organizationId);
  const [pageCount, setPageCount] = useState(defaultpageCount);

  const getAllPermission = useMutation(['getAllPermission'], {
    mutationFn: getPermissions,
    onSuccess: ({ data }) => setPermission(data),
    onError: () => setPermission([]),
  });

  const { data, isLoading, error } = useQuery(
    ['getRoles', currentPage, searchValue, sortBy, sortByType,pageCount],
    () => getRoles({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    {
      staleTime: Infinity,
    },
  );

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getAllPermission.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setRoles(data.data?.entities || []);
      setTotalEntities(data.data?.totalEntities || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleClickEdit = (item: any) => {
    setTargetRole(item);
    setOpenModal(true);
  };

  const handleClickAddRole = () => {
    setTargetRole(initRole);
    setOpenModal(true);
  };

  const handleCloseModal = (isCloseModal: any) => {
    setOpenModal(isCloseModal);
    if (!isCloseModal) {
      setTargetRole(initRole);
    }
  };

  const handleClickDelete = async (id: string) => {
    try {
      const response = await deleteRole(id);
      if (response.status === 200) {
        await queryClient.invalidateQueries(['getRoles'], { refetchType: 'active' }, { cancelRefetch: true });
        const message: string = t('roleManagementPage.deleteSuccessfully')
        toast.success(message)
      }
    } catch (error: any) {
      const message: string = `${error.response.data.errors?.[0].detail}`;
      toast.error(message);
    }
  };

  const handleHeaderSort = (field: string) => {
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC
    } else if (sortByType === sortByTypeType.ASC) {
      type = sortByTypeType.DESC;
    } else {
      setSortBy(type);
    }
    setSortByType(type)
  }

  const handleOnClickExport = () => {
    const exportedData = roles.map((item: any) => ({
      Name: item.displayName,
      Type: item.type,
      Permissions: item?.permissions
        ?.map((itemPremission: any) => {
          return itemPremission;
        })
        .join(', '),
    }));
    exportToFile(exportedData, exportFileName.ROLE);
  };

  useEffect(() => {
    if (error) {
      setRoles([])
    }
  }, [error]);

  return (
    <div className="main-sub">
        <ActionTable
          placeholderSearch='Search'
          buttonName={t('roleManagementPage.buttonAddRoleLabel')}
          handleAddClick={checkPermission(userInfo, props.type, [WRITE_ROLE], organizationId) && handleClickAddRole}
          handleSearch={handleSearch}
        />
      {totalEntities === 0 && !isLoading && <NoResult />}
      {totalEntities > 0 && (
        <>
          <Table className="border-b">
            <Table.Head className="text-xs text-gray-900 uppercase border-b-2 border-gray-100">
              <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-0" onClick={() => handleHeaderSort('displayName')}>
                <div className="flex items-center">
                  {t('roleManagementPage.Table.ColumnColName')}
                  <Sort check={sortBy === 'displayName'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3 hidden-mobile" onClick={() => handleHeaderSort('type')}>
                <div className="flex items-center">
                  {t('roleManagementPage.Table.ColumnColType')}
                  <Sort check={sortBy === 'type'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3" onClick={() => handleHeaderSort('countPermission')}>
                <div className="flex items-center">
                  {t('roleManagementPage.Table.countPermission')}
                  <Sort check={sortBy === 'countPermission'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3" onClick={() => handleHeaderSort('description')}>
                <div className="flex items-center">
                  {t('roleManagementPage.Table.description')}
                  <Sort check={sortBy === 'description'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3 w-6">{''}</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {roles?.map((item: roleType, index) => (
                <Table.Row key={`row-${index + 1}`} className="cursor-pointer bg-white text-gray-900 text-sm hover:bg-gray-50 border-b border-gray-100">
                  <Table.Cell className="font-semibold py-[10px] px-0">
                    <div className="flex flex-row items-center">
                      <div className="text-secondary">{`${item.displayName || ''}`}</div>
                      <div className="copy-icon pl-3 cursor-pointer">
                        <HiOutlineDuplicate size={25} onClick={() => copyToClipboard(item.displayName, t('roleManagementPage.copiedName'))} />
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="py-[10px] px-3 font-normal hidden-mobile">{item.type}</Table.Cell>
                  <Table.Cell className="py-[10px] px-3 font-normal hidden-mobile">{size(item.permissions)}</Table.Cell>
                  <Table.Cell className="py-[10px] px-3 font-normal hidden-mobile-tablet">{item.description}</Table.Cell>
                  <Table.Cell className="py-[10px] px-0 font-normal">
                    {isWriteOrg && (
                      <Actions>
                        <Actions.Item action={() => handleClickEdit(item)} label={t('edit')} />
                        <Actions.Item action={() => handleClickDelete(item.id)} label={t('roleManagementPage.buttonDeleteRoleLabel')} />
                      </Actions>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {isLoading && <SpinnerComponent />}
          <PaginateTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isLoadingTable={isLoading}
            exportHandler={handleOnClickExport}
            pageCount={pageCount}
          />
        </>
      )}
      {openModal && (
        <RoleModal
          headerTitle={targetRole.id.length > 0 ? t('roleManagementPage.RoleModal.ModalEditTitle') : t('roleManagementPage.RoleModal.ModalAddTitle')}
          openModal={openModal}
          targetData={targetRole}
          isEdit={targetRole.id.length > 0}
          allAvailablePermission={permissions || []}
          handleCloseModal={(isClose: any) => handleCloseModal(isClose)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          permissions={permissions}
        />
      )}
    </div>
  );
};

export default RoleManagementPage;
