import SelectOrganization from 'components/selectOrg';
import UserProfile from 'components/userProfile';
import './header.scss'
import { useNavigate } from 'react-router-dom';
import { routePaths } from '../utils/constants';
// eslint-disable-next-line
const logo = require('../assets/image/svg/logo.svg').default;

const Header = (props: any) => {
  const { type, path } = props;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col sm:flex-row bg-darkPrimary text-white items-center justify-between py-3 px-4 flex-wrap shadow-md header hidden-mobile">

      <div className="flex flex-1 justify-between h-12 items-center">
        <div className="flex">
          <div className="logo-container">
            <button
              className="logo pl-3 h-full w-full border-gray-200 pt-6 pb-6 flex items-center cursor-pointer hover:shadow-none"
              onClick={() => navigate(routePaths.DASHBOARD_PAGE)}
            >
              <img alt="MileMarker logo" className="mr-3" src={logo} />
            </button>
          </div>
          <div className="ml-1 flex items-center">
            <SelectOrganization type={type} />
          </div>
        </div>
          <UserProfile />
      </div>
    </div>
  )
}

export default Header;
