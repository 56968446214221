import {itemTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineBan, HiOutlineDuplicate, HiOutlinePencil, HiOutlineRefresh} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initItem} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {apiStatus, defaultpageCount, permissionKeys, sortByTypeType} from 'utils/constants';
import {isEqual} from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import {checkPermission, copyToClipboard, formatMoney, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import {Table, Tooltip} from 'flowbite-react';
import Sort from 'components/table/sort';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import EmptyState from 'components/commonComponent/emptyState';
import {deleteItemById, getItemsByFilter, updateItemById} from 'api/itemApi';
import ItemModal from 'components/modal/itemModal/itemModal';

const itemEmpty = require('../../assets/image/svg/Emptys.svg').default || '';

const ItemList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_ITEM, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [items, setItems] = useState([initItem]);
    const [allItems, setAllItems] = useState([]);
    const [targetItem, setTargetItem] = useState(initItem);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const {organizationId} = useSelector(orgSelector);
    const isWriteItem = checkPermission(userInfo, props.type, [WRITE_ITEM], organizationId);

    const handleAddItem = () => {
        setOpenModal(true);
        setIsEdit(false);
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getItems', currentPage, searchValue, sortBy, sortByType, pageCount],
        () => getItemsByFilter({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType,
            organizationId
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setItems([initItem]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const getAllItemss = useMutation(['getAllItems'], {
        mutationFn: getItemsByFilter,
        onSuccess: ({data}) => setAllItems(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any, type: string) => {
        switch (type) {
            case 'name':
                copyToClipboard(item.name, t('itemPage.copiedName'));
                break;
            case 'sku':
                copyToClipboard(item.sku, t('itemPage.copiedSku'));
                break;
            case 'upc':
                copyToClipboard(item.upc, t('itemPage.copiedUpc'));
                break;
            case 'bulkUpc':
                copyToClipboard(item.bulkUpc, t('itemPage.copiedBulkUpc'));
                break;
            case 'units':
                copyToClipboard(item.units, t('itemPage.copiedUnits'));
                break;
        }
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    const handleEdit = async (item: any) => {
        if (isWriteItem) {
            setTargetItem(item);
            setOpenModal(true);
            setIsEdit(true);
        }
    }

    const handleDelete = async (id: string) => {
        try {
            const response = await deleteItemById(id);
            if (response.status === 200) {
                await queryClient.invalidateQueries(['getItems'], {refetchType: 'active'}, {cancelRefetch: true});
                const message: string = t('itemPage.deleteSuccessfully')
                toast.success(message)
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    };

    const handleUpdateStatus = async (item: any, status: boolean) => {
        try {
            item.unitPrice = parseFloat(item.unitPrice);
            item.caseQuantity = parseInt(item.caseQuantity);
            item.caseCost = parseFloat(item.caseCost);
            item.unitCost = item.caseQuantity > 0 ? item.caseCost / item.caseQuantity : 0;
            const payload: any = {
                ...item,
                isActive: status
            };
            const response = await updateItemById(item?.id, payload);
            if (response.status === apiStatus.NO_CONTENT) {
                const message: string = t('itemPage.changeStatusSuccess')
                toast.success(message)
                refetch();
                queryClient.invalidateQueries(['getOrganizationDetail']);
                queryClient.invalidateQueries(['getUserDetail']);
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    };

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    useEffect(() => {
        if (data) {
            setItems(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    if (isLoading) {
        return null;
    }

    return (
        <>
        {error ? <EmptyState buttonName={t('itemPage.addItem')}
                             handleAddClick={isWriteItem && handleAddItem}
                             title={t('itemPage.titleNoResult')}
                             subtitle={t('itemPage.subTitleNoResult')}
                             icon={itemEmpty}
            /> :
            <>
                {!isEqual(items[0], initItem) && totalEntities === 0 && searchValue === ''
                    ?
                    <EmptyState buttonName={t('itemPage.addItem')}
                                handleAddClick={isWriteItem && handleAddItem}
                                title={t('itemPage.titleNoResult')}
                                subtitle={t('itemPage.subTitleNoResult')}
                                icon={itemEmpty}
                    />
                    :
                    !isEqual(items[0], initItem) && totalEntities === 0
                        ?
                        (
                            <div className="p-[30px]">
                                <ActionTable
                                    placeholderSearch="Search"
                                    buttonName={t('itemPage.addItem')}
                                    handleAddClick={isWriteItem && handleAddItem}
                                    handleSearch={handleSearch}
                                    searchValue={searchValue}
                                />
                                <NoResult/>
                            </div>
                        )
                        :
                        (
                            <div className="main-sub">
                                <ActionTable
                                    placeholderSearch="Search"
                                    buttonName={t('itemPage.addItem')}
                                    handleAddClick={isWriteItem && handleAddItem}
                                    handleSearch={handleSearch}
                                />
                                <Table className="h-full overflow-y-scroll">
                                    <Table.Head className="text-xs text-gray-900 text-xs border-b-2 border-gray-100">
                                        <Table.HeadCell
                                            className="cursor-pointer bg-white font-medium px-3"
                                            onClick={() => handleHeaderSort('name')}>
                                            <div className="flex items-center ">
                                                {t('itemPage.item')}
                                                <Sort check={sortBy === 'name'} sortByType={sortByType}/>
                                            </div>
                                        </Table.HeadCell>
                                        <Table.HeadCell
                                            className="hidden-mobile-tablet bg-white font-medium"
                                            onClick={() => {
                                                handleHeaderSort('sku');
                                            }}
                                        >
                                            <div className="flex items-center">
                                                {t('itemPage.sku')}
                                                <Sort check={sortBy === 'sku'} sortByType={sortByType}/>
                                            </div>
                                        </Table.HeadCell>
                                        <Table.HeadCell
                                            className="hidden-mobile-tablet bg-white font-medium px-3"
                                            onClick={() => {
                                                handleHeaderSort('bulkUpc');
                                            }}
                                        >
                                            <div className="flex items-center">
                                                {t('itemPage.bulkUpc')}
                                                <Sort check={sortBy === 'bulkUpc'} sortByType={sortByType}/>
                                            </div>
                                        </Table.HeadCell>
                                        <Table.HeadCell
                                            className="hidden-mobile bg-white font-medium px-3"
                                            onClick={() => {
                                                handleHeaderSort('unitCost');
                                            }}
                                        >
                                            <div className="flex items-center">
                                                {t('itemPage.unitCost')}
                                                <Sort check={sortBy === 'unitCost'} sortByType={sortByType}/>
                                            </div>
                                        </Table.HeadCell>
                                        <Table.HeadCell
                                            className="bg-white font-medium px-3"
                                            onClick={() => {
                                                handleHeaderSort('unitPrice');
                                            }}
                                        >
                                            <div className="flex items-center">
                                                {t('itemPage.unitPrice')}
                                                <Sort check={sortBy === 'unitPrice'} sortByType={sortByType}/>
                                            </div>
                                        </Table.HeadCell>
                                        <Table.HeadCell
                                            className="hidden-mobile w-44 bg-white font-medium"
                                            onClick={() => {
                                                handleHeaderSort('isActive');
                                            }}
                                        >
                                            <div className="flex items-center">
                                                {t('itemPage.status').toUpperCase()}
                                            </div>
                                        </Table.HeadCell>
                                        <Table.HeadCell className="bg-white px-3">{''}</Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body>
                                        {items.map((item: itemTypes, index) => (
                                            <Table.Row key={`row-${index + 1}`}
                                                       className="bg-white cursor-pointer text-sm hover:bg-gray-50 border-b border-gray-100">
                                                <Table.Cell className="font-semibold py-[6px] px-3">
                                                    <div className="flex flex-row items-center"
                                                         onClick={() => handleEdit(item)}>
                                                        <div
                                                            className="text-secondary max-w-[10vw] truncate">{item?.name}</div>
                                                        <div
                                                            className="copy-icon cursor-pointer text-gray-800 pl-2">
                                                            <HiOutlineDuplicate size={25}
                                                                                onClick={(e: any) => handleCopyToClipboard(e, item, 'name')}/>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell className="py-[6px] hidden-mobile-tablet">
                                                    <div className="flex flex-row items-center"
                                                         onClick={() => handleEdit(item)}>
                                                        <div
                                                            className="text-black max-w-[8vw] truncate">{item?.sku}</div>
                                                        <div
                                                            className="copy-icon cursor-pointer text-gray-800 pl-2">
                                                            <HiOutlineDuplicate size={25}
                                                                                onClick={(e: any) => handleCopyToClipboard(e, item, 'sku')}/>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell className="py-[6px] hidden-mobile-tablet">
                                                    <div className="flex flex-row items-center"
                                                         onClick={() => handleEdit(item)}>
                                                        <div
                                                            className="text-black max-w-[8vw] truncate">{item?.bulkUpc}</div>
                                                        {item?.bulkUpc && <div
                                                            className="copy-icon cursor-pointer text-gray-800 pl-2">
                                                            <HiOutlineDuplicate size={25}
                                                                                onClick={(e: any) => handleCopyToClipboard(e, item, 'bulkUpc')}/>
                                                        </div>}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell className="py-[6px] hidden-mobile">
                                                    <div className="flex flex-row items-center"
                                                         onClick={() => handleEdit(item)}>
                                                        {item?.unitCost &&
                                                            <div className="text-black">${item?.unitCost}</div>}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell className="py-[6px] max-w-[10vw] truncate">
                                                    <div className="flex flex-row items-center"
                                                         onClick={() => handleEdit(item)}>
                                                        {item?.unitPrice && <div
                                                            className="text-black w-[8vw] truncate">{formatMoney(item.unitPrice)}</div>}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell className="text-gray-900 hidden-mobile py-[6px]">
                                                    <aside className="flex flex-row items-center">
                                                        <span
                                                            className={`w-3 h-3 flex ${item?.isActive ? 'bg-green-400' : 'bg-red-500'} rounded-lg mr-2`}/>
                                                        {t(item?.isActive ? 'active' : 'inactive')}
                                                    </aside>
                                                </Table.Cell>
                                                <Table.Cell className="py-[6px] max-w-[8vw]">
                                                    {isWriteItem && (
                                                        <GroupButtonWithIcons
                                                            className="items-center justify-center"
                                                            buttons={[
                                                                {
                                                                    type: 'button',
                                                                    text: '',
                                                                    classType: 'white',
                                                                    icon: <HiOutlinePencil size={"20px"}/>,
                                                                    action: () => handleEdit(item),
                                                                },
                                                                {
                                                                    type: 'submit',
                                                                    text: '',
                                                                    classType: 'white',
                                                                    tooltip: item?.isActive ? t('deactivate') : t('activate'),
                                                                    icon: item?.isActive ?
                                                                        <HiOutlineBan size={"20px"}/> :
                                                                        <HiOutlineRefresh size={"20px"}/>,
                                                                    action: () => {
                                                                        handleUpdateStatus(item, item?.isActive ? false : true)
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                                {isLoading && <SpinnerComponent/>}
                                <PaginateTable
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    totalEntities={totalEntities}
                                    isLoadingTable={isLoading}
                                />
                            </div>
                        )
                }
            </>}
        {openModal && (
            <ItemModal
                headerTitle={isEdit ? t('itemPage.editItem') : t('itemPage.addItem')}
                openModal={openModal}
                isEdit={isEdit}
                targetData={targetItem}
                setOpenModal={setOpenModal}
                queryClient={queryClient}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
        )}
        </>
    );
};

export default ItemList;
