/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {useMutation} from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import {toast} from 'react-toastify';
import {checkPermission, messageErrors} from 'utils/utils';
import useUser from 'hooks/useUser';
import {Label, Modal, Table, TextInput, Tooltip} from 'flowbite-react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './stockModal.scss';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import '../purchaseOrderModal/purchaseOrderModal.scss';
import {useSelector} from 'react-redux';
import {getItemByUpc, getItemsByFilter} from 'api/itemApi';
import {createStock, getStockItemReconcile, getStocksByFilter} from 'api/stockApi';
import {cloneDeep, concat} from 'lodash';
import {pageType, permissionKeys, sortByTypeType} from 'utils/constants';
import EmptyState from 'components/commonComponent/emptyState';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import {HiOutlinePlus, HiOutlineTrash} from "react-icons/hi";
import MultiSelect from 'components/select/index'
import {reconcileStockTypes} from 'utils/proptypes';
import {v4 as uuidv4} from 'uuid';
import cn from 'classnames';
import SpinnerComponent from 'components/spinner';

type reconcileManuallyModalProps = {
    openParentModal: boolean,
    setOpenParentModal: Function,
    openRootModal: boolean,
    setOpenRootModal: Function,
    openModalManually: boolean,
    setOpenModalManually: Function,
    headerTitle?: string,
    subHeaderTitle?: string,
    targetData?: any,
    isEdit: boolean,
    isImportShipment: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ReconcileManuallyModal = (props: reconcileManuallyModalProps) => {
    const {
        openParentModal, 
        setOpenParentModal,
        openRootModal, 
        setOpenRootModal,
        openModalManually,
        isEdit,
        isImportShipment,
        setOpenModalManually,
        headerTitle,
        subHeaderTitle,
        targetData,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const {WRITE_STOCK, PLATFORM_ADMIN} = permissionKeys;
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabledSave, setIsDisabledSave] = useState(false);
    const [tempData, setTempData] = useState([]);
    const [itemTypeOptions, setItemTypeOptions] = useState([{label: '...', value: ''}]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedReason, setSelectedReason] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const {userInfo} = useSelector(userSelector);
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const [errorHandleItem, setErrorHandleItem] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);
    const itemEmpty = require('../../../assets/image/svg/Emptys.svg').default || '';
    const isWriteItem = checkPermission(userInfo, pageType.LOCATION, [WRITE_STOCK], organizationId);
    const reasonForDiscrepency = [
        {label: 'Spoiled/expired', value: 'Spoiled'},
        {label: 'Discarded due to damage or defect', value: 'Discarded'},
        {label: 'Shrinkage or theft', value: 'Shrinkage'},
        {label: 'Transferred items (to or from another club)', value: 'Transferred'},
        {label: 'Shipment Received', value: 'Shipment'},
        {label: 'Rapid restocked incorrect amount*', value: 'Rapid'},
        {label: 'Sales', value: 'Sale'},
        {label: 'Other*', value: 'Other'}
    ];

    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        getValues,
    } = hookForm;

    useEffect(() => {
        if (isEdit) {

            setIsLoading(true);
            const payload: any = targetData?.map((i: any) => {
                return {
                    ...i,
                    userId: userInfo.id,
                    user: {id: userInfo.id}
                }
            });
            let dataTemp: any = [];
            getStockItemReconcile(payload, organizationId, locationId).then(res => {
                dataTemp = res?.data?.map((i: any) => { i.reasonforDiscrepancy = isImportShipment === true ? 'Shipment' : ''; return i});
            }).catch(err => {
                setIsLoading(false);
                setErrorMessages(err?.response?.data?.errors[0]?.detail);
                //alert(err?.response?.data?.errors[0]?.detail); 
            });

            const settimeout = setTimeout(() => {
                setTempData(dataTemp);
            }, 6000);

            return () => {
                setIsLoading(false);
                clearTimeout(settimeout)
            }
        }

        // eslint-disable-next-line
    }, [targetData]);

    useEffect(() => {
        const combinedArray = tempData.reduce((acc: any, current: any) => {
            const existingItem = acc.find((item: any) => item.itemId === current.itemId);
            if (existingItem) {
                if (existingItem.errors) {
                    acc.push(current);
                } else {
                    existingItem.quantityOnHand += current.quantityOnHand;
                }
            } else {
                acc.push(current);
            }
            return acc;
        }, []);
        setData(combinedArray);

        // eslint-disable-next-line
    }, [tempData])

    useEffect(() => {
        getItemsByFilter({
            page: 0,
            limit: 0,
            sortByType: sortByTypeType.DESC,
            organizationId,
            isActive: true
        })?.then(res => {
            const newOption = res?.data?.entities?.map((i: any) => ({label: i.name, value: i.id}));
            setItemTypeOptions(newOption);
        });

        // eslint-disable-next-line
    }, []);

    const handleAddAndUpdateStock = async (payload: any) => {
        setIsDisabledSave(true);
        const errorRequired = [];
        for (let index = 0; index < payload.length; index++) {
            const element = payload[index];
            element.quantityOnHand = parseFloat(element.quantityOnHand);
            element.amount = element.quantityOnHand - element.previousCount;
            if (element.itemId === '') {
                errorRequired.push(t('stockPage.requiredFieldMessage'));
            } else {
                if (element.reasonforDiscrepancy === 'Rapid' || element.reasonforDiscrepancy === 'Other') {
                    if (element.discreprancyNotes === '') {
                        errorRequired.push(t('stockPage.requiredFieldMessage'));
                    } else {
                        if (errorHandleItem === true) {
                            errorRequired.push(t('itemPage.conflictedItem'));
                        }
                    }
                } else {
                    if (errorHandleItem === true) {
                        errorRequired.push(t('itemPage.conflictedItem'));
                    }
                }
            }
        }

        if (errorRequired.length > 0) {
            toast.error(`${t('stockPage.requiredFieldMessage')}`);
        } else {
            await createStock(payload);
            toggleHandler();
            if (currentPage !== 0) {
                queryClient.invalidateQueries('getStocks', {refetchActive: false}, {cancelRefetch: true});
                setCurrentPage(0);
            } else queryClient.invalidateQueries('getStocks', {refetchActive: true}, {cancelRefetch: true});
            const message: string = t('stockPage.editSuccess');
            toast.success(message);
            fetchCurrentUser();
        }
    };

    const handleAddRow = (item: any) => {
        if (item) {
            if (item.itemId === '') {
                toast.error(`${t('stockPage.requiredFieldMessage')}`);
            } else {
                if (item.reasonforDiscrepancy === 'Rapid' || item.reasonforDiscrepancy === 'Other') {
                    if (item.discreprancyNotes === '') {
                        toast.error(`${t('stockPage.requiredFieldMessage')}`);
                    } else {
                        if (errorHandleItem === true) {
                            toast.error(`${t('itemPage.conflictedItem')}`);
                        } else {
                            addRowFunc();
                        }
                    }
                } else {
                    if (errorHandleItem === true) {
                        toast.error(`${t('itemPage.conflictedItem')}`);
                    } else {
                        addRowFunc();
                    }
                }
            }
        } else {
            addRowFunc();
        }
    };

    const addRowFunc = () => {
        const newRow: reconcileStockTypes = {
            // Initialize the properties of the new row here
            // For example:
            id: uuidv4(),
            locationId: locationId,
            location: {id: locationId},
            itemId: '',
            item: null,
            userId: userInfo.id,
            user: {id: userInfo.id},
            previousCount: 0,
            quantityOnHand: 0,
            discreprancyNotes: '',
            reasonforDiscrepancy: ''
        };
        const newData: any = concat(data, newRow)
        setData(newData);
    }

    const handleDeleteRow = (id: string) => {
        const newData = data?.filter((d: any) => d.id !== id);
        setData(newData);
    };

    const mutation = useMutation(['create-update-category'], {mutationFn: handleAddAndUpdateStock});

    const onSubmit = (values: any, props: any) => {
        mutation.mutate(values, {
            onSuccess: () => {
                toggleHandler();
                if (currentPage !== 0) {
                    queryClient.invalidateQueries('getCategories', {refetchActive: false}, {cancelRefetch: true});
                    setCurrentPage(0);
                } else queryClient.invalidateQueries('getCategories', {refetchActive: true}, {cancelRefetch: true});
                const message: string = isEdit ? t('categoryPage.editSuccess') : t('categoryPage.saveSuccess');
                toast.success(message);
                fetchCurrentUser();
            },
            onError: error => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
        });
    };

    const toggleHandler = () => {
        setOpenModalManually(!openModalManually);
        setOpenParentModal(!openParentModal);
        setOpenRootModal(!openRootModal);
    };

    const handleChangeItem = async (id: string, option: any, field: any) => {
        const itemCheck = data.find((x: any) => x.itemId === option.value);
        if (itemCheck) {
            setErrorHandleItem(true);
        } else {
            setErrorHandleItem(false);
        }
        const listContentClone = cloneDeep(data);
        const findContent: any = listContentClone?.find((item: any) => item.id === id);
        findContent[field] = option.value;
        findContent['item'] = {id: option.value};
        findContent['errors'] = undefined;
        const res = await getStocksByFilter({
            page: 0,
            limit: 0,
            sortByType: sortByTypeType.DESC,
            locationId,
            itemId: option.value
        });
        if (res.data.entities?.length > 0) {
            findContent['previousCount'] = res.data.entities?.[0].quantityOnHand;
        } else {
            findContent['previousCount'] = 0;
        }

        if (findContent['action'] === 'ADD') {
            findContent['quantityOnHand'] = findContent['previousCount'] + findContent['quantity'];
        } else {
            if (findContent['action'] === 'remove') {
                findContent['quantityOnHand'] = findContent['previousCount'] - findContent['quantity'];
            } else {
                findContent['quantityOnHand'] = findContent['quantity'];
            }
        }
        setData(listContentClone);
    };

    const handleChangeReason = (id: string, option: any, field: any) => {
        const listContentClone = cloneDeep(data);
        const findContent: any = listContentClone?.find((item: any) => item.id === id);
        findContent[field] = option.value;
        setData(listContentClone);
    };

    const handleChangeContentOverview = (id: string, value: any, field: any) => {
        const listContentClone = cloneDeep(data);
        const findContent: any = listContentClone?.find((item: any) => item.id === id);
        findContent[field] = value;
        setData(listContentClone);
    };

    console.log("data: ", data);

    return (
        <div ref={rootRef} className="stock-modal-manually-container">
            <Modal
                show={openModalManually}
                size="6xl"
                className="stock-modal-manually"
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={false}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex flex-col items-center'}}>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full p-[30px] pt-[15px]">
                        <div className="text-primary text-sm font-normal text-center">{subHeaderTitle}
                        </div>
                        <>
                            {data && data.length === 0 ?
                                (isEdit ?
                                        (isLoading ? <SpinnerComponent/> : <EmptyState buttonName=""
                                                                                       handleAddClick={false}
                                                                                       title={t('stockPage.titleImportNoResult')}
                                                                                       subtitle={t('stockPage.subTitleImportNoResult')}
                                                                                       icon={itemEmpty}
                                        />)
                                        :
                                        (<EmptyState buttonName="Add Item"
                                                     handleAddClick={isWriteItem && handleAddRow}
                                                     title={t('stockPage.titleNoItemResult')}
                                                     subtitle={t('itemPage.subTitleNoResult')}
                                                     icon={itemEmpty}
                                        />)
                                )
                                :
                                (
                                    <>
                                        <Table className="reconcile-table">
                                            <Table.Head
                                                className="text-gray-900 border-b border-gray-100 border-b-2 w-full">
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium text-xs item px-0 py-0 pr-0">
                                                    {t('stockPage.reconcileItem')}
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium text-xs previous py-0 px-0">
                                                    {t('stockPage.previousCount')}
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium text-xs current py-0 px-0">
                                                    {t('stockPage.currentCount')}
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium text-xs reason py-0 px-0">
                                                    {t('stockPage.reasonForDiscrepency')}
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium text-xs discrepency py-0 px-0">
                                                    {t('stockPage.discrepencyNotes')}
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className=" bg-white font-medium text-xs action py-0 px-0">{''}</Table.HeadCell>
                                            </Table.Head>
                                            <Table.Body className={cn("", {
                                                '--overflow': data?.length > 6
                                            })}>
                                                {data.map((item: reconcileStockTypes, index) => {
                                                    return (
                                                        <Table.Row key={`row-${item.id}`}
                                                                   className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                                            {item.itemId === '' ?
                                                            (
                                                                <Table.Cell className="px-0 pr-3 py-3 item">
                                                                    <MultiSelect
                                                                        onChange={(e: any) => {
                                                                            handleChangeItem(item?.id, e, 'itemId')
                                                                        }}
                                                                        options={itemTypeOptions}
                                                                        value={itemTypeOptions.find((i: any) => i.value === item.itemId)}
                                                                        hookForm={hookForm}
                                                                        id={`item-${index}`}
                                                                        name={`item-${index}`}
                                                                        noOptionsMessage={() => t('No options')}
                                                                        closeMenuOnSelect
                                                                        isHolderLabel={false}
                                                                        className="select-item"
                                                                    />
                                                                    <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{t('userManagementPage.requiredField')}</div>
                                                                </Table.Cell>
                                                            )
                                                            :
                                                            (
                                                                <Tooltip content={itemTypeOptions.find((i: any) => i.value === item.itemId) !== null ? itemTypeOptions.find((i: any) => i.value === item.itemId)?.label : ""}>
                                                                    <Table.Cell className="px-0 py-3 item">
                                                                        <MultiSelect
                                                                            onChange={(e: any) => {
                                                                                handleChangeItem(item?.id, e, 'itemId')
                                                                            }}
                                                                            options={itemTypeOptions}
                                                                            value={itemTypeOptions.find((i: any) => i.value === item.itemId)}
                                                                            hookForm={hookForm}
                                                                            id={`item-${index}`}
                                                                            name={`item-${index}`}
                                                                            noOptionsMessage={() => t('No options')}
                                                                            closeMenuOnSelect
                                                                            isHolderLabel={false}
                                                                            className="select-item"
                                                                        />
                                                                        {errorHandleItem === true && (<div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{t('itemPage.conflictedItem')}</div>)}
                                                                        {item.errors && (<div className={`text-red-600 text-xs font-normal mt-1 warning`}>{t('itemPage.notFoundItem').replace('%UPC%', item.upc || '')}</div>)}
                                                                    </Table.Cell>
                                                                </Tooltip>
                                                            )
                                                            }
                                                            <Table.Cell key={item?.previousCount}
                                                                        className="py-3 px-0 previous">
                                                                <Label>{item?.errors ? 0 : item?.previousCount}</Label>
                                                            </Table.Cell>
                                                            <Table.Cell className="py-3 px-0 current">
                                                                <TextInput
                                                                    type="number"
                                                                    name={`quantityOnHand-${index}`}
                                                                    value={item?.errors ? 0 : item?.quantityOnHand}
                                                                    onChange={(e: any) => {
                                                                        handleChangeContentOverview(item?.id, e.target.value, 'quantityOnHand')
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Tooltip content={reasonForDiscrepency.find((i: any) => i.value === item.reasonforDiscrepancy) !== null ? reasonForDiscrepency.find((i: any) => i.value === item.reasonforDiscrepancy)?.label : ""}>
                                                                <Table.Cell className="py-3 px-0 reason">
                                                                    <MultiSelect
                                                                        onChange={(e: any) => {
                                                                            handleChangeReason(item?.id, e, 'reasonforDiscrepancy')
                                                                        }}
                                                                        options={reasonForDiscrepency}
                                                                        value={reasonForDiscrepency.find((r: any) => r.value === item.reasonforDiscrepancy)}
                                                                        hookForm={hookForm}
                                                                        name={`reasonforDiscrepancy-${index}`}
                                                                        noOptionsMessage={() => t('No options')}
                                                                        closeMenuOnSelect
                                                                        isHolderLabel={false}
                                                                        className="select-reason"
                                                                    />
                                                                </Table.Cell>
                                                            </Tooltip>
                                                            <Table.Cell className="py-3 px-0 discrepency">
                                                                <TextInput
                                                                    type="text"
                                                                    name={`discrepancyNote-${index}`}
                                                                    value={item?.discreprancyNotes}
                                                                    onChange={(e: any) => {
                                                                        handleChangeContentOverview(item?.id, e.target.value, 'discreprancyNotes')
                                                                    }}
                                                                    required={true}
                                                                />
                                                                {(item.reasonforDiscrepancy === 'Rapid' || item.reasonforDiscrepancy === 'Other') && item.discreprancyNotes === '' && (
                                                                    <div
                                                                        className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{t('userManagementPage.requiredField')}</div>)}
                                                            </Table.Cell>
                                                            <Table.Cell className="py-3 px-0 action">
                                                                <GroupButtonWithIcons
                                                                    className="items-center justify-center"
                                                                    buttons={[
                                                                        {
                                                                            type: 'button',
                                                                            text: '',
                                                                            classType: 'white',
                                                                            icon: <HiOutlineTrash size={"20px"}/>,
                                                                            action: () => {
                                                                                handleDeleteRow(item?.id)
                                                                            },
                                                                        },
                                                                        {
                                                                            type: 'button',
                                                                            text: '',
                                                                            classType: 'white',
                                                                            icon: <HiOutlinePlus size={"20px"}/>,
                                                                            action: () => handleAddRow(item),
                                                                        },
                                                                    ]}
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                })}
                                            </Table.Body>
                                        </Table>

                                        <GroupButton
                                            className="items-center justify-center mt-6 mb-3"
                                            buttons={[
                                                {
                                                    type: 'button',
                                                    text: t('modal.cancel'),
                                                    classType: 'white',
                                                    action: () => toggleHandler(),
                                                },
                                                {
                                                    type: 'button',
                                                    text: t('modal.save'),
                                                    classType: 'blue',
                                                    isLoading: mutation.isLoading,
                                                    action: () => {
                                                        handleAddAndUpdateStock(data)
                                                    },
                                                    disabled: isDisabledSave
                                                },
                                            ]}
                                        />
                                    </>
                                )
                            }
                        </>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default ReconcileManuallyModal;
