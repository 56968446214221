import {Button} from 'flowbite-react';
import {useTranslation} from 'react-i18next';

const EmptyState = (props: any) => {
    console.log(props)
    const {buttonName, handleAddClick, icon, title, subtitle} = props;
    const [t] = useTranslation();
    return (
        <div className="bg-white w-full h-full p-[30px]">
            <div className="flex flex-col p-6 items-center border border-gray-200 text-center ">
                <img src={icon} alt="Icon"/>
                <div className="text-gray-500 text-xl font-semibold mt-5">{title}</div>
                <div className="text-gray-500 text-base font-normal">{subtitle}</div>
                { handleAddClick && (
                    <Button size="sm" className="bg-secondary hover:drop-shadow-xl mt-5 rounded-[2px]" onClick={() => handleAddClick()}>
                        {buttonName}
                    </Button>
                )}
            </div>
        </div>
    );
};
export default EmptyState;
