import base from './baseApi';

const createPaymentIntent = async (payload: { planId: string, amount: number }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = urls.stripes.stripes;
  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

const chargeSubscription = async (payload: { planId: string, priceId: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = urls.stripes.chargeSubscription;
  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

const confirmPayment = async (payload: { paymentIntentId: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.PUT;
  const url = urls.stripes.confirm;
  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

export { createPaymentIntent, confirmPayment, chargeSubscription };
