/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {useMutation} from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import {toast} from 'react-toastify';
import {checkOrgAdmin, checkPermission, getUserOrg, messageErrors} from 'utils/utils';
import useUser from 'hooks/useUser';
import {Modal} from 'flowbite-react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import classNames from 'classnames';
import '../../form/styles.scss';
import {getRegionByFilter} from 'api/regionApi';
import {pageType, permissionKeys, sortByTypeType} from 'utils/constants';
import {createLocation, updateLocationById} from 'api/locationApi';
import {getListUserOrgs} from 'api/userApi';
import MultiSelect from 'components/select/index'
import {isEmpty} from 'lodash';
import InputText from 'components/commonComponent/inputText';
import makeAnimated from 'react-select/animated';
import {orgSelector, userSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import './locationModal.scss';

const animatedComponents = makeAnimated();

type locationModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    targetData?: any,
    isEdit: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const LocationModal = (props: locationModalProps) => {
    const {openModal, targetData, isEdit, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage} = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const {userInfo} = useSelector(userSelector);
    const {WRITE_USER, PLATFORM_ADMIN} = permissionKeys;
    const rootRef = useRef(null);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [regionTypeOptions, setRegionTypeOptions] = useState([{label: '...', value: ''}]);
    const [memberSelects, setMemberSelects] = useState([]);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const organization = userInfo.organizations?.find((o: any) => o.id === organizationId);
    const userOrgRoles = organization.roles;
    let isUserAccessManager = false;
    userOrgRoles?.forEach((u: any) => {
        if (u.displayName === 'User Access Manager') isUserAccessManager = true;
        return;
    });
    const isAdmin = checkPermission(userInfo, pageType.ORGANIZATION, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        getValues,
    } = hookForm;

    useEffect(() => {
        getRegionByFilter({page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId})?.then(res => {
            const newOption = res?.data?.entities?.map((i: any) => ({label: i.name, value: i.id}));
            setRegionTypeOptions(newOption);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isEdit) {
            const selectRegion: any = regionTypeOptions.find(gr => gr.value === targetData.regionId);
            setSelectedRegion(selectRegion);
            setValue('region', targetData.region);
            setValue('name', targetData.name);
            setValue('clubCode', targetData.clubCode);
            setValue('abcNumber', targetData.abcNumber);
            setValue('streetAddress1', targetData.streetAddress1);
            setValue('streetAddress2', targetData.streetAddress2);
            setValue('city', targetData.city);
            setValue('state', targetData.state);
            setValue('zipCode', targetData.zipCode);
            reset(targetData);
        } else {
            reset();
        }
        // eslint-disable-next-line
    }, [regionTypeOptions]);

    const handleAddAndUpdateLocation = async (payload: any) => {
        if (isEdit) {
            payload.organizationId = organizationId;
            payload.abcNumber = parseInt(payload.abcNumber);
            await updateLocationById(targetData.id, payload);
        } else {
            payload.organizationId = organizationId;
            payload.abcNumber = parseInt(payload.abcNumber);
            payload.isActive = true;
            await createLocation(payload);
        }
    };

    const mutation = useMutation(['create-update-role'], {mutationFn: handleAddAndUpdateLocation});

    const onSubmit = (values: any, props: any) => {
        mutation.mutate(values, {
            onSuccess: () => {
                toggleHandler();
                if (currentPage !== 0) {
                    queryClient.invalidateQueries('getLocations', {refetchActive: false}, {cancelRefetch: true});
                    setCurrentPage(0);
                } else queryClient.invalidateQueries('getLocations', {refetchActive: true}, {cancelRefetch: true});
                const message: string = isEdit ? t('locationPage.editSuccess') : t('locationPage.saveSuccess');
                toast.success(message);
                fetchCurrentUser();
            },
            onError: error => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
        });
    };

    const handleChangeRegion = (option: any) => {
        setSelectedRegion(option);
        const regionSelect: any = {id: getValues("regionId")};
        setValue('region', regionSelect);
    };

    const toggleHandler = () => {
        setOpenModal(!openModal);
        reset();
    };

    return (
        <div ref={rootRef} className="location-modal-container">
            <Modal
                show={openModal}
                size="2xl"
                className=""
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'location-modal'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-4 relative">
                            <InputText
                                hookForm={hookForm}
                                placeholder={t('locationPage.modal.modalLocationName')}
                                name="name"
                                id="name"
                                isHolderLabel={true}
                            />
                        </div>
                        <div className="mt-4 relative flex gap-4">
                            <InputText
                                hookForm={hookForm}
                                name="clubCode"
                                id="clubCode"
                                placeholder={t('locationPage.modal.modalClubCode')}
                                isHolderLabel={true}
                            />
                          <InputText
                              hookForm={hookForm}
                              name="abcNumber"
                              id="abcNumber"
                              type="number"
                              placeholder={t('locationPage.modal.modalAbcNumber')}
                              isHolderLabel={true}
                          />
                        </div>

                        <div className="mt-4 relative">
                            <InputText
                                hookForm={hookForm}
                                name="streetAddress1"
                                id="streetAddress1"
                                placeholder={t('locationPage.modal.modalstreetAddress1')}
                                isHolderLabel={true}
                            />
                        </div>
                        <div className="mt-4 relative">
                            <InputText
                                hookForm={hookForm}
                                name="streetAddress2"
                                id="streetAddress2"
                                placeholder={t('locationPage.modal.modalstreetAddress2')}
                                isHolderLabel={true}
                            />
                        </div>
                        <div className="mt-4 relative flex gap-4">
                            <InputText
                                hookForm={hookForm}
                                name="city"
                                id="city"
                                placeholder={t('locationPage.modal.modalCity')}
                                isHolderLabel={true}
                            />
                          <InputText
                              hookForm={hookForm}
                              name="state"
                              id="state"
                              placeholder={t('locationPage.modal.modalState')}
                              isHolderLabel={true}
                          />
                        </div>
                        <div className="mt-4 relative flex gap-4">
                            <InputText
                                hookForm={hookForm}
                                name="zipCode"
                                id="zipCode"
                                placeholder={t('locationPage.modal.modalZipcode')}
                                isHolderLabel={true}
                            />
                          <MultiSelect
                              onChange={handleChangeRegion}
                              options={regionTypeOptions}
                              value={selectedRegion}
                              hookForm={hookForm}
                              name="regionId"
                              noOptionsMessage={() => t('No options')}
                              closeMenuOnSelect
                              isHolderLabel={true}
                              placeholder={t('locationPage.modal.modalRegion')}
                          />
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 mb-4"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.save'),
                                    classType: 'blue',
                                    isLoading: mutation.isLoading,
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default LocationModal;
