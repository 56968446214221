import base from './baseApi';
import axios from 'axios';
const { urls, methods, execute } = base();

const generateUploadPresignedUrl = (key: string, fileType: string) => {
  const method = methods.POST;

  const url = `${urls.files.generateUploadPresignedUrl}`;
  const response = execute(method, url, { authorization: true }, { key, fileType });

  return response;
};

const uploadImageByPresignUrl = async (presignedUrl: any, file: any) => {
  return axios({
    method: 'put',
    url: presignedUrl,
    data: file,
  })
    .then(res => {
      return res;
    })
    .catch(err => console.log(err, 'error'));
};

export { generateUploadPresignedUrl, uploadImageByPresignUrl };
