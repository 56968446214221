import axios from 'axios';
import { httpMethods } from 'utils/constants';
import { headerType } from 'utils/proptypes';
import urls from './urls';
import { getToken } from 'auth0';

const BaseApi = () => {
  const execute = async (method: string, endpoint: string, headers: headerType = {}, body: object = {}, isFile: boolean = false) => {
    const token = await getToken();
    axios.defaults.baseURL = urls.baseUrl;
    const requestConfig = {
      ...(method === httpMethods.GET && { params: body }),
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
        Authorization: generateAuthToken(token),
      },
    };
    if (method === httpMethods.GET) {
      if (headers.responseTye) {
        return axios.get(endpoint, {...requestConfig, responseType: 'blob'});
      } else {
        return axios.get(endpoint, requestConfig);
      }
    } else if (method === httpMethods.POST) {
      return axios.post(endpoint, body, requestConfig);
    } else if (method === httpMethods.PUT) {
      return axios.put(endpoint, body, requestConfig);
    } else if (method === httpMethods.PATCH) {
      return axios.patch(endpoint, body, requestConfig);
    } else {
      return axios.delete(endpoint, requestConfig);
    }
  };

  const generateAuthToken = (token: any) => {
    return `Bearer ${token}`;
  };

  return {
    methods: httpMethods,
    urls,
    execute,
  };
};

export default BaseApi;
