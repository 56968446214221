// eslint-disable-next-line
import { queryFilterByOrg } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getCategoriesByFilter = (queryFilter: queryFilterByOrg) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.categories.categories}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getCategories = () => {
  const method = methods.GET;
  const url = `${urls.categories.categories}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getCategoryById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.categories.categories}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createCategory = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.categories.categories}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateCategoryById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.categories.categories}/${id}`;

  // do not include items in category update
  delete payload?.items

  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteCategoryById = (id: string) => {
    const method = methods.DELETE;
    const url = `${urls.categories.categories}/${id}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

export {
    getCategoriesByFilter,
    getCategories,
    getCategoryById,
    createCategory, 
    updateCategoryById,
    deleteCategoryById
};
