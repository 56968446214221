import SidebarComponent from './sidebar';
import {propsMainLayoutType} from 'utils/proptypes';
import Header from 'components/header';

const MainLayout = (props: propsMainLayoutType) => {
    const {children, type, permissions, path} = props;
    return (
        <>
            <Header type={type} path={path}/>
            <div className="w-full overflow-hidden flex bg-white h-page relative">
                <SidebarComponent type={type} permissions={permissions}/>
                <div className="relative main-content">
                    {children}
                </div>
            </div>
        </>
    );
};
export default MainLayout;
