import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const Buttons = (props: any) => {
  const [t] = useTranslation();
    const {text, onClick, className} = props;

    return (
      <div className={cn('w-auto px-[15px] rounded-[2px] h-[34px] cursor-pointer text-sm flex justify-center items-center text-nowrap', className)} onClick={onClick} >
             {text}
      </div>
  );
};
export default Buttons;
