import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import routes from 'router/routes';
import { routeType } from 'utils/proptypes';
import useUser from 'hooks/useUser';
import MainLayout from '../components/layouts/mainLayout';
import { BasicLayout, LoginPage, UpdateProfile, NotFoundPage, ContactSupportPage } from 'pages';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'flowbite-react';
import { useSelector } from 'react-redux';
import { userSelector, orgSelector } from 'redux/selectors';
import { permissionKeys, routePaths } from 'utils/constants';
import { Helmet } from 'react-helmet';
import EmailVerification from 'pages/emailVerification';
import { checkPermission } from 'utils/utils';
import { setToken } from 'auth0';
import SignupPage from 'pages/signup';
import { find, flattenDeep, isEmpty, map, uniq } from 'lodash';

const Router = () => {
  const isUseSubscription = process.env.REACT_APP_IS_USE_SUBSCRIPTION === 'true';
  const { isLoading, error, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { PLATFORM_ADMIN } = permissionKeys;
  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const [currentToken, setCurrentToken] = useState('');

  const isRequireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION === 'true' || false;
  const isNavigateToEmailVerification = isRequireEmailVerification && !userInfo?.emailVerified;
  const platformAdmin = find(uniq(flattenDeep(map(userInfo?.roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
      setCurrentToken(token);
    };
    fetchDataAsync().catch(console.error);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      await fetchCurrentUser();
    };
    if (currentToken) fetchDataAsync().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken]);

  if (error) {
    return <div>{error.message}</div>;
  }

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePaths.LOGIN_PAGE} element={<LoginPage />} key={`route-key-login`} />
        <Route path={routePaths.SIGNUP} element={<SignupPage />} key={`route-key-signup`} />
        {!isAuthenticated && <Route path="*" element={<Navigate replace to={routePaths.LOGIN_PAGE} />} key={`route-key-navigate-login`} />}
        {isAuthenticated && isNavigateToEmailVerification && (
          <>
            <Route path={routePaths.EMAIL_VERIFICATION} element={<EmailVerification />} key={`route-key-email-verification`} />
            <Route
              path={routePaths.DASHBOARD_PAGE}
              element={<Navigate replace to={routePaths.EMAIL_VERIFICATION} />}
              key={`route-key-navigate-email-verification`}
            />
          </>
        )}
        {isAuthenticated &&
          routes.map((route, index) => {
            if (isEmpty(userInfo)) return <Route path="*" element={<></>} key={`route-key-waning`} />;

            if (userInfo?.id && !userInfo?.isActive && route.path !== routePaths.LOGOUT_PAGE) {
              return <Route path="*" element={<ContactSupportPage />} key={`route-key-contact-support`} />;
            }

            if (route.path === routePaths.UPDATE_PROFILE && userInfo?.updatedProfile) {
              const element = <Navigate to={routePaths.DASHBOARD_PAGE} />;
              return <Route path={routePaths.UPDATE_PROFILE} element={element} key={`route-key-update-profile`} />;
            }

            if (route.path === routePaths.UPDATE_PROFILE && !userInfo?.updatedProfile) {
              const element = <UpdateProfile isNavigatedFromDashboard={true} />;
              return <Route path={routePaths.UPDATE_PROFILE} element={element} key={`route-key-update-profile`} />;
            }

            if (route.path === routePaths.DASHBOARD_PAGE && userInfo.updatedProfile === null) {
              const element = <Navigate replace to={routePaths.UPDATE_PROFILE} />;
              return <Route path={routePaths.DASHBOARD_PAGE} element={element} key={`route-key-dashoard`} />;
            }

            if (route.path === routePaths.DASHBOARD_PAGE && isEmpty(userInfo.subscriptions) && !platformAdmin && isUseSubscription) {
              const element = <Navigate replace to={routePaths.SUBSCRIPTION} />;
              return <Route path={routePaths.DASHBOARD_PAGE} element={element} key={`route-key-dashoard`} />;
            }

            const checkPerm = checkPermission(userInfo, route.type, route.permissions, organizationId);
            const element = checkPerm ? <Outlet /> : <Navigate to={routePaths.DASHBOARD_PAGE} />;
            return (
              <Route path={route.path} element={element} key={`route-key-${index + 1}`}>
                {getFinalRoute(route, index)}
              </Route>
            );
          })}
        <Route path="*" element={<NotFoundPage />} key={`route-key-not-found`} />
      </Routes>
    </BrowserRouter>
  );
};

const getFinalRoute = (route: routeType, key: number) => {
  let Layout: any;
  if (route.layout === 'MainLayout') {
    Layout = MainLayout;
  } else Layout = BasicLayout;

  return (
    <Route
      path={route.path}
      element={
        <Layout type={route.type} permissions={route.permissions} path={route.path}>
          <Helmet>
            <title>{route.title ? route.title : 'Huzzard Inventory Management'}</title>
            <meta name="description" content={'Description'} />
          </Helmet>
          <route.component type={route.type} permissions={route.permissions} />
        </Layout>
      }
      key={`route-key-${key}`}
    />
  );
};

export default Router;
