/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import useUser from 'hooks/useUser';
import {Modal} from 'flowbite-react';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './purchaseOrderModal.scss';
import { useForm } from 'react-hook-form';
import BarChart from 'components/commonComponent/chart/barChart';
type statisticsSaleModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    itemName?: string,
    data?: any,
};
const StatisticsSaleModal = (props: statisticsSaleModalProps) => {
    const {openModal, setOpenModal, headerTitle, itemName, data } = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [chart, setChart] = useState<any>(null);
    const hookForm = useForm({
        mode: "onChange",
      });
    
      const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        getValues,
      } = hookForm;
    useEffect(() => {
        if (data) {
            const labels = ['4 weeks ago', '3 weeks ago', '2 weeks ago', 'last week'];
            const dataChart: any = {
                labels: labels,
                datasets: [
                    {
                        label: '',
                        data: [parseInt(data[0].count), parseInt(data[1].count), parseInt(data[2].count), parseInt(data[3].count)],
                        backgroundColor: 'rgba(1, 76, 140, 1)',
                        borderWidth: 1, 
                        barPercentage: 0.5, 
                        categoryPercentage: 0.8
                    },
                ]
            };
            
            setChart(dataChart);
        }
    
        // eslint-disable-next-line
      }, [data]);
    const toggleHandler = () => {
        setOpenModal(!openModal);
    };
    return (
        <>
            <div ref={rootRef} className="order-modal-container">
                <Modal
                    show={openModal}
                    size="2xl"
                    className="order-modal"
                    root={rootRef.current ?? undefined}
                    onClose={toggleHandler}
                    dismissible={false}
                    theme={themModal}
                >
                    <CustomModalHeader title={headerTitle?.replace('%itemName%', itemName || '')} toggle={toggleHandler}/>
                    <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
                        <form>
                            <div className="mt-4 relative">
                                <BarChart data={chart}/>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};
export default StatisticsSaleModal;