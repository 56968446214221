import {categoryTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineDuplicate, HiOutlinePencil, HiOutlineTrash} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initCategory} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {defaultpageCount, permissionKeys, sortByTypeType} from 'utils/constants';
import {isEqual} from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import {checkPermission, copyToClipboard, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import {Table} from 'flowbite-react';
import Sort from 'components/table/sort';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import EmptyState from 'components/commonComponent/emptyState';
import {deleteCategoryById, getCategoriesByFilter} from 'api/categoryApi';
import CategoryModal from 'components/modal/categoryModal/categoryModal';
import ConfirmModal from 'components/modal/confirmModal';

const categoryEmpty = require('../../assets/image/svg/document-text.svg').default || '';

const CategoryList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_CATEGORY, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [idDelete, setIdDelete] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [categories, setCategories] = useState([initCategory]);
    const [allcategories, setAllCategories] = useState([]);
    const [targetCategory, setTargetcategory] = useState(initCategory);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const {organizationId} = useSelector(orgSelector);
    const isWriteCategory = checkPermission(userInfo, props.type, [WRITE_CATEGORY], organizationId);

    const handleAddCategory = () => {
        setOpenModal(true);
        setIsEdit(false);
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getCategories', currentPage, searchValue, sortBy, sortByType, pageCount],
        () => getCategoriesByFilter({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType,
            organizationId
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setCategories([initCategory]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const getAllCategories = useMutation(['getAllcategories'], {
        mutationFn: getCategoriesByFilter,
        onSuccess: ({data}) => setAllCategories(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any, type: string) => {
        if (type === 'name') {
            copyToClipboard(item.name, t('categoryPage.copiedName'));
        } else {
            if (type === 'organizationId') {
                copyToClipboard(item.organizationId, t('categoryPage.copiedOrganization'));
            }
        }
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    const handleEdit = async (item: any) => {
        if (isWriteCategory) {
            setTargetcategory(item);
            setOpenModal(true);
            setIsEdit(true);
        }
    }

    const handleDelete = async (id: string) => {
        setIdDelete(id);
        setOpenModalConfirm(!openModalConfirm);
    };

    const onDelete = async (id: string) => {
        try {
            const response = await deleteCategoryById(id);
            if (response.status === 200) {
                await queryClient.invalidateQueries(['getCategories'], {refetchType: 'active'}, {cancelRefetch: true});
                const message: string = t('categoryPage.deleteSuccessfully')
                toast.success(message);
                setOpenModalConfirm(!openModalConfirm);
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    }

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    useEffect(() => {
        if (data) {
            setCategories(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            {error ? <EmptyState buttonName={t('categoryPage.addCategory')}
                                 handleAddClick={isWriteCategory && handleAddCategory}
                                 title={t('categoryPage.titleNoResult')}
                                 subtitle={t('categoryPage.subTitleNoResult')}
                                 icon={categoryEmpty}
                /> :
                <>
                    {!isEqual(categories[0], initCategory) && totalEntities === 0 && searchValue === ''
                        ?
                        <EmptyState buttonName={t('categoryPage.addCategory')}
                                    handleAddClick={isWriteCategory && handleAddCategory}
                                    title={t('categoryPage.titleNoResult')}
                                    subtitle={t('categoryPage.subTitleNoResult')}
                                    icon={categoryEmpty}
                        />
                        :
                        !isEqual(categories[0], initCategory) && totalEntities === 0
                            ?
                            (
                                <div className="p-[30px]">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('categoryPage.addCategory')}
                                        handleAddClick={isWriteCategory && handleAddCategory}
                                        handleSearch={handleSearch}
                                        searchValue={searchValue}
                                    />
                                    <NoResult/>
                                </div>
                            )
                            :
                            (
                                <div className="main-sub">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('categoryPage.addCategory')}
                                        handleAddClick={isWriteCategory && handleAddCategory}
                                        handleSearch={handleSearch}
                                    />
                                    <Table className="border-b">
                                        <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                            <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3"
                                                            onClick={() => handleHeaderSort('name')}>
                                                <div className="flex items-center ">
                                                    {t('categoryPage.category')}
                                                    <Sort check={sortBy === 'name'} sortByType={sortByType}/>
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell
                                                className="hidden-mobile w-44 bg-white font-medium text-xs"
                                                onClick={() => {
                                                    handleHeaderSort('item');
                                                }}
                                            >
                                                <div className="flex items-center">
                                                    {t('categoryPage.items')}
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell className="w-6 bg-white">{''}</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body>
                                            {categories.map((item: categoryTypes, index) => (
                                                <Table.Row key={`row-${index + 1}`}
                                                           className="bg-white text-sm hover:bg-gray-50 border-b border-gray-100 cursor-pointer">
                                                    <Table.Cell className="font-semibold py-2.5 px-0">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div className="text-secondary">{item?.name}</div>
                                                            <div
                                                                className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                <HiOutlineDuplicate size={25}
                                                                                    onClick={(e: any) => handleCopyToClipboard(e, item, 'name')}/>
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-2.5">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div className="text-black">{item?.countItem}</div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-2.5">
                                                        {isWriteCategory && (
                                                            <GroupButtonWithIcons
                                                                className="items-center justify-center"
                                                                buttons={[
                                                                    {
                                                                        type: 'button',
                                                                        text: '',
                                                                        classType: 'white',
                                                                        icon: <HiOutlinePencil size={'20px'}/>,
                                                                        action: () => handleEdit(item),
                                                                    },
                                                                    {
                                                                        type: 'submit',
                                                                        text: '',
                                                                        classType: 'white',
                                                                        icon: <HiOutlineTrash size={'20px'}/>,
                                                                        action: () => {
                                                                            handleDelete(item?.id)
                                                                        },
                                                                    },
                                                                ]}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                    {isLoading && <SpinnerComponent/>}
                                    <PaginateTable
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        totalEntities={totalEntities}
                                        isLoadingTable={isLoading}
                                    />
                                </div>
                            )
                    }
                </>}
            {openModal && (
                <CategoryModal
                    headerTitle={isEdit ? t('categoryPage.editCategory') : t('categoryPage.addCategory')}
                    openModal={openModal}
                    isEdit={isEdit}
                    targetData={targetCategory}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
            {openModalConfirm && (
                <ConfirmModal
                    openModal={openModalConfirm}
                    setOpenModal={setOpenModalConfirm}
                    onDelete={() => onDelete(idDelete)}
                />
            )}
        </>
    );
};

export default CategoryList;
