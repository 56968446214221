/* eslint-disable jsx-a11y/label-has-associated-control */

import { HiOutlineOfficeBuilding, HiOutlinePencil, HiOutlineUser } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { confirmAndSaveOrgProfileImage, getOrganizationById, updateOrganizationById, updateOrganizationImage, updateStatusOrganizationById } from 'api/organizationApi';
import { useTranslation } from 'react-i18next';
import { IMAGE_TYPE_REGEX, acceptImageTypeFile, permissionKeys } from 'utils/constants';
import { toast } from 'react-toastify';
import { checkPermission, messageErrors } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import './organizationDetail.scss';
import GroupAvatar from 'components/GroupAvatar/GroupAvatar';
import { Button, Tabs, TextInput, Textarea } from 'flowbite-react';
import UserManagementPage from 'pages/user/userManagement';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import GroupButton from 'components/button/groupButton';
import { useLocation } from 'react-router-dom';
import useUser from 'hooks/useUser';
import SpinnerComponent from 'components/spinner';
import { find, flattenDeep, map, uniq } from 'lodash';
import { themeTabs } from 'utils/theme';
import { generateUploadPresignedUrl, uploadImageByPresignUrl } from 'api/fileApi';

const intro = require('../../assets/image/svg/bg-intro-org.svg').default;
const avatarNone = require('../../assets/image/svg/avatar-none.svg').default;

const OrganizationDetail = (props: any) => {
  const { WRITE_ORG, PLATFORM_ADMIN } = permissionKeys;
  const location = useLocation();
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [organization, setOrganization] = useState<any>({});
  const [file, setFile] = useState<any>();
  const [imgUrl, setImgUrl] = useState('');
  const [tab, setTab] = useState(location?.state?.tab || 'overview');
  const { fetchCurrentUser } = useUser();

  const siteAdmin = find(uniq(flattenDeep(map(userInfo?.roles, (r: any) => r?.permissions))), (per: any) =>
    [WRITE_ORG, PLATFORM_ADMIN].includes(per),
  );
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ORG], organizationId);
  const textStatusLabel = organization.isActive ? 'text-red-900' : 'text-green-500';
  const bgStatusLabel = organization.isActive ? 'bg-red-50' : 'bg-green-50';
  const bgStatusButton = organization.isActive ? 'bg-red-700 enabled:hover:bg-red-600' : 'bg-green-500 enabled:hover:bg-green-400';

  const handleSuccess = (message: string) => {
    toast.success(message);
    refetch();
    fetchCurrentUser();
    queryClient.invalidateQueries(['getOrganizations']);
    queryClient.invalidateQueries(['getUserDetail']);
  };

  const handleonError = (error: string) => {
    const message: string = messageErrors(error, t);
    toast.error(message);
  };

  const { data, isLoading, refetch } = useQuery(['getOrganizationDetail', organizationId], () => getOrganizationById(organizationId), {
    staleTime: Infinity,
  });

  const updateStatus = useMutation(['updateStatusOrganization'], {
    mutationFn: updateStatusOrganizationById,
    onSuccess: () => handleSuccess(t('organizationPage.changeStatusSuccess')),
    onError: handleonError,
  });

  const mutation = useMutation(['organization'], {
    mutationFn: updateOrganizationById,
    onSuccess: () => {
      handleSuccess(t('organizationPage.editSuccess'));
      changeStatusPage();
    },
    onError: handleonError,
  });

  const mutaOrganizationImage = useMutation(['organizationImage'], {
    mutationFn: updateOrganizationImage,
    onSuccess: () => handleSuccess(t('organizationPage.changeImageSuccess')),
    onError: handleonError,
  });

  const changeStatusPage = () => {
    setIsEdit(!isEdit);
    reset({
      name: data?.data.name,
      description: data?.data.description,
    });
  };

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(60, t('organizationModal.maxLength60')),
    description: Yup.string().max(1000, t('organizationModal.maxLength1000')).nullable(),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  }: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const onSubmit = (values: any, props: any) => {
    mutation.mutate({ id: data?.data?.id, ...values });
  };

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          return;
        }
        if (currentFile.size > 8000000) {
          return;
        }
      }
      setImgUrl(URL.createObjectURL(currentFile));
      setFile(currentFile);
      currentFile.isUploaded = true;
    }
  };

  useEffect(() => {
    if (data?.data) {
      setImgUrl(data?.data?.fileImage?.url);
      setOrganization(data?.data);
      reset({
        name: data?.data.name,
        description: data?.data.description,
      });
      setOrganization(data?.data);
    }
    // eslint-disable-next-line
  }, [data]);
  const updateOrgProfileImage = async () => {
    if (file?.name) {
      const currentFileName =
        new Date().getTime() +
        file.name
          .split('.')
          .map((item: string) => item.toLowerCase())
          .join('.');
      const { data } = await generateUploadPresignedUrl(currentFileName, file.type);
      if (data) {
        const res:any = await uploadImageByPresignUrl(data, file);
        if (res.status === 200)  await confirmAndSaveOrgProfileImage({organizationId, fileName: currentFileName})
      }}
  }

  useEffect(() => {
    if (file?.name) {
      const currentFileName = file.name
        .split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      const formData = new FormData();
      formData.append('file', file, currentFileName);
      mutaOrganizationImage.mutate({ id: organizationId, file: formData });
    }
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (location.state) {
      setTab(location.state?.tab);
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line
  }, [location.key]);

  if (isLoading) return <SpinnerComponent />;
  return (
    <div className="org-detail w-full">
      <div className="banner org-top w-full flex items-center relative top-0 left-0 px-8 overflow-hidden">
        <img alt="MileMarker logo" className="absolute left-0 top-0 max-w-none min-w-full min-h-full" src={intro} />
        <div className="avatar border border-gray-200 rounded-full h-32 w-32 flex items-center justify-center bg-white relative overflow-hidden">
          {imgUrl ? <img alt="avatar" className="text-base" src={imgUrl} /> : <img alt="avatar" className="banner-image" src={avatarNone} />}
          <label
            className="icon opacity-0 bg-opacity-50 bg-gray-900 absolute h-full w-full top-0 right-0 left-0 flex items-center justify-center"
            // htmlFor="dropzone-file-organization-detail"
          >
            <HiOutlinePencil className="text-white w-8 h-8" />
            <input
            // id="dropzone-file-organization-detail"
            type="file" accept={acceptImageTypeFile} onChange={e => handleUploadImage(e)} className="hidden" />
          </label>
        </div>
        <div className="pl-8 flex-1 banner-context overflow-hidden">
          <div className="flex items-center context mb-3.5">
            <div>
              <span className="text-3xl font-semibold text-gray-900 pb-1 title mr-2 break-word">{organization?.name} </span>
            </div>
            <div>
              {organization?.isActive && <span className="bg-green-500 text-white px-2.5 p-0.5 rounded">{t('active')}</span>}
              {!organization?.isActive && <span className="bg-red-700 text-white px-2.5 p-0.5 rounded">{t('inactive')}</span>}
            </div>
          </div>
          <div className="text-gray-800 flex items-center">
            <div className="mr-2 py-1">{t('organizationPage.organizationId')}</div>
            <div className="bg-gray-100 px-2 py-1 py-0.5 rounded relative">{organization?.id}</div>
          </div>
        </div>
      </div>
      <div className="px-8 tab-content">
        <div className="box bg-white w-full p-8 mb-8 border border-gray-200 rounded-lg relative">
          {isWriteOrg && (
            <HiOutlinePencil className="bg-gray-100 h-8 w-8 p-1.5 rounded absolute right-0 top-0 cursor-pointer" onClick={changeStatusPage} />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pb-4 border-b rounded-lg">
              <div className="lg:grid lg:grid-cols-6">
                <div className="row flex py-2 col-span-3">
                  <div className="row-item flex mt-2">
                    <HiOutlineOfficeBuilding className="text-secondary h-5 w-5 mt-0.5" />
                    <div className="text-secondary mx-2 w-44">{t('organizationModal.name')}</div>
                  </div>
                  {isEdit && (
                    <div className="row-item">
                      <TextInput
                        className="flex-1 max-w-sm"
                        color={errors?.name ? 'failure' : 'gray'}
                        id="emailAddress"
                        type="text"
                        placeholder="Jese Leos"
                        /* eslint-disable react/jsx-props-no-spreading */
                        {...register('name')}
                        helperText={<>{errors?.name?.message}</>}
                      />
                    </div>
                  )}
                  {!isEdit && <div className="row-item left mr-2 flex-1 mt-2 font-semibold break-word">{organization.name}</div>}
                </div>
                <div className="flex items-baseline lg:justify-end mr-4 py-2.5 col-span-3">
                  <div className="row flex items-center">
                    <div className="row-item right flex items-center">
                      <HiOutlineUser className="text-secondary h-5 w-5" />
                      <div className="text-secondary mx-2">
                        {organization.countUser} {organization.countUser < 2 ? t('member') : t('members')}
                      </div>
                    </div>
                    <div className="row-item">
                      <GroupAvatar items={organization?.users} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 description">
              <div className="text-xl font-semibold text-gray-900 pb-3 title">{t('organizationModal.description')}</div>
              {isEdit && (
                <Textarea
                  color={errors?.description ? 'failure' : 'gray'}
                  id="description"
                  placeholder="Enter text for Description"
                  rows={6}
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...register('description')}
                  className="text-sm rounded-lg content"
                  helperText={<>{errors?.description?.message}</>}
                />
              )}
              {!isEdit && <div className="text-gray-900 content">{organization.description}</div>}
            </div>
            {isEdit && (
              <div className="mt-2 flex">
                <GroupButton
                  className="items-center justify-center pt-2 pb-2"
                  buttons={[
                    {
                      type: 'submit',
                      text: t('saveChanges'),
                      classType: 'blue',
                      isLoading: mutation.isLoading,
                    },
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => setIsEdit(false),
                    },
                  ]}
                />
              </div>
            )}
          </form>
        </div>
        {siteAdmin && (organization.isActive === true || organization.isActive === false) && (
          <div className={`${bgStatusLabel} w-full mt-8 p-8 rounded-lg flex justify-between action`}>
            <div>
              <div className={`text-xl font-semibold ${textStatusLabel} title pb-3`}>
                {organization.isActive ? t('organizationPage.deactivateTitle') : t('organizationPage.activateTitle')}
              </div>
              <div className={`${textStatusLabel} content`}>
                {organization.isActive ? t('organizationPage.deactivateDescrition') : t('organizationPage.activateDescrition')}
              </div>
            </div>
            <div className="flex items-center justify-end ml-2">
              <Button
                type="button"
                size="sm"
                className={`${bgStatusButton} text-sm`}
                onClick={() => updateStatus.mutate({ id: organizationId, isActive: !organization.isActive })}
              >
                {organization.isActive ? t('deactivate') : t('activate')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationDetail;
