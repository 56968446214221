/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import useUser from 'hooks/useUser';
import {Label, Modal, Table, TextInput, Tooltip} from 'flowbite-react';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './stockModal.scss';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import ReconcileManuallyModal from './reconcileManuallyModal';
import cn from 'classnames';
import {updatePurchaseOrderById} from 'api/purchaseOrderApi';
import {cloneDeep} from 'lodash';
import { getItemsByFilter } from 'api/itemApi';
import { sortByTypeType } from 'utils/constants';

type receivedShipmentModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    openParentModal: boolean,
    setOpenParentModal: Function,
    headerTitle?: string,
    targetData?: any,
    purchaseOrder?: any,
    purchaseOrderItems?: any,
    isEdit: boolean,
    isImportShipment: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ReceivedShipmentModal = (props: receivedShipmentModalProps) => {
    const {
        openParentModal,
        setOpenParentModal,
        openModal,
        targetData,
        isEdit,
        purchaseOrder,
        purchaseOrderItems,
        isImportShipment,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const {userInfo} = useSelector(userSelector);
    const [openModalManually, setOpenModalManually] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [data, setData] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [items, setItems] = useState([]);
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const {locationId} = useSelector(locationSelector);

    useEffect(() => {
        getItemsByFilter({
            page: 0,
            limit: 0,
            sortByType: sortByTypeType.DESC,
            organizationId,
            isActive: true
        })?.then(res => {
            const newOption = res?.data?.entities;
            setItemList(newOption);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (itemList?.length > 0) {
            const itemDatas: any = [];
            for (let index = 0; index < targetData.length; index++) {
                const itemFromFile = targetData[index];
                const itemFromOrder = purchaseOrderItems.find((x: any) => x.item?.upc === itemFromFile.upc || x.item?.bulkUpc === itemFromFile.upc);
                if (itemFromOrder) {
                    if (itemFromOrder.item?.bulkUpc === itemFromFile.upc) {
                        itemFromOrder.quantityReceived = itemFromOrder.quantityReceived + (itemFromFile.quantity * itemFromOrder.item?.caseQuantity);
                    } else {
                        itemFromOrder.quantityReceived = itemFromOrder.quantityReceived + itemFromFile.quantity;
                    }
                    itemDatas.push(itemFromOrder);
                } else {
                    const otherItem: any = itemList.find((x: any) => x.upc === itemFromFile.upc || x.bulkUpc === itemFromFile.upc);
                    const poItemNew: any = {
                        itemId: '',
                        item: null,
                        quantityOrdered: 0,
                        quantityReceived: 0
                    };
                    if (otherItem) {
                        if (otherItem?.bulkUpc === itemFromFile.upc) {
                            poItemNew.itemId = otherItem.id;
                            poItemNew.item = otherItem;
                            poItemNew.quantityReceived = itemFromFile.quantity * otherItem.caseQuantity;
                        } else {
                            poItemNew.itemId = otherItem.id;
                            poItemNew.item = otherItem;
                            poItemNew.quantityReceived = itemFromFile.quantity;
                        }

                        itemDatas.push(poItemNew);
                    }
                }
            }
           
            setItems(itemDatas);
        }
            

        // eslint-disable-next-line
    }, [purchaseOrderItems, targetData, itemList])

    const toggleHandler = () => {
        setOpenModal(!openModal);
        setOpenParentModal(!openParentModal);
    };

    const toggleContinue = async () => {
        purchaseOrder.purchaseOrderItems = items;
        purchaseOrder.status = 'Received';
        purchaseOrder.receivedBy = userInfo.id;
        purchaseOrder.receivedUser = {id: userInfo.id};
        const res = await updatePurchaseOrderById(purchaseOrder.id, purchaseOrder);
        setData(targetData);
        setIsImport(!isImport);
        setOpenModalManually(!openModalManually);
    };

    const handleChangeContentOverview = (id: string, value: any, field: any) => {
        const listContentClone = cloneDeep(items);
        const findContent: any = listContentClone?.find((item: any) => item.itemId === id);
        findContent[field] = parseInt(value);
        setItems(listContentClone);
    };

    return (
        <>
            <div ref={rootRef} className="import-shipment-modal h-modal">
                <Modal
                    show={openModal}
                    size="6xl"
                    className="stock-modal"
                    root={rootRef.current ?? undefined}
                    onClose={toggleHandler}
                    dismissible={false}
                    theme={themModal}
                >
                    <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                    <Modal.Body theme={{base: 'flex flex-col p-[30px] w-full max-h-[700px] overflow-y-auto'}}>
                        {/*Order Item*/}
                        <Table>
                            <Table.Head className="text-gray-900 border-b border-gray-100 border-b-2 w-full">
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[40%] px-3 py-2">
                                    {t('itemPage.item')}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('itemPage.sku').toUpperCase()}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('orderPage.caseOrdered').toUpperCase()}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('orderPage.unitOrdered').toUpperCase()}
                                </Table.HeadCell>
                                <Table.HeadCell
                                    className="cursor-pointer bg-white font-medium text-xs w-[15%] px-3 py-0">
                                    {t('orderPage.unitReceived').toUpperCase()}
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className={cn("", {
                                '--overflow': items?.length > 6
                            })}>
                                {items?.map((item: any, index) => {
                                    return (
                                        <Table.Row key={`row-${item.id}`}
                                                   className="bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                            <Table.Cell className="px-3 py-3 w-[40%]">
                                                <Tooltip
                                                    content={item.item?.name}><Label>{item?.item?.name}</Label></Tooltip>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%]">
                                                <Label>{item?.item?.sku}</Label>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%]">
                                                <Label>{item?.quantityOrdered}</Label>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%]">
                                                <Label>{item?.quantityOrdered * item?.item?.caseQuantity}</Label>
                                            </Table.Cell>
                                            <Table.Cell className="px-3 py-3 w-[15%] custom-input">
                                                <TextInput
                                                    type="number"
                                                    name={`quantityReceived-${index}`}
                                                    value={item?.quantityReceived}
                                                    onChange={(e: any) => {
                                                        handleChangeContentOverview(item?.itemId, e.target.value, 'quantityReceived')
                                                    }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                        <div className="w-full flex justify-end pt-[15px]">
                            <GroupButton
                                className=""
                                buttons={[
                                    {
                                        type: 'button',
                                        text: t('modal.continue'),
                                        classType: 'blue',
                                        action: () => toggleContinue(),
                                    },
                                ]}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {openModalManually && (
                <ReconcileManuallyModal
                    openParentModal={openModal}
                    setOpenParentModal={setOpenModal}
                    openRootModal={openParentModal}
                    setOpenRootModal={setOpenParentModal}
                    headerTitle={headerTitle}
                    subHeaderTitle={isImport === false ? t('stockPage.subHeaderManuallyModal') : t('stockPage.subHeaderImportModal')}
                    openModalManually={openModalManually}
                    isEdit={isImport}
                    isImportShipment={isImportShipment}
                    targetData={data}
                    setOpenModalManually={setOpenModalManually}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </>
    );
};
export default ReceivedShipmentModal;
