import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const ReviewBy = (props: any) => {
    const {label, content, className} = props;
    const [t] = useTranslation();
    return (
        <div className="flex items-center text-sm">
            <span className="text-gray-500 w-[110px]">{label}</span>
            <span className={cn("text-gray-900", className)}>{content}</span>
        </div>
    );
};
export default ReviewBy;
