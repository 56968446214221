/* eslint-disable react/jsx-props-no-spreading */
import { Label, TextInput } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from 'redux/actions';
import { useTranslation } from 'react-i18next';
import FileUpload from 'components/commonComponent/fileUpload';
import { useEffect, useState } from 'react';
import { updateUserProfile, updateUserImage, confirmAndSaveProfileImage } from 'api/userApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import useUser from 'hooks/useUser';
import { userSelector } from 'redux/selectors';
import { toast } from 'react-toastify';
import GroupButton from 'components/button/groupButton';
import ChangePasswordModal from 'components/modal/ChangePasswordModal';
import 'styles/styles.scss';
import { messageErrors } from 'utils/utils';
import { IMAGE_TYPE_REGEX } from 'utils/constants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './updateProfileModal.scss';
import './ChangePasswordModal.scss';
import './formUpdateProfile.scss';
import { generateUploadPresignedUrl, uploadImageByPresignUrl } from 'api/fileApi';

const FormUpdateProfile = (props: any) => {
  const { isNavigatedFromDashboard = false, isModal = false, redirectToDashboard, isOpenProfileModal } = props;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const closeModal = () => {
    const isOpenModal: any = false;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };

  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);
  const [file, setFile] = useState<any>();
  const [imgUrl, setImgUrl] = useState(userInfo?.fileImage?.url || '');
  const [imgSizeError, setImgSizeError] = useState(false);
  const [imgTypeError, setImgTypeError] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  }: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });
  const handleRemoveImage = () => {
    setFile(false);
    setImgUrl('');
  };

  const changePasswordHandler = () => setOpenModal(true);

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        setImgSizeError(true);
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          setImgTypeError(true);
          setImgSizeError(false);
          return;
        }
        if (currentFile.size > 8000000) {
          setImgSizeError(true);
          setImgTypeError(false);
          return;
        }
      }
      setImgUrl(URL.createObjectURL(currentFile));
      currentFile.isUploaded = true;
      setFile(currentFile);
      setImgSizeError(false);
      setImgTypeError(false);
    }
  };

  const handleUpdateUser = async (values: any) => {
    await updateUserProfile(omit({ ...values, updatedProfile: true }, ['id', 'organizations', 'roles']));
    if (file?.name) {
      const currentFileName = file.name
        .split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      const formData = new FormData();
      formData.append('file', file, currentFileName);
      const { data } = await updateUserImage({ id: userInfo.id, file: formData });
    } else if (!file) {
      await updateUserImage({ id: userInfo.id, file: {} });
      setImgUrl('');
    }
    queryClient.invalidateQueries(['getUserDetail'], { refetchType: 'active' }, { cancelRefetch: true });
    await fetchCurrentUser();
    if (redirectToDashboard) {
      redirectToDashboard();
    }
  };

  const handleClickSkip = async () => {
    mutationUpdateUser.mutate({});
  };

  const mutationUpdateUser = useMutation(['update-user'], {
    mutationFn: handleUpdateUser,
    onSuccess: async () => {
      await fetchCurrentUser();
      closeModal();
      const message: string = t('user.updateSuccess');
      toast.success(message);
    },
    onError: error => {
      toast.error(messageErrors(error, t));
    },
  });

  const onSubmit = (values: any) => {
    mutationUpdateUser.mutate(values);
  };

  useEffect(() => {
    if (userInfo?.fileImage?.url) {
      setImgUrl(userInfo?.fileImage?.url);
      setFile(true);
    }
    const initData = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      emailAddress: userInfo.emailAddress,
    };
    reset(initData);
    setImgSizeError(false);
    setImgTypeError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isOpenProfileModal]);

  return (
    <div className="form-update-profile w-full flex justify-center">
      <div
        className={`w-full flex flex-wrap items-center shadow-sm bg-white px-6 rounded-lg justify-center ${
          isModal ? 'user-profile-modal' : 'user-profile-page'
        } `}
      >
        <FileUpload handleUploadImage={handleUploadImage} imgUrl={imgUrl} handleRemoveImage={handleRemoveImage} />
        {imgSizeError && <div className="text-red-600 text-xs font-normal mt-1 veri-modal height-16">{t('userProfilePage.errorSizeImage')}</div>}
        {imgTypeError && <div className="text-red-600 text-xs font-normal mt-1 veri-modal height-16">{t('userProfilePage.errorTypeImage')}</div>}
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full mt-8">
            <div className={`${!isModal && 'lg:grid grid-cols-2 gap-4'} `}>
              <div className="mb-3">
                <div className="mb-2 block">
                  <Label value={`${t('userProfilePage.firstName')} *`} />
                </div>
                <div className={`${errors.firstName && 'border-error'}`}>
                  <TextInput id="firstName" type="text" placeholder="Jese" {...register('firstName')} />
                  {errors.firstName && (
                    <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.firstName?.message}</div>
                  )}
                </div>
              </div>

              <div className="mb-3">
                <div className="mb-2 block">
                  <Label value={`${t('userProfilePage.lastName')} *`} />
                </div>
                <div className={`${errors.lastName && 'border-error'}`}>
                  <TextInput id="lastName" type="text" placeholder="Leos" {...register('lastName')} />
                  {errors.lastName && <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.lastName?.message}</div>}
                </div>
              </div>

              <div className="mb-5">
                <div className="mb-2 block">
                  <Label value={`${t('userManagementPage.email')} *`} />
                </div>
                <div className={`${errors.emailAddress && 'border-error'}`}>
                  <TextInput id="emailAddress" type="text" placeholder="example@email.com" {...register('emailAddress')} disabled />
                </div>
              </div>
            </div>
            {isNavigatedFromDashboard && (
              <div className="flex items-end justify-end">
                <GroupButton
                  className="items-center justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('userProfilePage.skip'),
                      classType: 'white',
                      action: handleClickSkip,
                      disabled: mutationUpdateUser.isLoading,
                    },
                    {
                      type: 'submit',
                      text: t('userProfilePage.saveAndContinue'),
                      classType: 'blue',
                      disabled: mutationUpdateUser.isLoading,
                    },
                  ]}
                />
              </div>
            )}
            {!isNavigatedFromDashboard && (
              <div className="flex items-end justify-center">
                <GroupButton
                  className="items-center justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('userProfilePage.changePassword'),
                      classType: 'white',
                      action: changePasswordHandler,
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      disabled: mutationUpdateUser.isLoading,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </form>
        <ChangePasswordModal openModal={openModal} setOpenModal={setOpenModal} closeModal={closeModal} />
      </div>
    </div>
  );
};
export default FormUpdateProfile;
