import { camelCase, map, split } from 'lodash';
import { getAvatarColor } from '../../utils/utils';
import { HiUser } from 'react-icons/hi';

const Avatar = (props: any) => {
  const { item, className } = props;
  const nameArr = split(item?.name || `${camelCase(item?.firstName)} ${camelCase(item?.lastName)}`, ' ', 2);
  const name = map(nameArr, str => str[0]).join('');
  const backgroundAvatar = getAvatarColor(item);
  const url = item?.url || item?.fileImage?.url;
  const textImg = className?.includes('xl') ? 'text-base' : 'text-xs'
  return (
    <div
      className={`${className || 'w-9 h-9 text-xs'} flex items-center justify-center rounded-full overflow-hidden`}
      style={{ backgroundColor: url ? 'white' : name ? '#F3F4F6' : '#E5E7EB' }}
    >
      {url && <img className={textImg} src={url} alt="avatar" />}
      {!url && name && <span className="text-gray-500 font-semibold uppercase">{name}</span>}
      {!url && !name && <HiUser className="w-4/6 h-4/6 text-gray-500" />}
    </div>
  );
};

export default Avatar;
