import { useEffect, useState } from 'react';
import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { BiArrowBack } from 'react-icons/bi';
import { INTERVAL, routePaths, SUBSCRIPTION_TYPE } from '../../utils/constants';
import { userSelector } from '../../redux/selectors';
import './subscription.scss';
import { getPlans, createSubscriptions } from '../../api/subscriptionApi';
import { planType } from 'utils/proptypes';
import { chargeSubscription } from 'api/stripeApi';
import useUser from 'hooks/useUser';

const Subscription = () => {
  const isUseStripe = process.env.REACT_APP_IS_USE_STRIPE === 'true';
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);
  const { fetchCurrentUser } = useUser();
  const { refetch: fetchPlans, data,error } = useQuery(['getPlans'], () => getPlans(), {
    enabled: false
  });

  const getDisplayInterVal = (interval: any) => {
    if (!interval) {
      return '';
    }
    interval = interval.toLowerCase();

    if (interval.startsWith(INTERVAL.MONTH)) {
      return INTERVAL.MONTH;
    }

    if (interval.startsWith(INTERVAL.YEAR)) {
      return INTERVAL.YEAR;
    }
    return '';
  };

  const getDisplayPlanType = (type: any) => {
    if (!type) {
      return '';
    }

    if (type.includes(SUBSCRIPTION_TYPE.BASIC)) {
      return t('subscription.plan.basic');
    }
    if (type.includes(SUBSCRIPTION_TYPE.STANDARD)) {
      return t('subscription.plan.standard');
    }
    if (type.includes(SUBSCRIPTION_TYPE.ENTERPRISE)) {
      return t('subscription.plan.enterprise');
    }

    return '';
  };

  const getShortDescriptionPlanType = (type: any) => {
    if (!type) {
      return '';
    }

    if (type.includes(SUBSCRIPTION_TYPE.BASIC)) {
      return t('subscription.plan.basicDescription');
    }
    if (type.includes(SUBSCRIPTION_TYPE.STANDARD)) {
      return t('subscription.plan.standardDescription');
    }
    if (type.includes(SUBSCRIPTION_TYPE.ENTERPRISE)) {
      return t('subscription.plan.enterpriseDescription');
    }

    return '';
  };

  const handleSubscription = async (plan: planType) => {
    try {
      if (plan.type !== SUBSCRIPTION_TYPE.BASIC) {
        const result = await chargeSubscription({ planId: plan.id, priceId: plan.priceId });
        if (result.status === 201 && result?.data?.url) window.location.replace(result.data.url);
      } else {
        const result = await createSubscriptions({ userId: userInfo.id, planId: plan.id });
        if (result.status === 201) {
          await fetchCurrentUser();
          navigate(routePaths.DASHBOARD_PAGE);
          const message: string = t('subscription.createdSuccess');
          toast.success(message);
        } else {
          const message: string = t('subscription.createdFail');
          toast.error(message);
        }
      }
    } catch (e) {
      const message: string = t('subscription.createdFail');
      toast.error(message);
    }
  };

  useEffect(() => {
    const fetchAsync = async () => {
      await fetchPlans();
    };

    fetchAsync().catch(() => {});
  }, [navigate, fetchPlans, t]);

  useEffect(() => {
    if (data) {
      setPlans(data.data.entities);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  if (loading) {
    return null;
  }

  return (
    <div className="subscription-page w-full h-screen p-5 lg:p-50 ">
      <div className="flex flex-col self-center items-center justify-center box w-full">
        <div className="back flex justify-start items-center w-full pl-5 cursor-pointer mb-10 mt-10" onClick={() => navigate(-1)}>
          <BiArrowBack /> <div className="pl-1">{t('back')}</div>
        </div>
        <h1 className="subscription-title">{t('subscription.title')}</h1>
        <p className="subscription-description">{t('subscription.description')}</p>
        <div className="plans flex-wrap xl:flex-nowrap">
          {plans.map((plan: planType, index) => {
            if (index > 2) {
              return null;
            }
            return (
              <div key={plan.id} className={classnames('plan-item m-0 md:m-4')}>
                <div className="plan-item-body">
                  <div className="plan-title">{plan.name}</div>
                  <div className="plan-shortDescription">{getShortDescriptionPlanType(plan.type)}</div>
                  <div className="plan-currencies">
                    <sup className="plan-currency">$</sup>
                    <span className="plan-amount">{plan.priceUSD / 100}</span>
                    <span className="plan-interval">/{plan.interval ? `${getDisplayInterVal(plan.interval)}` : ''}</span>
                  </div>
                  <div className="plan-description" dangerouslySetInnerHTML={{ __html: plan.description }} />
                  <div
                    className={classnames('plan-action', {
                      basic: plan.type === SUBSCRIPTION_TYPE.BASIC,
                      standard: plan.type === SUBSCRIPTION_TYPE.STANDARD,
                      enterprise: plan.type === SUBSCRIPTION_TYPE.ENTERPRISE,
                    })}
                  >
                    <Button
                      type="button"
                      size="lg"
                      onClick={() => {
                        const fetchAsync = async () => {
                          await handleSubscription(plan);
                        };
                        fetchAsync().catch(() => {});
                      }}
                    >
                      {t('subscription.plan.startWith')} {getDisplayPlanType(plan.type)}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
