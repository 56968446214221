import { PaginatorProps } from 'primereact/paginator';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowNarrowLeft, HiArrowNarrowRight, HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

const CustomPaginatorTreeTable = (props: PaginatorProps) => {
  const {first = 0, rows = 0, totalRecords = 0, onPageChange, template} = props;
  const totalPages = Math.ceil(totalRecords / rows);
  const currentPage = Math.ceil(first / rows) + 1;
  const [inputPage, setInputPage] = useState(currentPage + 1)
  const [t] = useTranslation();

  const goToPage = (page: any) => {
    const firstRecord = (page - 1) * rows;
    if (onPageChange) {
        onPageChange({
            first: firstRecord,
            rows,
            page: 0,
            pageCount: 0
        });
    }
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    goToPage(previousPage);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    goToPage(nextPage);
  };

  const changePage = (event: any) => {
    setInputPage(event.target.value)
  }

  useEffect(() => {
    setInputPage(currentPage + 1)
  }, [currentPage])

  return (
    <>
      <div className='flex paginate w-full bottom-5 text-xs'>
        <div className='w-1/2 flex mr-2 justify-end paginate-button h-34'>
          <div className="w-auto flex-row gap-2 ml-3 bg-gray-400 rounded-sm previous-button-container">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePreviousPage()}
              data-testid="test-button-previous"
              className="previous-button w-auto border text-gray-900 rounded-sm border-gray-200 bg-white text-xs font-normal cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10"
            >
              <HiArrowNarrowLeft className='w-5 h-5 mr-2' />
              {t('paginate.previousPage')}
            </button>
          </div>
          <div className="w-auto  flex-row gap-2 ml-3 bg-gray-400 rounded-sm">
            <button
              disabled={currentPage === totalPages}
              onClick={() => handleNextPage()}
              data-testid="test-button-next"
              className="next-button w-auto text-white text-xs font-normal cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10"
            >
              {t('paginate.nextPage')}
              <HiArrowNarrowRight className='w-5 h-5 ml-2' />
            </button>
          </div>
        </div>
        <div className='w-1/2 flex justify-end pr-5 second-paginate' data-testid="test-pagination">
          <form onSubmit={(e) => e.preventDefault()}>
            <input type="number" max={totalPages} min="1" className='border paginate-input bg-gray-50 w-14 text-gray-900 text-center text-xs' value={inputPage} onChange={changePage} onKeyPress={changePage} />
          </form>

          <p className='flex items-center mx-3 text-center text-on-primary-containerpt-1'>of {totalPages}</p>
          <div className='flex justify-center bg-gray-100'>
            <div className='w-8 paginate-icon flex justify-center items-center cursor-pointer mr-1'
              onClick={() => handlePreviousPage()}>
              <HiOutlineChevronLeft className='w-5 h-5 text-on-primary-container' />
            </div>
            <div className='w-8 paginate-icon flex justify-center items-center cursor-pointer'
              onClick={() => handleNextPage()}>
              <HiOutlineChevronRight className='w-5 h-5 text-on-primary-container' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPaginatorTreeTable;