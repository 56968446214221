import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locationId: null,
};

const setLocationCookie = (state: any, action: any) => {
  state.locationId = action.payload;
};

export const locationSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setLocationCookie,
  },
});

export default locationSlice;
