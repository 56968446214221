export const themeTabs = {
  tablist: {
    styles: { underline: 'border-b-0 pl-8'},
    tabitem: {
      styles: {
        underline: { active: { on: 'border-b-2 border-blue-600 text-blue-600 focus:ring-0 px-8', off: 'border-blue-600 focus:ring-0 px-8' } },
      },
    },
  },
  tabitemcontainer: {
    variant: {
      fullWidth: "100%"
    },
    styles: { width: "100%" },
  },
  tabpanel: "py-3",
};

export const themTooltip = {
  target: 'w-full overflow-hidden text-ellipsis whitespace-nowrap',
  base: 'bg-blue-600 ',
  style: {
    dark: 'bg-gray-800 bg-opacity-80 text-white px-2 py-1 !left-14 !top-8 rounded',
  },
};

export const themModal = {
  content: {
    base: 'relative w-full p-4 md:h-auto',
  },
};

export const themDropdownItem = {
  container: '',
  base: 'flex items-center justify-start py-2 px-4 text-sm font-normal text-on-primary-container cursor-pointer w-full hover:bg-hover-10 focus:bg-gray-100 focus:outline-none',
  icon: 'mr-2 h-4 w-4',
};

export const themDatepicker = {
  views: {
    days: {
      header: {
        base: 'grid grid-cols-7 mb-1',
        title: 'dow h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400',
      },
      items: {
        base: 'grid w-64 grid-cols-7',
        item: { selected: 'bg-primary !text-white hover:bg-cyan-600', disabled: 'text-gray-500' },
      },
    },
  },
};

export const themInput = {
  field: {
    input: {
      colors: {
        gray: 'bg-white border-hover-20 hover:border-opacity-50 focus:border-hover-20 focus:ring-hover-20 text-base text-on-primary-container',
      },
      withAddon: { off: 'rounded-none' },
    },
  },
};

export const themTextarea = {
  colors: {
    gray: 'bg-white border-hover-20 hover:border-opacity-50 focus:border-hover-20 focus:ring-hover-20 text-base text-on-primary-container',
  },
  withAddon: { off: 'rounded-none' },
};

export const themCheckbox = {
  root: {
    base: 'bg-white border focus:ring-0 w-5 h-5 !rounded mr-1 ',
    color: {
      default: 'border-primary checked:bg-primary disabled:bg-gray-200 disabled:bg-opacity-50 disabled:border-opacity-50',
    },
  },
};