/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from 'utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { themModal } from 'utils/theme';
import '../../form/styles.scss';
import InputText from 'components/commonComponent/inputText';
import { orgSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { createCategory, updateCategoryById } from 'api/categoryApi';
import { getItemsByFilter } from 'api/itemApi';
import makeAnimated from 'react-select/animated';
import { isEmpty } from 'lodash';
import { sortByTypeType } from 'utils/constants';

type categoryModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string,
  targetData?: any,
  isEdit: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};
const CategoryModal = (props: categoryModalProps) => {
  const { openModal, targetData, isEdit, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);
  const [error, setErrors] = useState(false);
  const { organizationId } = useSelector(orgSelector);

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
  });

  const hookForm = useForm({
    mode: "onChange",
    resolver: yupResolver(ValidateSchema)
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    getValues,
  } = hookForm;

  useEffect(() => {
    if (isEdit) {
      setValue('name', targetData.name);
      setValue('organizationId', targetData.organization?.id);
      reset(targetData);
    } else {
      reset();
    }

    // eslint-disable-next-line
  }, []);

  const handleAddAndUpdateCategory = async (payload: any) => {
    if (isEdit) {
        payload.organizationId = organizationId;
        payload.organization = {id: organizationId};
        await updateCategoryById(targetData.id, payload);
    } else {
        payload.organizationId = organizationId;
        payload.organization = {id: organizationId};
        await createCategory(payload);
    }
  };

  const mutation = useMutation(['create-update-category'], { mutationFn: handleAddAndUpdateCategory });

  const onSubmit = (values: any, props: any) => {
    mutation.mutate(values, {
      onSuccess: () => {
        toggleHandler();
        if (currentPage !== 0) {
          queryClient.invalidateQueries('getCategories', { refetchActive: false }, { cancelRefetch: true });
          setCurrentPage(0);
        } else queryClient.invalidateQueries('getCategories', { refetchActive: true }, { cancelRefetch: true });
        const message: string = isEdit ? t('categoryPage.editSuccess') : t('categoryPage.saveSuccess');
        toast.success(message);
        fetchCurrentUser();
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  return (
    <div ref={rootRef} className="category-modal-container">
      <Modal
        show={openModal}
        size="xl"
        className="category-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4 relative">
                <InputText 
                hookForm={hookForm}
                placeholder={`${t('categoryPage.modalCategoryName')}`}
                name="name"
                id="name"
                isHolderLabel={true}
                />
            </div>
            <GroupButton
              className="items-center justify-center pt-5 mb-4"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: mutation.isLoading,
                },
              ]}
            />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CategoryModal;
