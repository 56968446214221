/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import {toast} from 'react-toastify';
import useUser from 'hooks/useUser';
import {Modal} from 'flowbite-react';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './stockModal.scss';
import {orgSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import FileUpload from 'components/commonComponent/fileUpload/dragDropFileUpload';
import ReconcileManuallyModal from './reconcileManuallyModal';

type reconcileStockModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    targetData?: any,
    isEdit: boolean,
    isImportShipment: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ReconcileStockModal = (props: reconcileStockModalProps) => {
    const {openModal, targetData, isEdit, isImportShipment, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage} = props;
    const [openModalManually, setOpenModalManually] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [data, setData] = useState([]);
    const [file, setFile] = useState(null);
    const [fileContent, setFileContent] = useState('');
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);

    const toggleHandler = () => {
        setOpenModal(!openModal);
    };

    const toggleContinue = () => {
        if (!file) {
            toast.error(`${t('uploadFile.noUpload')}`);
        } else {
            const nextLineRegex = /\r\n/;
            const lineFound = fileContent.match(nextLineRegex);
            if (lineFound) {
                const dataLine = fileContent.split(nextLineRegex);
                const dataLineFilter = dataLine.filter((x: any) => x !== "");
                const dataByFile = buildDataByFile(dataLineFilter);
                setData(dataByFile);
                setIsImport(!isImport);
                setOpenModalManually(!openModalManually);
            } else {
                setIsImport(!isImport);
                setOpenModalManually(!openModalManually);
            }
        }
    };

    const buildDataByFile = (fileContent: any) => {
        const dataByContent: any = [];
        for (let index = 0; index < fileContent.length; index++) {
            const element = fileContent[index];
            const tabRegex = /\t/;
            const tabFound = element.match(tabRegex);
            if (tabFound) {
                const dataTab = element.split(tabRegex);
                const temp = {
                    upc: dataTab[0],
                    quantity: parseInt(dataTab[1]),
                    action: dataTab[2]
                }
                dataByContent.push(temp);
            }
        }
        return dataByContent;
    }

    const toggleReconcileManually = () => {
        setIsImport(false);
        setOpenModalManually(!openModalManually);
    };

    return (
        <>
            <div ref={rootRef} className="stock-modal-container">
                <Modal
                    show={openModal}
                    size="6xl"
                    className="stock-modal"
                    root={rootRef.current ?? undefined}
                    onClose={toggleHandler}
                    dismissible={false}
                    theme={themModal}
                >
                    <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                    <Modal.Body theme={{base: 'flex flex-col items-center'}}>
                        <div className="my-4 text-sm font-normal">Choose an inventory file to import</div>
                        <FileUpload file={file} setFile={setFile} fileContent={fileContent} setFileContent={setFileContent} fileTypes={['txt']} headerUpload={''}/>
                        { !isEdit && (
                            <>
                                <span className="my-4 text-sm">or</span>
                                <GroupButton
                                    className="primary-button"
                                    buttons={[
                                        {
                                            type: 'button',
                                            text: 'Reconcile Manually',
                                            classType: 'blue',
                                            action: () => toggleReconcileManually(),
                                        },
                                    ]}
                                />
                            </>
                        )}
                        <div className="w-full flex justify-end pr-[30px] pb-[30px]">
                            <GroupButton
                                className=""
                                buttons={[
                                    {
                                        type: 'button',
                                        text: t('modal.continue'),
                                        classType: 'blue',
                                        action: () => toggleContinue(),
                                    },
                                ]}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {openModalManually && (
                <ReconcileManuallyModal
                    openParentModal = {openModal}
                    setOpenParentModal={setOpenModal}
                    openRootModal = {openModal}
                    setOpenRootModal={setOpenModal}
                    headerTitle={headerTitle}
                    subHeaderTitle={isImport === false ? t('stockPage.subHeaderManuallyModal') :  t('stockPage.subHeaderImportModal') }
                    openModalManually={openModalManually}
                    isEdit={isImport}
                    isImportShipment={isImportShipment}
                    targetData={data}
                    setOpenModalManually={setOpenModalManually}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </>
    );
};
export default ReconcileStockModal;
