import { useTranslation } from 'react-i18next';

const ButtonNewPurchaseOrder = (props: any) => {
  const [t] = useTranslation();
    const {text, Icon, onClick} = props;

    return (
      <div onClick={onClick} className="border-[3px] border-[#E8F4FF] p-[10px] rounded-[10px] bg-white w-[152px] h-[152px] cursor-pointer hover:bg-[#E8F4FF] hover:border-[#A9D8FF] ">
          <div className="text-xs text-primary border border-[#A9D8FF] rounded-[10px] border-dashed flex flex-col bg-[#E8F4FF] w-full h-full justify-center items-center">
              <Icon size={"50px"} strokeWidth={1}/>
              <span className="mt-[10px]">{text}</span>
          </div>
      </div>
  );
};
export default ButtonNewPurchaseOrder;
