import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import { BUTTON_COLOR, routePaths, stripeSucceeded } from 'utils/constants';
import './stripePayment.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { confirmPayment } from 'api/stripeApi';
import { createSubscriptions } from 'api/subscriptionApi';
import useUser from 'hooks/useUser';

const CheckoutForm = (props: { isPayment: boolean }) => {
  const { isPayment } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { fetchCurrentUser } = useUser();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isStripLoading, setIsStripLoading] = useState(true);

  useEffect(() => {
    const payment_intent_client_secret = searchParams.get('payment_intent_client_secret') ?? '';
    const payment_intent = searchParams.get('payment_intent') ?? '';

    if ((!stripe && !payment_intent_client_secret) || isPayment) {
      return;
    }

    const confirmAndCreateSub = async () => {
      const res = await confirmPayment({ paymentIntentId: payment_intent });
      if (res.status === 200 && res.data.chargeStatus === stripeSucceeded) {
        const { planId, userId, stripeId } = res.data || {};

        if (planId && userId && stripeId && !isSuccess) {
          const result = await createSubscriptions({ userId, planId, stripeId });
          result.status === 201 && (await fetchCurrentUser());
          navigate(routePaths.DASHBOARD_PAGE);
          const message: string = t('stripe.paymentSuccessful');
          toast.success(message);
          setIsSuccess(true);
        }
      } else {
        const message: string = t('stripe.paymentFail');
        toast.error(message);
      }
    };

    !isSuccess && confirmAndCreateSub();

    // eslint-disable-next-line
  }, [stripe, isSuccess]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_RETURN_URL ?? '',
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      {stripe && elements && isPayment && (
        <div className="checkout-form w-full h-screen p-5 lg:p-50 ">
          <div className="form flex flex-col items-center">
            <form onSubmit={handleSubmit}>
              <div className="payment-form mt-10">
                <PaymentElement id="stripe-payment" className="stripe-payment" onReady={() => setIsStripLoading(false)} />
                <div className="mt-4 flex flex-col items-center">
                  {!isStripLoading && <ButtonIconWithText text={'Submit'} disabled={!stripe} type="submit" color={BUTTON_COLOR.BLUE} />}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

const isUseStripe = process.env.REACT_APP_IS_USE_STRIPE === 'true';

const StripePayment = () => {
  const [searchParams] = useSearchParams();
  if (isUseStripe) {
    const clientSecret = searchParams.get('payment_intent_client_secret') ?? '';
    const isPayment = searchParams.get('isPayment') === 'true' || false;
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '');

    const options = {
      clientSecret: clientSecret,
      theme: 'stripe',
    };
    return (
      <>
        {clientSecret && isUseStripe && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm isPayment={isPayment} />
          </Elements>
        )}
      </>
    );
  }
};
export default StripePayment;
