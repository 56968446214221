/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from 'utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { themModal } from 'utils/theme';
import '../../form/styles.scss';
import MultiSelect from 'components/select/index';
import InputText from 'components/commonComponent/inputText';
import { orgSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { getCategoriesByFilter } from 'api/categoryApi';
import { sortByTypeType } from 'utils/constants';
import { createItem, updateItemById } from 'api/itemApi';

type itemModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string,
  targetData?: any,
  isEdit: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};
const ItemModal = (props: itemModalProps) => {
  const { openModal, targetData, isEdit, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([{ label: '...', value: '' }]);
  const [error, setErrors] = useState(false);
  const { organizationId } = useSelector(orgSelector);

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
    sku: Yup.string().required(t('requiredField')),
    upc: Yup.string().required(t('requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const { register, handleSubmit, setValue, clearErrors, setError, reset, getValues } = hookForm;

  useEffect(() => {
    getCategoriesByFilter({ page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId })?.then(res => {
      const newOption = res?.data?.entities?.map((i: any) => ({ label: i.name, value: i.id }));
      setCategoryTypeOptions(newOption);
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit) {
      setValue('name', targetData.name);
      const categoryId = targetData.category?.id;
      if (categoryId) {
        const cateSelect: any = categoryTypeOptions.find(c => c.value === categoryId);
        setSelectedCategory(cateSelect);
      }
      setValue('categoryId', categoryId);
      setValue('sku', targetData.sku);
      setValue('upc', targetData.upc);
      setValue('bulkUpc', targetData.bulkUpc);
      setValue('unitPrice', targetData.unitPrice);
      setValue('caseQuantity', targetData.caseQuantity);
      setValue('caseCost', targetData.caseCost);
      reset(targetData);
    } else {
      reset();
    }

    // eslint-disable-next-line
  }, [categoryTypeOptions]);

  const handleAddAndUpdateItem = async (payload: any) => {
    if (isEdit) {
      payload.organizationId = organizationId;
      payload.organization = { id: organizationId };
      payload.category = { id: payload.categoryId };
      payload.unitPrice = parseFloat(payload.unitPrice);
      payload.caseQuantity = parseInt(payload.caseQuantity);
      payload.caseCost = parseFloat(payload.caseCost);
      payload.unitCost = payload.caseQuantity > 0 ? payload.caseCost/payload.caseQuantity : 0;
      await updateItemById(targetData.id, payload);
    } else {
      payload.organizationId = organizationId;
      payload.organization = { id: organizationId };
      payload.category = { id: payload.categoryId };
      payload.unitPrice = parseFloat(payload.unitPrice);
      payload.caseQuantity = parseInt(payload.caseQuantity);
      payload.caseCost = parseFloat(payload.caseCost);
      payload.unitCost = payload.caseQuantity > 0 ? payload.caseCost/payload.caseQuantity : 0;
      payload.isActive = true;
      await createItem(payload);
    }
  };

  const mutation = useMutation(['create-update-item'], { mutationFn: handleAddAndUpdateItem });

  const onSubmit = (values: any, props: any) => {
    mutation.mutate(values, {
      onSuccess: () => {
        toggleHandler();
        if (currentPage !== 0) {
          queryClient.invalidateQueries('getItems', { refetchActive: false }, { cancelRefetch: true });
          setCurrentPage(0);
        } else queryClient.invalidateQueries('getItems', { refetchActive: true }, { cancelRefetch: true });
        const message: string = isEdit ? t('itemPage.editSuccess') : t('itemPage.saveSuccess');
        toast.success(message);
        fetchCurrentUser();
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const handleChangeCategory = (option: any) => {
    setSelectedCategory(option);
    setValue('categoryId', option.value);
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  return (
    <div ref={rootRef} className="item-modal-container">
      <Modal
        show={openModal}
        size="4xl"
        className="item-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-2 relative flex md:flex-row flex-col">
            <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalItemName')}`} name="name" id="name" className="md:mr-4 mb-2 md:mb-0" isHolderLabel={true} />
              <MultiSelect
                onChange={handleChangeCategory}
                options={categoryTypeOptions}
                value={selectedCategory}
                hookForm={hookForm}
                name="categoryId"
                noOptionsMessage={() => t('No options')}
                closeMenuOnSelect
                isHolderLabel={true}
                placeholder={`${t('itemPage.modal.modalCategory')}`}
              />
            </div>
            <div className="mt-4 md:mt-2 relative flex md:flex-row flex-col">
              <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalSku')}`} name="sku" id="sku" className="md:mr-4 mb-2 md:mb-0" isHolderLabel={true} />
              <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalUpc')}`} name="upc" id="upc" isHolderLabel={true} />
            </div>
            <div className="mt-4 md:mt-2 relative flex md:flex-row flex-col">
            <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalBulkUpc')}`} name="bulkUpc" id="bulkUpc" isHolderLabel={true} className="md:mr-4 mb-2 md:mb-0"/>
              <InputText
                hookForm={hookForm}
                placeholder={`${t('itemPage.modal.modalSalePrice')}`}
                type="number"
                step="0.01"
                name="unitPrice"
                id="unitPrice"
                isHolderLabel={true}
              />
            </div>
            <div className="mt-2 relative flex md:flex-row flex-col">
              <InputText
                hookForm={hookForm}
                placeholder={`${t('itemPage.modal.modalCaseQuantity')}`}
                name="caseQuantity"
                id="caseQuantity"
                type="number"
                className="md:mr-4 mb-2 md:mb-0"
                isHolderLabel={true}
              />
              <InputText hookForm={hookForm} placeholder={`${t('itemPage.modal.modalCaseCost')}`} type="number" step="0.01" name="caseCost" id="caseCost" isHolderLabel={true} />
            </div>
            <GroupButton
              className="items-center justify-center pt-5 mb-4"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: mutation.isLoading,
                },
              ]}
            />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ItemModal;
