import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {iStatusType} from "../../utils/proptypes";

type IPropStatus = {
    type?: iStatusType
}

const Status = ({type}: IPropStatus) => {
    const [t] = useTranslation();
    console.log("type", type)
    function getTextByStatusType(type?: iStatusType) {
        switch (type) {
            case "Draft":
                return t("Draft");
            case "Rejected":
                return t("Rejected");
            case "Ordered":
                return t("Ordered");
            case "Requested":
                return t("Requested");
            case "Received":
                return t("Received");
            default:
                return "";
        }
    }

    function getColorByStatusType(type?: iStatusType) {
        switch (type) {
            case "Draft":
                return t("bg-gray-400");
            case "Rejected":
                return t("bg-red-500");
            case "Ordered":
                return t("bg-blue-500");
            case "Requested":
                return t("bg-yellow-400");
            case "Received":
                return t("bg-green-400");
            default:
                return "";
        }
    }

    function getTextColorByStatusType(type?: iStatusType) {
        switch (type) {
            case "Draft":
                return t("text-gray-400");
            case "Rejected":
                return t("text-red-500");
            case "Ordered":
                return t("text-blue-500");
            case "Requested":
                return t("text-yellow-400");
            case "Received":
                return t("text-green-400");
            default:
                return "";
        }
    }

    const text = getTextByStatusType(type);
    const color = getColorByStatusType(type);
    const textColor = getTextColorByStatusType(type);

    return (
        <div className="flex flex-row items-center">
            <span className={cn("w-3 h-3 rounded-lg mr-2", {
                [color]: color
            })} />
            <span className={cn("text-sm", {
                [textColor]: textColor
            })}>{text}</span>
        </div>
    );
};
export default Status;
