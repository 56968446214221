/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import  { useRef } from 'react';
import { themModal } from 'utils/theme';
import '../../form/styles.scss';
import GroupButton from 'components/button/groupButton';

type regionModalProps = {
  openModalError: boolean,
  setOpenModalError: Function,
  headerTitle?: string,
  targetData?: any,
  isEdit: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};
const RegionModalError = (props: regionModalProps) => {
  const { openModalError, targetData, isEdit, setOpenModalError, headerTitle, currentPage, queryClient, setCurrentPage } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);



  const toggleHandler = () => {
    setOpenModalError(!openModalError);
  };

  return (
    <div ref={rootRef} className="group-modal-container">
      <Modal
        show={openModalError}
        size="lg"
        className="group-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        //dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex items-center flex-col' }}>
            <div className="text-black  text-base font-semibold mt-5 mb-3">{`${t('regionPage.modal.modalHeaderError')}`}</div>
            <div className="text-black text-sm font-normal">{`${t('regionPage.modal.modalSubHeaderError')}`}</div>
            <GroupButton
              className="items-center justify-center pt-5 mb-8"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.ok'),
                  classType: 'blue',
                  action: () => toggleHandler(),
                },
              ]}
            />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default RegionModalError;
