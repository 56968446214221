import { ORG_COOKIE_KEY, LOCATION_COOKIE_KEY, tokenKey, permissionKeys } from 'utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { getCurrentUser } from 'api/userApi';
import { orgAction, userAction, locationAction } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { deleteCookie } from 'utils/cookie';
import { flattenDeep, get, isEmpty, map, orderBy } from 'lodash';
import { setOrgCookie, getOrganizations } from 'api/organizationApi';
import { setLocationCookie } from 'api/locationApi';

const useUser = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  const fetchCurrentUser = async () => {
    const { data } = await getCurrentUser();

    const checkPlatformAdmin = flattenDeep(map(data.roles, r => r.permissions)).includes(permissionKeys.PLATFORM_ADMIN);
    if (checkPlatformAdmin) data.organizations = get(await getOrganizations(), 'data.entities', []);
    data.organizations = orderBy(data.organizations, 'created', 'desc');
    const organizations = data.organizations;
    const organizationDefault = localStorage.getItem(ORG_COOKIE_KEY);
    if (!organizations || isEmpty(organizations)) {
      deleteCookie(ORG_COOKIE_KEY);
    } else if (!organizationDefault || !organizations.find((o: any) => o.id === organizationDefault)) {
      await handleSetTookie(organizations[0].id);
    } else {
      await handleSetTookie(organizationDefault);
    }
    dispatch(userAction.setUserInfo(data));
  };

  const handleLogout = () => {
    deleteCookie(tokenKey);
    localStorage.removeItem(ORG_COOKIE_KEY);
    logout({ returnTo: `${window.location.origin}` });
  };

  const handleSetTookie = async (organizationId: any) => {
    await setOrgCookie(organizationId);
    localStorage.setItem(ORG_COOKIE_KEY, organizationId);
    dispatch(orgAction.setOrgCookie(organizationId));
  };

  const handleSetLocationTookie = async (locationId: any, organizationId: any) => {
    await setLocationCookie(locationId, organizationId);
    localStorage.setItem(LOCATION_COOKIE_KEY, locationId);
    dispatch(locationAction.setLocationCookie(locationId));
  };

  return {
    handleLogout,
    fetchCurrentUser,
    handleSetTookie,
    handleSetLocationTookie
  };
};

export default useUser;
