/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { ORG_COOKIE_KEY, LOCATION_COOKIE_KEY, pageType, routePaths, permissionKeys, sortByTypeType } from 'utils/constants';
import { useSelector } from 'react-redux';
import { userSelector, orgSelector } from 'redux/selectors';
import { isEmpty, size } from 'lodash';
import Select, { components } from 'react-select';
import useUser from 'hooks/useUser';
import './selectLocation.scss';
import { useTranslation } from 'react-i18next';
import {HiLocationMarker, HiOutlineChevronDown, HiOutlineLocationMarker, HiOutlineTrash} from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLocationByFilter } from 'api/locationApi';
import { Tooltip } from 'flowbite-react';
import MultiSelect from 'components/select/index'
import { checkOrgAdmin, checkPermission, getUserOrg } from 'utils/utils';

const locationSelect = require('../assets/image/svg/location-marker.svg').default;
const userSetting = require('../assets/image/svg/user-setting.svg').default;

const LocationOrganization = (props: any) => {
  const { type } = props;
  const params = useParams();
  const locaion = useLocation();
  const [options, setOptions] = useState([{label: 'Select Location', value: ''}]);
  const { userInfo } = useSelector(userSelector);
  const [optionSelect, setOptionSelect] = useState<any>({});
  const { handleSetLocationTookie } = useUser();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useSelector(orgSelector);
  const [isMenuOpen, setIsMenuOpen] = useState<any>(undefined);
  const {WRITE_LOCATION, PLATFORM_ADMIN} = permissionKeys;
  const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
  const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
  

  const getRegions = () => {
    const userOrg = userInfo?.organizations?.find((o: any) => o.id === organizationId);
    const orgRegions = userOrg?.regions;
    let regionIds = '';
    if (orgRegions) {
        for (let index = 0; index < orgRegions.length; index++) {
            const region = orgRegions[index];
            if (regionIds === '') {
                regionIds = region.id;
            } else {
                regionIds = `${regionIds},${region.id}`;
            }
        }
    }
    return regionIds;
}

  const formatOptionLabel = ({ label, value }: any) => {
    return (
      <>
       {label !== undefined && (
        <div className="select-label">
          <div className="icon">
            <HiOutlineLocationMarker size={"16px"} color="white"/>
          </div>
          <p className="font-light text-name">{label !== undefined ? label : ''}</p>
        </div>
       )}
      </>
    );
  };

  const handleSelectLocation = async (event: any) => {
    if (event) {
      const option: any = options?.find((o: any) => o.value === event.value);
      if (option) {
        await setOptionSelect(option);
        setIsMenuOpen(undefined);
        const organizationId = localStorage.getItem(ORG_COOKIE_KEY) || undefined;
        await handleSetLocationTookie(option.value, organizationId);
        if ((locaion.pathname.split('/').includes('clinicians') || locaion.pathname.split('/').includes('patients')) && params.id) {
          navigate(-1);
        }
      }
    } else {
      await setOptionSelect({});
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    if (userInfo) {
      const locationId = localStorage.getItem(LOCATION_COOKIE_KEY) || undefined;
      getLocationByFilter({ page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions(), isActive: true })?.then(res => {
        const  newOption = res?.data?.entities?.map((i: any)=> ({label: i.name, value: i.id}));
        setOptions(newOption);
        const locationCookey = locationId !== undefined ? newOption.find((x: any) => x.value === locationId) : null;
        if (locationCookey) {
          setOptionSelect(locationCookey);
          handleSetLocationTookie(locationId, organizationId);
        } else {
          setOptionSelect(newOption[0]);
          handleSetLocationTookie(newOption[0]?.value, organizationId);
        }
       });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, type, organizationId]);
  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        <div className="text-base font-semibold	py-3 px-5">Switch to</div>
        <div className="select-options">{props.children}</div>
      </components.MenuList>
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <HiOutlineChevronDown className="h-4 w-4" />
        </components.DropdownIndicator>
      )
    );
  };

  const renderColor = (isSelected: any, isFocused: any) => {
    if ((isSelected && isFocused) || isSelected) {
      return 'rgba(132, 116, 106, 0.2)';
    } else if (isFocused) return 'rgba(132, 116, 106, 0.1)';
    return null;
  };

  const colourStyles = {
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: renderColor(isSelected, isFocused),
      };
    },
  };

  return (
    <div className="selected-location-container ">
      {!isEmpty(options) && options.length > 1 && type !== pageType.SITE && (
        <div className="select-location">
          <MultiSelect
            className={`block disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 font-light rounded text-xs	`}
            value={optionSelect}
            options={options}
            menuIsOpen={isMenuOpen}
            // defaultValue={''}
            onChange={handleSelectLocation}
            formatOption={formatOptionLabel}
            isSearchable
            isClearable
            //components={{ MenuList: MenuList, DropdownIndicator: DropdownIndicator }}
            styles={colourStyles}

          />
        </div>
      )}
      {!isEmpty(options) && options.length === 1 && type !== pageType.SITE && (
        <div className="select-location select-location-one one">{formatOptionLabel(optionSelect)}</div>
      )}
      {type === pageType.SITE && (
        <div className="flex site-admin items-center">
          <img alt="Huzzard Inventory logo" className="mr-1 h-7" src={userSetting} />
          <p className="font-medium site-admin-text text-white text-lg	ml-2 flex items-center">{t('sidebar.siteAdmin')}</p>
        </div>
      )}
    </div>
  );
};

export default LocationOrganization;
