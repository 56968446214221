// eslint-disable-next-line
import { queryFilterByOrg } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getLocationByFilter = (queryFilter: queryFilterByOrg) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.location.location}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getLocations = () => {
  const method = methods.GET;
  const url = `${urls.location.location}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getLocationById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.location.location}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createLocation = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.location.location}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateLocationById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.location.location}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const removeUserLocationById = (id: any, userOrgId: string) => {
  const method = methods.PUT;
  const url = `${urls.location.location}/remove-user/${id}/${userOrgId}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

const deleteLocationById = (id: string) => {
    const method = methods.DELETE;
    const url = `${urls.location.location}/${id}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

const setLocationCookie = (locationId: any, orgId: any) => {
    const method = methods.GET;
    const url = `${urls.location.location}/${locationId}/${orgId}/set-context`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

export {
  getLocationByFilter,
  getLocationById,
  updateLocationById,
  removeUserLocationById,
  getLocations,
  createLocation,
  deleteLocationById,
  setLocationCookie
};
